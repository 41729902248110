import { format, utcToZonedTime } from 'date-fns-tz';

// When we decide to try to format our dates going to the CDS
// differently, we will have one place to change it.
const toCreatedOnTimestamp = (time: Date): string => time.toISOString();

const formatDateStr = (date: string, dateFormat?: string): string => {
    const dateAsDate = new Date(date);

    return format(dateAsDate, dateFormat || 'MMMM d, yyyy');
};

const formatUTCDateStr = (date: Date | string, dateFormat?: string): string =>
    format(utcToZonedTime(date, 'UTC'), dateFormat || 'MMMM d, yyyy', {
        timeZone: 'UTC',
    });

export const DateTimeHelpers = {
    toCreatedOnTimestamp,
    formatDateStr,
    formatUTCDateStr,
};
