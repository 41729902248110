import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import _ from 'lodash';
import { Styles } from './styles';

type TabsProps = {
    labels: string[];
    setSelectedTab: (tab: number) => void;
    selectedTab: number;
};

export const TabsDisplay: React.FC<TabsProps> = (props: TabsProps) => {
    const handleChange = (_event: React.ChangeEvent<unknown>, newValue: any): void => {
        props.setSelectedTab(newValue);
    };

    return (
        <Box boxShadow={3} sx={Styles.box}>
            <Tabs id="tabs-display" sx={Styles.tabs} value={props.selectedTab} onChange={handleChange}>
                {props.labels.map((value, index) => (
                    <Tab
                        sx={Styles.textColorPrimary}
                        role="tab"
                        label={value}
                        key={index}
                        id={`tab-${_.lowerCase(value)}`}
                    />
                ))}
            </Tabs>
        </Box>
    );
};
