import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    dropdownHeader: {
        marginBottom: '6px',
    },
    listItem: {
        marginTop: '8px',
        marginBottom: '16px',
    },
    scrollableContainer: {
        maxHeight: '300px',
        overflow: 'auto',
        display: 'flex',
        flexFlow: 'column',
    },
    errorTextField: {
        marginTop: '16px',
        color: Colors.red[500],
    },
};
