import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { MakeTextField } from 'src/client/components/HelperComponents/TutorialHelpers/MakeTextField';
import { EM_API_SUBSCRIPTION_KEY, SERVICE_ACCOUNT_AUTH_TOKEN } from 'src/client/constants/tutorial';
import { useAsyncFn } from 'src/client/hooks/async-fn-hook';
import { SimulatedEmApiClient } from 'src/client/simulated-em-api/simulated-em-api-client';
import { Validator } from 'src/client/util/validator';
import { withDelay } from 'src/shared/lib/with-delay';
import { RequestDefinition, RequestResponse } from '../../RequestResponse';
import { SubmitButton } from '../../SubmitButton';
import { Styles } from '../styles';
import { TutorialExpandable, TutorialStepProps } from '../TutorialExpandable';

type TextFields = {
    emApiSubscriptionKey: string;
    authToken: string;
    name: string;
    description: string;
};

export const TutorialOrganization: React.FC<TutorialStepProps> = React.memo((props) => {
    const [organizationTextFieldValues, setOrganizationBodyValues] = useState<TextFields>({
        emApiSubscriptionKey: EM_API_SUBSCRIPTION_KEY,
        authToken: SERVICE_ACCOUNT_AUTH_TOKEN,
        name: 'Client 1',
        description: `Client 1's power grid management project`,
    });

    const [inFlight, response, invokeAsyncFn] = useAsyncFn(withDelay(750, SimulatedEmApiClient.createOrganization));

    const requestBody = {
        name: organizationTextFieldValues.name,
        description: organizationTextFieldValues.description || '',
    };

    const submitRequest = (): void => invokeAsyncFn(requestBody);

    const request: RequestDefinition = {
        method: 'POST',
        endpointPath: '/organizations',
        headers: [
            { name: 'Em-Api-Subscription-Key', value: organizationTextFieldValues.emApiSubscriptionKey },
            { name: 'Authorization', value: `Bearer ${organizationTextFieldValues.authToken}` },
            { name: 'Accept', value: 'application/json' },
            { name: 'Content-Type', value: 'application/json; charset=utf-8' },
        ],
        body: requestBody,
    };

    const validationErrors = Validator.validateSchema(organizationTextFieldValues, {
        name: {
            type: 'string',
            presence: {
                allowEmpty: false,
            },
            length: { maximum: 255 },
        },
        description: {
            type: 'string',
            presence: {
                allowEmpty: false,
            },
            length: { maximum: 255 },
        },
        emApiSubscriptionKey: {
            type: 'string',
            format: {
                pattern: `^${EM_API_SUBSCRIPTION_KEY}$`,
                message: `needs to match the api key assigned to your application. The tutorial application's key is ${EM_API_SUBSCRIPTION_KEY}`,
            },
            presence: {
                allowEmpty: false,
            },
        },
        authToken: {
            type: 'string',
            format: {
                pattern: `^${SERVICE_ACCOUNT_AUTH_TOKEN}$`,
                message: `is the token you got back in step 2's response. This represents that you are 'logged in' as an application. For the tutorial this is: 'eyJ0e-application-auth-token'`,
            },
            presence: {
                allowEmpty: false,
            },
        },
    });

    return (
        <TutorialExpandable
            {...props}
            title="Create an Organization"
            description={
                <Typography variant="body1" sx={Styles.description}>
                    Using an <strong>Application Auth Token</strong>, you can create an Organization. An{' '}
                    <strong>Organization</strong> is a logical container that allows Developers to maintain separation
                    of concerns and credentials if, for example, the software you are developing is intended to support
                    multiple customer organizations. Creating an Organization will return an ID and two Secrets which
                    you can use to log in as the Organization.
                </Typography>
            }
            valueField={
                <Box component="div" sx={Styles.values}>
                    <form noValidate autoComplete="off">
                        <Box component="div" sx={Styles.entryFields}>
                            {MakeTextField(
                                organizationTextFieldValues,
                                validationErrors,
                                'emApiSubscriptionKey',
                                'EM API Subscription Key',
                                setOrganizationBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                organizationTextFieldValues,
                                validationErrors,
                                'authToken',
                                'Auth Token',
                                setOrganizationBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                organizationTextFieldValues,
                                validationErrors,
                                'name',
                                'Organization Name',
                                setOrganizationBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                organizationTextFieldValues,
                                validationErrors,
                                'description',
                                'Organization Description',
                                setOrganizationBodyValues,
                                { required: true }
                            )}
                        </Box>
                    </form>
                    <SubmitButton
                        id="send-request-button"
                        onClick={submitRequest}
                        disabled={!!validationErrors}
                        spinning={inFlight}
                        buttonText="Send Request"
                        width={144}
                    />
                </Box>
            }
            requestResponse={<RequestResponse request={request} response={response} />}
        />
    );
});
