import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: Colors.white[600],
        backgroundImage:
            'linear-gradient(90deg, rgba(218, 218, 218, 0.7), rgba(218, 218, 218, 0.7)), url(/images/title-overlay.png)',
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingTop: '15px',
        position: 'static',
        marginBottom: '20px',
    },
};
