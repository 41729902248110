import ErrorIcon from '@mui/icons-material/Error';
import { EmptyState } from '@brightlayer-ui/react-components';
import { Box, SxProps, Theme } from '@mui/material';

type DisplayErrorProps = {
    errorTitle?: string;
    errorMessage?: string;
    sx?: SxProps<Theme>;
};
export const DisplayError = (props: DisplayErrorProps): JSX.Element => (
    <Box data-testid="portal-page-error" sx={props.sx}>
        <EmptyState
            title={props.errorTitle ?? 'Error'}
            description={
                props.errorMessage ??
                'Oops! We apologize, but it looks like the information you’re looking for is unavailable.'
            }
            icon={<ErrorIcon color="error" height={'96px'} width={'96px'} fontSize="inherit" />}
        />
    </Box>
);
