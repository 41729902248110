import { useEffect, useMemo, useState } from 'react';
import { NotificationContextProvider } from '../contexts/NotificationContextProvider';
import { DrawerContext } from '../contexts/DrawerContextProvider';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import {
    AuthContextProvider,
    ContactSupportScreen,
    CreateAccountScreen,
    CreatePasswordScreen,
    EulaScreen,
    ForgotPasswordScreen,
    LoginScreen,
    ReactRouterAuthGuard,
    ReactRouterGuestGuard,
    RegistrationContextProvider,
    RegistrationWorkflow,
    ResetPasswordScreen,
    ChangePasswordDialog,
    VerifyCodeScreen,
} from '@brightlayer-ui/react-auth-workflow';
import { Box } from '@mui/material';
import { ProjectAuthUIActions } from '../actions/AuthUIActions';
import { routes } from '../constants/routing';
import productLogo from 'src/client/assets/images/emcb_stacked_logo05x.png';
import { useApp } from '../contexts/AppContextProvider';
import { ProjectRegistrationUIActions } from '../actions/RegistrationUIActions';

import { RegistrationForm } from '../components/RegistrationForm';
import { AuthorizedRoutes, CompleteAccountRoutes, PublicRoutes } from './routes';

const SharedLayoutWrapper: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = useState(true);
    const app = useApp();

    return (
        <NotificationContextProvider>
            <DrawerContext.Provider
                value={{
                    drawerOpen,
                    setDrawerOpen,
                }}
            >
                <Outlet />
                {app.isAuthenticated && (
                    <ChangePasswordDialog
                        open={app.showChangePasswordDialog}
                        onFinish={() => {
                            app.setShowChangePasswordDialog(false);
                            app.onUserNotAuthenticated();
                        }}
                    />
                )}
            </DrawerContext.Provider>
        </NotificationContextProvider>
    );
};

export const AppRouter: React.FC = () => {
    const navigate = useNavigate();
    const app = useApp();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const actions = useMemo(() => ProjectAuthUIActions(app), []);

    // We need to initiate security as soon as the app loads so that an already-logged in user
    // has the correct drawer content without having to navigate to the login page
    useEffect(() => {
        void actions.initiateSecurity();
    }, [actions]);

    return (
        <Routes>
            <Route
                element={
                    <AuthContextProvider actions={actions} language="en" navigate={navigate} routeConfig={routes}>
                        <Outlet />
                    </AuthContextProvider>
                }
            >
                <Route
                    path={'/login'}
                    element={
                        <ReactRouterGuestGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/'}>
                            <LoginScreen
                                projectImage={<Box component="img" src={productLogo} sx={{ width: '273px' }} />}
                            />
                        </ReactRouterGuestGuard>
                    }
                />
                <Route
                    path={'/contact-support'}
                    element={
                        <ReactRouterGuestGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/'}>
                            <ContactSupportScreen
                                contactEmail={'EMCBSupport@eaton.com'}
                                contactPhone={'877-386-2273, Opt 2, Opt 2, Opt 1'}
                            />
                        </ReactRouterGuestGuard>
                    }
                />
                <Route
                    path={'/forgot-password'}
                    element={
                        <ReactRouterGuestGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/'}>
                            <ForgotPasswordScreen />
                        </ReactRouterGuestGuard>
                    }
                />
                <Route
                    path={'/reset-password'}
                    element={
                        <ReactRouterGuestGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/'}>
                            <ResetPasswordScreen />
                        </ReactRouterGuestGuard>
                    }
                />
                <Route element={<SharedLayoutWrapper />}>
                    {app.isAuthenticated && (!app.loginData.isEulaAccepted || !app.loginData.isProfileComplete)
                        ? CompleteAccountRoutes
                        : PublicRoutes}
                </Route>
                <Route
                    element={
                        <ReactRouterAuthGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/login'}>
                            <SharedLayoutWrapper />
                        </ReactRouterAuthGuard>
                    }
                >
                    {app.loginData.isEulaAccepted && app.loginData.isProfileComplete ? (
                        <>{AuthorizedRoutes}</>
                    ) : (
                        <>{CompleteAccountRoutes}</>
                    )}
                </Route>
            </Route>
            <Route
                element={
                    <RegistrationContextProvider
                        language="en"
                        routeConfig={routes}
                        navigate={navigate}
                        actions={ProjectRegistrationUIActions()}
                    >
                        <Outlet />
                    </RegistrationContextProvider>
                }
            >
                <Route
                    path={'/register/create-account'}
                    element={
                        <RegistrationWorkflow>
                            <EulaScreen html />
                            <CreateAccountScreen />
                            <VerifyCodeScreen />
                            <CreatePasswordScreen />
                            <RegistrationForm />
                        </RegistrationWorkflow>
                    }
                />
            </Route>
        </Routes>
    );
};
