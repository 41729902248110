import { Theme } from '@mui/material/styles';
import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    applicationNameForm: {
        display: 'flex',
        flexDirection: 'column',
    },
    bodyHeader: {
        marginBottom: '25px',
        marginTop: '25px',
    },
    textField: (theme: Theme) => ({
        maxWidth: '400px',
        color: Colors.white[200],
        width: '100%',
        marginBottom: theme.spacing(2),
    }),
    formHelperText: {
        marginLeft: '0px',
    },
    submitButton: {
        maxWidth: '90px',
        marginTop: '10px',
        textTransform: 'uppercase',
    },
    circularProgress: {
        color: 'white',
    },
    infoData: {
        marginTop: 2,
    },
};
