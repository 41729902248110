import { Box, CircularProgress, Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import _ from 'lodash';
import React from 'react';
import { Styles } from './styles';

type SimpleModalProps = {
    modalTitle: string;

    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;

    onClose?: () => void;

    submitActionButtonText?: string;
    submitActionButtonOnClick?: () => Promise<void>;
    submitActionButtonDisabled?: boolean;

    cancelActionButtonText?: string;
    cancelActionButtonOnClick?: () => void;
};

export const SimpleModal: React.FC<SimpleModalProps & React.PropsWithChildren> = (props) => {
    const [actionInFlight, setActionInFlight] = React.useState<boolean>(false);

    const handleClose = (): void => {
        if (props.setOpen) {
            props.setOpen(false);
        }

        if (props.onClose) {
            props.onClose();
        }
    };

    const submitActionButtonOnClick = props.submitActionButtonOnClick;
    const onActionButtonClick = submitActionButtonOnClick
        ? async (): Promise<void> => {
              setActionInFlight(true);
              try {
                  await submitActionButtonOnClick();
              } finally {
                  setActionInFlight(false);
              }
          }
        : handleClose;

    return (
        <div>
            <Dialog
                data-testid="simple-modal"
                id={`${_.kebabCase(props.modalTitle)}-dialog`}
                open={props.isOpen}
                onClose={(_event, reason): void => {
                    if (!actionInFlight && reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        handleClose();
                    }
                }}
                aria-labelledby="dialog-title"
                transitionDuration={{ exit: 0, enter: 30 }}
            >
                <DialogTitle id="dialog-title" data-testid="dialog-title">
                    {props.modalTitle}
                </DialogTitle>
                <DialogContent data-testid="dialog-content" sx={Styles.contentContainer}>
                    <Box component="fieldset" sx={Styles.simpleModalFieldset} disabled={actionInFlight}>
                        {props.children}
                    </Box>
                </DialogContent>
                <Divider />
                <DialogActions
                    sx={
                        !props.cancelActionButtonText || !props.submitActionButtonText
                            ? Styles.singleButtonActions
                            : Styles.actionsContainer
                    }
                >
                    {props.cancelActionButtonText && (
                        <Button
                            id="dialog-close-button"
                            data-testid="dialog-close-button"
                            onClick={props.cancelActionButtonOnClick ?? handleClose}
                            color="primary"
                            disabled={actionInFlight}
                            variant="outlined"
                            sx={Styles.button}
                        >
                            {props.cancelActionButtonText}
                        </Button>
                    )}
                    {props.submitActionButtonText && (
                        <Button
                            id="dialog-action-button"
                            data-testid="dialog-action-button"
                            onClick={onActionButtonClick}
                            color="primary"
                            disabled={actionInFlight || props.submitActionButtonDisabled}
                            variant="contained"
                            sx={Styles.button}
                        >
                            {actionInFlight ? (
                                <CircularProgress sx={Styles.circularProgress} size={20} variant={'indeterminate'} />
                            ) : (
                                props.submitActionButtonText
                            )}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};
