import { genericHashLink } from 'react-router-hash-link';
import { Link as LinkIcon } from '@mui/icons-material';
import React from 'react';
import { Box, SxProps, Theme, Link as MuiLink } from '@mui/material';
import { Styles } from './styles';
import * as Colors from '@brightlayer-ui/colors';

type ContentAnchorProps = {
    id: string;
    page?: string;
    sx?: SxProps<Theme>;
};

const Link = genericHashLink(MuiLink);

export const ContentAnchor: React.FC<ContentAnchorProps & React.PropsWithChildren> = (props) => (
    <>
        <Box sx={props.sx} id={props.id}></Box>
        {props.children && props.page ? (
            <Link href={`${props.page}#${props.id}`} sx={Styles.link} underline="hover" color={Colors.black[500]}>
                <LinkIcon sx={Styles.linkIcon} /> {props.children}
            </Link>
        ) : (
            ''
        )}
    </>
);
