import React, { ReactNode, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, Divider, MobileStepper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Styles } from './styles';
import _ from 'lodash';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

type StepperModalProps = {
    modalTitle: string;
    steps: ReactNode[];

    onSubmitAction?: () => void;
    actionInFlight?: boolean;
    onClose?: () => void;
    disableNext?: boolean;
};

export const StepperModal: React.FC<StepperModalProps> = (props) => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = (): void => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = (): void => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleClose = (): void => {
        if (props.onClose) {
            props.onClose();
        }
    };

    const handleSubmit = (): void => {
        if (props.onSubmitAction) {
            void props.onSubmitAction();
        }
    };

    return (
        <div>
            <Dialog
                data-testid="stepper-modal"
                id={`${_.kebabCase(props.modalTitle)}-dialog`}
                open={true}
                aria-labelledby="dialog-title"
                transitionDuration={{ exit: 0, enter: 30 }}
                onClose={handleClose}
            >
                <DialogTitle id="dialog-title" data-testid="dialog-title">
                    {props.modalTitle}
                </DialogTitle>
                <DialogContent data-testid="dialog-content" sx={Styles.contentContainer}>
                    {props.steps[activeStep]}
                </DialogContent>
                <Divider />
                <DialogActions sx={Styles.actionsContainer}>
                    <MobileStepper
                        variant="dots"
                        steps={props.steps.length}
                        position="static"
                        activeStep={activeStep}
                        style={{ flexGrow: 1, backgroundColor: 'white' }}
                        nextButton={
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={activeStep === props.steps.length - 1 ? handleSubmit : handleNext}
                                disabled={props.actionInFlight || props.disableNext}
                            >
                                {props.actionInFlight ? (
                                    <CircularProgress
                                        sx={Styles.circularProgress}
                                        size={20}
                                        variant={'indeterminate'}
                                    />
                                ) : (
                                    <>
                                        {activeStep === props.steps.length - 1 ? 'Submit' : 'Next'}
                                        {activeStep !== props.steps.length - 1 &&
                                            (theme.direction === 'rtl' ? (
                                                <KeyboardArrowLeft />
                                            ) : (
                                                <KeyboardArrowRight />
                                            ))}
                                    </>
                                )}
                            </Button>
                        }
                        backButton={
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={activeStep === 0 ? handleClose : handleBack}
                                disabled={props.actionInFlight}
                            >
                                {activeStep !== 0 &&
                                    (theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />)}
                                {activeStep === 0 ? 'Cancel' : 'Back'}
                            </Button>
                        }
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};
