import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { ApiClient } from 'src/client/api-client';
import { GenericConfirmationModal } from '../GenericConfirmationModal';
import { Styles } from './styles';
import { useApp } from 'src/client/contexts/AppContextProvider';

type LeaveTeamConfirmationModalProps = {
    team: { id: string; name: string };
    onClose: () => void;
    onSuccess: () => void;
    isLastExistingOwner?: boolean;
};

export const LeaveTeamConfirmationModal: React.FC<LeaveTeamConfirmationModalProps> = (props) => {
    const [errorText, setErrorText] = useState<string>('');
    const app = useApp();

    const onLeaveTeamClick = async (): Promise<void> => {
        const result = await ApiClient.leaveTeam(props.team.id, app.loginData.userId || '');

        if (!result.success) {
            setErrorText(`Failed to leave team. ${result.error || 'no error found'} `);
            return;
        }
        props.onSuccess();
        props.onClose();
    };

    return (
        <GenericConfirmationModal
            submitActionButtonText="Leave Team"
            cancelActionButtonText="Cancel"
            modalTitle="Leave Team"
            onClose={props.onClose}
            submitActionButtonOnClick={onLeaveTeamClick}
            submitActionButtonDisabled={props.isLastExistingOwner}
            errorText={errorText}
        >
            {props.isLastExistingOwner ? (
                <>
                    <Typography sx={Styles.confirmationQuestionText}>
                        You are the only owner of team <b>{props.team.name}</b>.
                    </Typography>
                    <Typography sx={Styles.confirmationInstructionalText}>
                        In order to leave this team, you will have to first assign ownership to another member.
                    </Typography>
                </>
            ) : (
                <>
                    <Typography sx={Styles.confirmationQuestionText}>
                        Are you sure you want to leave the
                        <b> {props.team.name} </b>
                        team?
                    </Typography>
                    <Typography sx={Styles.confirmationInstructionalText}>
                        You will have to be invited to rejoin.
                    </Typography>
                </>
            )}
        </GenericConfirmationModal>
    );
};
