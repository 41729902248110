import { Theme } from '@mui/material/styles';
import backgroundImage from '../../assets/images/background.svg';

export const Styles = {
    page: (theme: Theme) => ({
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.dark,
        backgroundImage: `url(${backgroundImage})`,
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }),
    card: (theme: Theme) => ({
        width: '100%',
        height: '100%',
        maxWidth: '450px',
        maxHeight: '730px',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'none',
            maxHeight: 'none',
            borderRadius: 0,
        },
    }),
};
