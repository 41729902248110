import { ApiClient } from 'src/client/api-client';
import { PageWrapper } from 'src/client/components/PageWrapper/index';
import { Spinner } from 'src/client/components/Spinner';
import { useRequest } from 'src/client/hooks/request-hook';
import { DisplayError } from 'src/client/components/DisplayError';
import { EmptyState } from '@brightlayer-ui/react-components';
import Group from '@mui/icons-material/Group';
import { TeamsOverview } from 'src/client/components/TeamsOverview';
import { ListPageNavBar } from 'src/client/components/ListPageNavBar';
import { AddCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Styles } from './styles';

export const TeamsListPage = (): JSX.Element => {
    const [isLoading, teamsData, requeryTeamsData] = useRequest(ApiClient.getTeams);
    const navigate = useNavigate();

    const navigateTo = (route: string): void => {
        navigate(route);
    };

    return (
        <PageWrapper title="My Teams" sx={Styles.teamsOverviewPageContent}>
            <ListPageNavBar
                startIcon={<AddCircle />}
                buttonId={'create-team'}
                buttonText={'Create New Team'}
                onClick={(): void => {
                    navigateTo(`/teams/create`);
                }}
            />

            {isLoading ? (
                <Spinner />
            ) : !teamsData ? (
                <DisplayError />
            ) : teamsData.length > 0 ? (
                <TeamsOverview teamsData={teamsData} requeryTeamsData={requeryTeamsData} />
            ) : (
                <EmptyState
                    sx={Styles.emptyState}
                    id="empty-teams-page-message"
                    icon={<Group sx={Styles.emptyStateIcon} />}
                    title="No Teams Found"
                    description="You are not a part of any teams."
                />
            )}
        </PageWrapper>
    );
};
