import { Box, Divider } from '@mui/material';
import { HomeContentBlock } from '../HomeContentBlock';
import { Styles } from './styles';

export const HomeContent = (): JSX.Element => (
    <Box sx={Styles.container}>
        <HomeContentBlock
            title="Smart Breakers"
            subtitle="Real-time energy monitoring"
            content="Eaton’s Wi-Fi smart breakers provide circuit protection, cloud connectivity, remote control & precise metering all packaged in a standard miniature breaker form factor. This smart breaker is designed to fit in a standard Eaton loadcenter or panelboard to accommodate BR and BAB series of breakers."
            orientation="right"
            image="/images/smart-breakers-connect.png"
        />

        <Divider />

        <HomeContentBlock
            title="Open API Platform"
            subtitle="Integration flexibility"
            content="Open APIs, powered by Brightlayer, allow flexibility to integrate with preferred software systems. Our documentation provides detailed instructions of the functionality supported by the APIs."
            orientation="left"
            image="/images/software-engineer-smart-breaker.png"
        />

        <Divider />

        <HomeContentBlock
            title="Intelligent Power for the Home"
            subtitle="Cloud connectivity"
            content="Eaton’s smart breaker can easily be installed in most Eaton loadcenters. Through cloud connectivity, they can monitor and control loads inside the home, including electric vehicle chargers and energy storage systems."
            orientation="right"
            image="/images/smart-city.png"
        />
    </Box>
);
