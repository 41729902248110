import { Typography } from '@mui/material';
import { Styles } from '../styles';

export const InviteInstallerDescription = (): JSX.Element => (
    <>
        <Typography variant="body1" sx={Styles.description}>
            <strong>Installers</strong> play a vital role in the overall deployment and management of Eaton Smart
            Breakers. Installers, through use of the EM Install application (available on the{' '}
            <a href="https://apps.apple.com/us/app/eaton-em-install/id1530627437" target="_blank" rel="noreferrer">
                App Store®
            </a>{' '}
            and on{' '}
            <a
                href="https://play.google.com/store/apps/details?id=com.eaton.eminstall"
                target="_blank"
                rel="noreferrer"
            >
                Google Play
            </a>
            ), commission devices on behalf of <strong>Organizations</strong>.
        </Typography>
        <br />
        <Typography variant="body1" sx={Styles.description}>
            Using an <strong>Organization Auth Token</strong>, invite an <strong>Installer</strong> to commission
            devices on behalf of the <strong>Organization</strong> by assigning them the <em>installer</em> role. The
            installer role can be applied at the <strong>Organization</strong> level, at an{' '}
            <strong>Address Location</strong>, or at an <strong>Equipment Location</strong>.
        </Typography>
        <br />
        <Typography variant="body1" sx={Styles.description}>
            If an <strong>Installer</strong> is given the installer role at the <strong>Organization</strong> level,
            they will be able to create new or choose from existing <strong>Address Locations</strong> and{' '}
            <strong>Equipment Locations</strong> on the organization&apos;s behalf, as well as commission devices at
            those Equipment Locations.
        </Typography>
        <br />
        <Typography variant="body1" sx={Styles.description}>
            If an <strong>Installer</strong> is given the installer role at an <strong>Address Location</strong>, they
            will be able to create new or choose existing <strong>Equipment Locations</strong> on the
            organization&apos;s behalf, as well as commission devices at those Equipment Locations.
        </Typography>
        <br />
        <Typography variant="body1" sx={Styles.description}>
            If an <strong>Installer</strong> is given the installer role at an <strong>Equipment Location</strong>, they
            will only be able to commission devices at that Equipment Location.
        </Typography>
        <br />
        <Typography variant="body1" sx={Styles.description}>
            An installer can be invited by <strong>Email</strong> or via an <strong>Invite Code</strong>. Use the{' '}
            <strong>Email</strong> invitation type to invite an individual user with a known email addres.
        </Typography>

        <br />
        <Typography variant="body1" sx={Styles.description}>
            Or, use the <strong>Invite Code</strong> invitation type to generate an invite code that multiple users can
            claim through the EM Install app. Note that the created invite code must be shared with installers outside
            of the EM system.
        </Typography>
    </>
);
