import InfoIcon from '@mui/icons-material/Info';
import { Box, Divider, Typography } from '@mui/material';
import { Styles } from './styles';

export const TutorialInfoText = (): JSX.Element => (
    <Box data-testid="tutorial-info-text" component="div" sx={Styles.container}>
        <Box component="div" sx={Styles.header}>
            <InfoIcon></InfoIcon>
            <Typography variant="body2" sx={Styles.title}>
                There are two Auth Tokens that will be used throughout this tutorial.
            </Typography>
        </Box>
        <Typography variant={'body1'} sx={Styles.content}>
            <strong style={Styles.tokenName}>Application Auth Tokens</strong> are limited in their use, with the main
            use being the creation and management of <strong>Organizations</strong> and <strong> Streams </strong>. They
            can also be used to get a list of <strong>Devices</strong> application-wide, to aid in understanding the
            full scope of devices under the management of an <strong>Application</strong>. In summary, these tokens are
            generally used for setup activities.
        </Typography>
        <br />
        <Divider />
        <br />
        <Typography variant={'body1'} sx={Styles.content}>
            <strong style={Styles.tokenName}>Organization Auth Tokens</strong> are used to manage the{' '}
            <strong>Locations</strong> hierarchy, <strong>UserRoles</strong>, and <strong>Devices</strong> that belong
            to an <strong>Organization</strong>. These tokens are used at runtime to facilitate interaction with
            devices.
        </Typography>
        <br />
        <Divider />
        <br />
        <Typography variant={'body1'} sx={Styles.content}>
            Eaton <strong>does not</strong> offer end-user management for Applications, with the exception of user
            account management for Installers and Developers.
        </Typography>
        <br />
        <Typography variant={'body1'} sx={Styles.content}>
            It is the Application&apos;s responsibility to store and manage users of their system. The Developer is
            responsible for creating a middleware layer between the end-users of their system and the EM Platform.
            Specifically, a mapping should be kept of which Organizations, Locations, and Devices belong to which
            end-user(s). A user&apos;s requests should be mapped to an Organization so the middleware layer can make the
            request to the EM API using an <strong>Organization Auth Token</strong> on the user&apos;s behalf. This
            mapping is <strong>not</strong> stored or managed by the EM API.
        </Typography>
    </Box>
);
