export const Styles = {
    header: {
        marginBottom: '14px',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        margin: '8px',
        marginTop: '0px',
    },
    subheader: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '16px',
    },
};
