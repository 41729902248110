import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: Colors.blue['500'],
        marginBottom: '10px',
    },
    title: {
        marginLeft: '6px',
    },
    tokenName: {
        color: Colors.blue['500'],
    },
    content: {
        color: Colors.black['500'],
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '18px',
        backgroundColor: Colors.gray[50],
        margin: '25px',
    },
};
