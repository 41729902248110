import { Theme } from '@mui/material/styles';
import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    container: (theme: Theme) => ({
        paddingLeft: theme.spacing(1),
    }),
    circularProgress: {
        color: 'white',
    },
    button: {
        marginBottom: '25px',
        textTransform: 'uppercase',
    },
    buttonSave: { marginRight: '15px', minWidth: '64px' },
    errorTextField: {
        marginBottom: '25px',
        color: Colors.red[500],
    },
    divider: { marginBottom: '25px' },
};
