import { Box } from '@mui/material';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { Styles } from './styles';
import { TutorialAuthenticate } from './TutorialAuthenticate';
import { TutorialCommissionDevice } from './TutorialCommissionDevice';
import { TutorialControlBreaker } from './TutorialControlBreaker';
import { TutorialCreateAddressLocation } from './TutorialCreateAddressLocation';
import { TutorialCreateEquipmentLocation } from './TutorialCreateEquipmentLocation';
import { TutorialGetAssignableRoles } from './TutorialGetAssignableRoles';
import { TutorialIntro } from './TutorialIntro';
import { TutorialInviteInstaller } from './TutorialInviteInstaller';
import { TutorialListDevices } from './TutorialListDevices';
import { TutorialOrganization } from './TutorialOrganization';
import { TutorialOrganizationAuthenticate } from './TutorialOrganizationAuthenticate';

type ExpandedMapping = Record<number, boolean>;

const isExpanded = (expanded: ExpandedMapping, stepNumber: number): boolean => expanded[stepNumber] ?? false;

export const Tutorial: React.FC = () => {
    const [expanded, setExpanded] = useState<ExpandedMapping>({
        1: true, // First step starts expanded by default
    });
    const [furthestStepVisited, setFurthestStepVisited] = useState<number>(1);

    const updateFurthestStep = useCallback(
        (currentStep: number): void => {
            setFurthestStepVisited((currentFurthestStepVisited) => {
                if (currentStep > currentFurthestStepVisited) {
                    return currentStep;
                }

                return currentFurthestStepVisited;
            });
        },
        [setFurthestStepVisited]
    );

    const onRowClick = useCallback(
        (stepNumber: number): void => {
            setExpanded((current) => ({
                ...current,
                [stepNumber]: !isExpanded(current, stepNumber),
            }));
            updateFurthestStep(stepNumber);
        },
        [setExpanded, updateFurthestStep]
    );
    const onNextClick = useCallback(
        (stepNumber: number): void => {
            setExpanded((current) => ({
                ...current,
                [stepNumber]: false,
                [stepNumber + 1]: true,
            }));

            updateFurthestStep(stepNumber + 1);
        },
        [setExpanded, updateFurthestStep]
    );
    const onPrevClick = useCallback(
        (stepNumber: number): void => {
            setExpanded((current) => ({
                ...current,
                [stepNumber]: false,
                [stepNumber - 1]: true,
            }));
            updateFurthestStep(stepNumber - 1);
        },
        [setExpanded, updateFurthestStep]
    );

    const commonProps = {
        onClick: onRowClick,
        onNextClick,
        onPrevClick,
        furthestStepVisited,
    };

    return (
        <Box component="div" data-testid="tutorial-steps" sx={Styles.list}>
            <TutorialIntro stepNumber={1} expanded={isExpanded(expanded, 1)} {..._.omit(commonProps, 'onPrevClick')} />
            <TutorialAuthenticate stepNumber={2} expanded={isExpanded(expanded, 2)} {...commonProps} />
            <TutorialOrganization stepNumber={3} expanded={isExpanded(expanded, 3)} {...commonProps} />
            <TutorialOrganizationAuthenticate stepNumber={4} expanded={isExpanded(expanded, 4)} {...commonProps} />
            <TutorialCreateAddressLocation stepNumber={5} expanded={isExpanded(expanded, 5)} {...commonProps} />
            <TutorialCreateEquipmentLocation stepNumber={6} expanded={isExpanded(expanded, 6)} {...commonProps} />
            <TutorialGetAssignableRoles stepNumber={7} expanded={isExpanded(expanded, 7)} {...commonProps} />
            <TutorialInviteInstaller stepNumber={8} expanded={isExpanded(expanded, 8)} {...commonProps} />
            <TutorialCommissionDevice stepNumber={9} expanded={isExpanded(expanded, 9)} {...commonProps} />
            <TutorialListDevices stepNumber={10} expanded={isExpanded(expanded, 10)} {...commonProps} />
            <TutorialControlBreaker
                stepNumber={11}
                expanded={isExpanded(expanded, 11)}
                {..._.omit(commonProps, 'onNextClick')}
            />
        </Box>
    );
};
