import { Box, FormHelperText, Typography } from '@mui/material';
import React, { useState } from 'react';
import { MakeTextField } from 'src/client/components/HelperComponents/TutorialHelpers/MakeTextField';
import {
    ADDRESS_LOCATION_ID,
    EM_API_SUBSCRIPTION_KEY,
    ORGANIZATION_AUTH_TOKEN,
    ORGANIZATION_ID,
} from 'src/client/constants/tutorial';
import { useAsyncFn } from 'src/client/hooks/async-fn-hook';
import { SimulatedEmApiClient } from 'src/client/simulated-em-api/simulated-em-api-client';
import { Validator } from 'src/client/util/validator';
import { withDelay } from 'src/shared/lib/with-delay';
import { BasicDropdown } from '../../BasicDropdown';
import { RequestDefinition, RequestResponse } from '../../RequestResponse';
import { SubmitButton } from '../../SubmitButton';
import { Styles } from '../styles';
import { TutorialExpandable, TutorialStepProps } from '../TutorialExpandable';

type CreateEquipmentTextFields = {
    emApiSubscriptionKey: string;
    organizationAuthToken: string;
    organizationId: string;
    parentLocationId: string;
    name: string;
    description?: string;
    contact?: string;
    email?: string;
    phone?: string;
    locationType: string;
};

export const TutorialCreateEquipmentLocation: React.FC<TutorialStepProps> = React.memo((props) => {
    const [createEquipmentLocationTextFieldValues, setCreateEquipmentLocationBodyValues] =
        useState<CreateEquipmentTextFields>({
            emApiSubscriptionKey: EM_API_SUBSCRIPTION_KEY,
            organizationAuthToken: ORGANIZATION_AUTH_TOKEN,
            organizationId: ORGANIZATION_ID,
            parentLocationId: ADDRESS_LOCATION_ID,
            name: 'Main Breaker Box',
            locationType: 'equipment',
        });

    const handleChange = (
        value: string
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ): void =>
        setCreateEquipmentLocationBodyValues({
            ...createEquipmentLocationTextFieldValues,
            locationType: value,
        });

    const [inFlight, response, invokeAsyncFn] = useAsyncFn(
        withDelay(750, SimulatedEmApiClient.createEquipmentLocation)
    );

    const requestBody = {
        organizationId: createEquipmentLocationTextFieldValues.organizationId,
        name: createEquipmentLocationTextFieldValues.name,
        description: createEquipmentLocationTextFieldValues.description,
        parentLocationId: createEquipmentLocationTextFieldValues.parentLocationId,
        contact: createEquipmentLocationTextFieldValues.contact,
        email: createEquipmentLocationTextFieldValues.email,
        phone: createEquipmentLocationTextFieldValues.phone,
        locationType: createEquipmentLocationTextFieldValues.locationType,
    };

    const submitRequest = (): void => invokeAsyncFn(requestBody);

    const request: RequestDefinition = {
        method: 'POST',
        endpointPath: '/locations',
        headers: [
            { name: 'Em-Api-Subscription-Key', value: createEquipmentLocationTextFieldValues.emApiSubscriptionKey },
            { name: 'Accept', value: 'application/json' },
            { name: 'Content-Type', value: 'application/json; charset=utf-8' },
            { name: 'Authorization', value: `Bearer ${createEquipmentLocationTextFieldValues.organizationAuthToken}` },
        ],
        body: requestBody,
    };

    const required = {
        type: 'string',
        presence: {
            allowEmpty: false,
        },
    } as const;

    //running validate on the values instead of the schema (since the schema is hardcoded)
    const validationErrors = Validator.validateSchema(createEquipmentLocationTextFieldValues, {
        emApiSubscriptionKey: {
            type: 'string',
            format: {
                pattern: `^${EM_API_SUBSCRIPTION_KEY}$`,
                message: `needs to match the api key assigned to your application. The tutorial application's key is: ${EM_API_SUBSCRIPTION_KEY}`,
            },
            presence: {
                allowEmpty: false,
            },
        },
        organizationAuthToken: {
            type: 'string',
            format: {
                pattern: `^${ORGANIZATION_AUTH_TOKEN}$`,
                message: `is the token you got from step 4. This represents being logged in as the organization and grants the ability to add locations to the organization. For the tutorial this token is: ${ORGANIZATION_AUTH_TOKEN}`,
            },
            presence: {
                allowEmpty: false,
            },
        },
        parentLocationId: {
            type: 'string',
            format: {
                pattern: `^${ADDRESS_LOCATION_ID}$`,
                message: `is the location id that was returned in the response from the previous step. For the tutorial, this value is: ${ADDRESS_LOCATION_ID}`,
            },
            presence: {
                allowEmpty: false,
            },
        },
        name: required,
        organizationId: {
            type: 'string',
            format: {
                pattern: `^${ORGANIZATION_ID}$`,
                message: `is the id of the organization that was created in step 3. For the tutorial this is: ${ORGANIZATION_ID}.`,
            },
            presence: {
                allowEmpty: false,
            },
        },
        locationType: required,
    });

    return (
        <TutorialExpandable
            {...props}
            title="Create an Equipment Location"
            description={
                <>
                    <Typography variant="body1" sx={Styles.description}>
                        In general, breakers are typically installed into a Loadcenter, sometimes referred to as a
                        &quot;breaker box&quot;, or into another properly-rated enclosure. All of these possible
                        enclosure types are represented by an <strong>Equipment Location</strong>, or the enclosure that
                        the physical device will be installed into. Using an Organization Auth Token and the Address
                        Location ID, create the equipment location.
                    </Typography>
                    <br />
                    <Typography variant="body1" sx={Styles.description}>
                        Using an <strong>Organization Auth Token</strong> and the <strong>Address Location ID</strong>,
                        create the equipment location.
                    </Typography>
                </>
            }
            valueField={
                <Box component="div" sx={Styles.values}>
                    <form noValidate autoComplete="off">
                        <Box sx={Styles.entryFields}>
                            {MakeTextField(
                                createEquipmentLocationTextFieldValues,
                                validationErrors,
                                'emApiSubscriptionKey',
                                'EM API Subscription Key',
                                setCreateEquipmentLocationBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                createEquipmentLocationTextFieldValues,
                                validationErrors,
                                'organizationAuthToken',
                                'Organization Auth Token',
                                setCreateEquipmentLocationBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                createEquipmentLocationTextFieldValues,
                                validationErrors,
                                'organizationId',
                                'Organization ID',
                                setCreateEquipmentLocationBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                createEquipmentLocationTextFieldValues,
                                validationErrors,
                                'parentLocationId',
                                'Parent Location ID',
                                setCreateEquipmentLocationBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                createEquipmentLocationTextFieldValues,
                                validationErrors,
                                'name',
                                'Name',
                                setCreateEquipmentLocationBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                createEquipmentLocationTextFieldValues,
                                validationErrors,
                                'description',
                                'Description',
                                setCreateEquipmentLocationBodyValues
                            )}
                            {MakeTextField(
                                createEquipmentLocationTextFieldValues,
                                validationErrors,
                                'contact',
                                'Contact',
                                setCreateEquipmentLocationBodyValues
                            )}
                            {MakeTextField(
                                createEquipmentLocationTextFieldValues,
                                validationErrors,
                                'email',
                                'Email',
                                setCreateEquipmentLocationBodyValues
                            )}
                            {MakeTextField(
                                createEquipmentLocationTextFieldValues,
                                validationErrors,
                                'phone',
                                'Phone',
                                setCreateEquipmentLocationBodyValues
                            )}
                            <BasicDropdown
                                sx={
                                    createEquipmentLocationTextFieldValues.locationType !== 'equipment'
                                        ? undefined
                                        : Styles.formField
                                }
                                data-testid="location-type-dropdown"
                                onChange={handleChange}
                                items={['address', 'equipment']}
                                label="Location Type"
                                value={createEquipmentLocationTextFieldValues.locationType}
                            />
                            {createEquipmentLocationTextFieldValues.locationType !== 'equipment' ? (
                                <FormHelperText sx={Styles.formField}>
                                    Required to be &lsquo;equipment&lsquo; for this step. This step is using the address
                                    location created in the previous step to mark where equipment is installed.
                                </FormHelperText>
                            ) : (
                                <></>
                            )}
                        </Box>
                    </form>
                    <SubmitButton
                        id="send-request-button"
                        onClick={submitRequest}
                        disabled={!!validationErrors}
                        spinning={inFlight}
                        buttonText="Send Request"
                        width={144}
                    />
                </Box>
            }
            requestResponse={<RequestResponse request={request} response={response} />}
        />
    );
});
