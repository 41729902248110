import * as Colors from '@brightlayer-ui/colors';
export const Styles = {
    box: { marginBottom: '25px', maxWidth: 'fit-content' },
    tabs: {
        marginTop: '14px',
        '& .MuiTabs-scroller': {
            height: '62px',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: Colors.blue[500],
        },
    },
    textColorPrimary: {
        color: Colors.black[500],
        opacity: 0.7,
        '&.Mui-selected': {
            color: Colors.black[500],
            opacity: 1,
        },
    },
};
