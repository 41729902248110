import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { ApiClient } from 'src/client/api-client';
import { TeamRole } from 'src/shared/types/developer-portal-roles';
import { GenericConfirmationModal } from '../GenericConfirmationModal';
import { Styles } from './styles';
import { useApp } from 'src/client/contexts/AppContextProvider';

type ChangeRoleModalProps = {
    newRole: TeamRole;
    targetUserId: string;
    targetDisplayName: string;
    team: {
        id: string;
        name: string;
    };
    isLastExistingOwner: boolean;
    onClose: () => void;
    onSuccess: () => void;
};
export const ChangeRoleConfirmationModal: React.FC<ChangeRoleModalProps> = (props) => {
    const [errorText, setErrorText] = useState<string>('');
    const app = useApp();

    const changeRoleOnClick = async (): Promise<void> => {
        const result = await ApiClient.updateTeamUser(props.team.id, props.targetUserId, props.newRole);

        if (!result.success) {
            setErrorText(`Failed to change role. ${result.error || ''} `);
            return;
        }
        props.onSuccess();
        props.onClose();
    };

    const preventRoleChange = (): boolean => app.loginData.userId === props.targetUserId && props.isLastExistingOwner;
    const isCurrentUser = (): boolean => app.loginData.userId === props.targetUserId;

    const defaultChangeRoleContent = (
        <Box sx={Styles.modalTextContainer}>
            {preventRoleChange() ? (
                <>
                    <Typography sx={Styles.confirmationQuestionText}>
                        You are the only owner of team <b>{props.team.name}</b>.
                    </Typography>
                    <Typography sx={Styles.confirmationInstructionalText}>
                        In order to change your role, you will have to first assign ownership to another member.
                    </Typography>
                </>
            ) : (
                <>
                    <Typography sx={Styles.confirmationQuestionText} data-testid="modal-text-container">
                        Are you sure you want to make <b>{isCurrentUser() ? 'yourself' : props.targetDisplayName} </b>a
                        {props.newRole === TeamRole.Owner ? 'n' : ''} <b>{props.newRole}</b> of team{' '}
                        <b>{props.team.name}</b>?
                    </Typography>
                    {isCurrentUser() && props.newRole === TeamRole.Member && (
                        <Typography sx={Styles.errorInformationText} data-testid="modal-warning-text">
                            This action cannot be undone. You will have to be promoted by another owner to regain your
                            current role.
                        </Typography>
                    )}
                </>
            )}
        </Box>
    );

    return (
        <GenericConfirmationModal
            errorText={errorText}
            modalTitle={'Change User Role'}
            submitActionButtonText={'Change Role'}
            submitActionButtonDisabled={preventRoleChange()}
            cancelActionButtonText={'Cancel'}
            submitActionButtonOnClick={changeRoleOnClick}
            onClose={props.onClose}
        >
            {defaultChangeRoleContent}
        </GenericConfirmationModal>
    );
};
