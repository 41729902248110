import { CompleteAccountFlow } from 'src/client/components/CompleteAccountFlow';
import { ApiClient } from 'src/client/api-client';
import { useRequest } from 'src/client/hooks/request-hook';
import { Spinner } from 'src/client/components/Spinner';
import { DisplayError } from 'src/client/components/DisplayError';
import { CardContainer } from 'src/client/components/CardContainer';
import { Styles } from './styles';
import { useApp } from 'src/client/contexts/AppContextProvider';

export const CompleteAccountPage: React.FC = () => {
    const app = useApp();
    const [isLoading, userInformation] = useRequest(() => ApiClient.getUserInformation());

    return (
        <CardContainer>
            {isLoading ? (
                <Spinner sx={Styles.spinner} />
            ) : !userInformation ? (
                <DisplayError sx={Styles.error} />
            ) : (
                <CompleteAccountFlow
                    showAcceptEula={!app.loginData.isEulaAccepted}
                    showAccountEdit={!app.loginData.isProfileComplete}
                    accountInformation={userInformation}
                />
            )}
        </CardContainer>
    );
};
