import React, { useEffect, useState, useCallback } from 'react';
import { ApiClient } from 'src/client/api-client';
import { ResultSuccessData } from 'src/client/types/result';
import { Styles } from './styles';
import { useNavigate } from 'react-router-dom';
import { EntitlementActivationModal } from 'src/client/pages/EntitlementActivationModal';
import { LocalStorage } from 'src/client/store/local-storage';
import { Spinner } from 'src/client/components/Spinner';
import { Box } from '@mui/material';
import { ApplicationCreateForm } from 'src/client/components/ApplicationCreate/ApplicationCreateForm';
import { Validator } from 'src/client/util/validator';
import { ApplicationConfirmationDisplay } from './ApplicationConfirmationDisplay';

type Entitlements = ResultSuccessData<typeof ApiClient.getEntitlementsDetails>;

type EntitlementDetails = {
    activationId: string;
    expirationDate?: string;
    deviceLimit: number;
    duration?: string;
};

type Application = ResultSuccessData<typeof ApiClient.createApplication>;

export const ApplicationCreate: React.FC = () => {
    const [entitlementDataByEmail, setEntitlementDataByEmail] = useState<Entitlements>();
    const [isLoadingEntitlementData, setIsLoadingEntitlementData] = useState(false);
    const [inFlight, setInFlight] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [createApplicationResult, setCreateApplicationResult] = useState<Application | undefined>(undefined);
    const [selectedEntitlement, setSelectedEntitlement] = useState<EntitlementDetails>();

    const navigate = useNavigate();

    useEffect(() => {
        setIsLoadingEntitlementData(true);
        const fetchData = async () => {
            const authData = await LocalStorage.readAuthData();
            const entitlementsResult = await ApiClient.getEntitlementsDetails({ email: authData?.email });
            if (entitlementsResult.success) {
                setEntitlementDataByEmail(entitlementsResult.data);
            }
            setIsLoadingEntitlementData(false);
        };
        void fetchData();
    }, []);

    const navigateTo = (route: string): void => {
        navigate(route);
    };

    const handleClose = () => {
        navigateTo(`/applications`);
        setEntitlementDataByEmail(undefined);
    };

    const clearErrorMessage = useCallback(() => {
        setErrorMessage(undefined);
    }, [setErrorMessage]);

    if (createApplicationResult) {
        return <ApplicationConfirmationDisplay application={createApplicationResult} />;
    }

    const onSubmitClick = async (applicationName: string | undefined): Promise<void> => {
        const validation = Validator.validateSchema(
            { applicationName },
            {
                applicationName: {
                    type: 'string',
                    length: { maximum: 255 },
                    presence: {
                        allowEmpty: false,
                    },
                },
            }
        );

        if (!validation && applicationName && selectedEntitlement) {
            setInFlight(true);
            const result = await ApiClient.createApplication({
                name: applicationName,
                entitlementDetails: { activationId: selectedEntitlement.activationId },
            });
            setInFlight(false);

            if (!result.success) {
                setErrorMessage(result.error);
                return;
            }
            setCreateApplicationResult(result.data);
        }

        if (validation?.applicationName && validation.applicationName?.length > 0) {
            setErrorMessage(validation.applicationName[0] || 'Unknown Error');
        }
    };

    return (
        <Box sx={Styles.body}>
            {isLoadingEntitlementData ? (
                <Spinner />
            ) : (
                !selectedEntitlement && (
                    <EntitlementActivationModal
                        entitlementDetails={entitlementDataByEmail}
                        handleClose={handleClose}
                        onActivate={(entitlement) => {
                            setSelectedEntitlement(entitlement);
                        }}
                    />
                )
            )}
            {selectedEntitlement && (
                <ApplicationCreateForm
                    errorMessage={errorMessage}
                    inFlight={inFlight}
                    clearErrorMessage={clearErrorMessage}
                    onSubmit={onSubmitClick}
                    data={selectedEntitlement}
                />
            )}
        </Box>
    );
};
