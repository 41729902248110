import * as Colors from '@brightlayer-ui/colors';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { IconButton } from '@mui/material';
import { Close, NotificationImportant } from '@mui/icons-material';
import { useNotification } from 'src/client/contexts/NotificationContextProvider';

const getStatusLevel = (
    color: 'yellow' | 'green' | 'blue' | 'red'
): { backgroundColor: string; icon: JSX.Element; closeIcon: JSX.Element } => {
    switch (color) {
        case 'green':
            return {
                backgroundColor: Colors.green[100],
                icon: <NotificationImportant style={{ color: Colors.green[700] }} />,
                closeIcon: <Close style={{ color: Colors.green[700] }} />,
            };
        case 'yellow':
            return {
                backgroundColor: Colors.yellow[100],
                icon: <NotificationImportant style={{ color: Colors.yellow[700] }} />,
                closeIcon: <Close style={{ color: Colors.yellow[700] }} />,
            };
        case 'blue':
            return {
                backgroundColor: Colors.blue[100],
                icon: <NotificationImportant style={{ color: Colors.blue[700] }} />,
                closeIcon: <Close style={{ color: Colors.blue[700] }} />,
            };
        default:
            return {
                backgroundColor: Colors.red[100],
                icon: <NotificationImportant style={{ color: Colors.red[700] }} />,
                closeIcon: <Close style={{ color: Colors.red[700] }} />,
            };
    }
};

export const NotificationBanner: React.FC<{ paddingTop?: number }> = (props) => {
    const notification = useNotification();

    if (notification.notificationOpen) {
        const styleProp = props.paddingTop ? { paddingTop: props.paddingTop } : {};

        const statusLevel = getStatusLevel(notification.color);
        return (
            <InfoListItem
                data-testid="notification-banner"
                title={notification.displayText}
                wrapTitle
                dense
                style={styleProp}
                fontColor={Colors.black[400]}
                rightComponent={
                    <IconButton onClick={notification.dismissNotification} data-testid="dismiss-notification-button">
                        {statusLevel.closeIcon}
                    </IconButton>
                }
                icon={statusLevel.icon}
                backgroundColor={statusLevel.backgroundColor}
            />
        );
    }

    return <></>;
};
