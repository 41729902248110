export enum Departments {
    IT = 'Information Technology',
    HR = 'Human Resources',
    OPERATIONS = 'Operations',
    MARKETING = 'Marketing',
    ACCOUNTING = 'Accounting',
    SALES = 'Sales',
    RESEARCH = 'Research and Developement',
    MANUFACTURING = 'Manufacturing',
    SUPPLYCHAINMANAGEMENT = 'Supply Chain Management',
    QUALITYASSURANCE = 'Quality Assurance',
    HEALTHANDSAFETY = 'Health and Safety',
    OTHER = 'Other',
}
