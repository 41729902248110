import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';
import * as Types from 'server/api/types';

export const getEntitlementByApplicationId = (
    applicationId: string
): Promise<RequestHelperResult<Types.GetEntitlementByApplicationIdResponseBody['data']>> =>
    axiosRequestHelper<Types.GetEntitlementByApplicationIdResponseBody>(
        'GET',
        `/api/applications/${applicationId}/entitlement`
    );
