import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 2,
    },
    circularProgress: {
        color: 'white',
    },
    infoData: {
        textAlign: 'start',
        padding: 1,
        background: Colors.white[200],
    },
    infoOuterBox: {
        alignContent: 'center',
        textAlign: 'center',
        marginBottom: 2,
    },
    errorMsg: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    emptyState: {
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dialogBlock: {
        backgroundColor: Colors.blue[700],
        textAlign: 'center',
        color: Colors.white[50],
    },
    inputBox: {
        textAlign: 'center',
        margin: 2,
        fontWeight: 'bold',
    },
    inputLabel: {
        min: 10,
        textAlign: 'center',
    },
};
