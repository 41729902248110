import { Box, Button, Typography } from '@mui/material';
import { Styles } from './styles';

export const ScrollToTopButton: React.VoidFunctionComponent = () => {
    const scrollToTop = (): void =>
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

    return (
        <Box sx={Styles.buttonContainer}>
            <Button variant="contained" color="primary" onClick={scrollToTop} sx={Styles.topButton}>
                <Typography variant="body2">Scroll to Top</Typography>
            </Button>
        </Box>
    );
};
