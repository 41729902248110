import { Box, Button, Divider, Typography } from '@mui/material';
import React, { MouseEventHandler, ReactElement, useCallback } from 'react';
import { SelectableDropdownListItem } from '../../SelectableDropdownListItem';
import { TutorialStepLabel } from '../TutorialStepLabel';
import { Styles } from './styles';

export type TutorialStepProps = Pick<
    TutorialExpandableProps,
    'stepNumber' | 'expanded' | 'onClick' | 'onNextClick' | 'onPrevClick' | 'furthestStepVisited'
>;

type VisitedState = React.ComponentProps<typeof TutorialStepLabel>['visited'];

type TutorialExpandableProps = {
    furthestStepVisited: number;
    stepNumber: number;
    title: string;
    description: ReactElement;
    valueField: ReactElement;
    requestResponse?: ReactElement;
    expanded?: boolean;
    onClick: (stepNumber: number) => void;
    onNextClick?: (stepNumber: number) => void;
    onPrevClick?: (stepNumber: number) => void;
};

export const TutorialExpandable: React.FC<TutorialExpandableProps> = (props) => {
    const stepNumber = props.stepNumber;
    const proppedOnClick = props.onClick;
    const proppedOnNextClick = props.onNextClick;
    const proppedOnPrevClick = props.onPrevClick;

    const visitedState = (): VisitedState => {
        if (props.furthestStepVisited > props.stepNumber) {
            return 'Visited';
        } else if (props.furthestStepVisited === props.stepNumber) {
            return 'Current';
        }
        return 'Not Visited';
    };

    const onClickHandler: MouseEventHandler<HTMLElement> = (e): void => {
        e.stopPropagation();
        proppedOnClick(stepNumber);
    };
    const onClick = useCallback(onClickHandler, [stepNumber, proppedOnClick]);

    const onNextClickHandler: MouseEventHandler<HTMLElement> = (e): void => {
        e.stopPropagation();
        if (proppedOnNextClick) {
            proppedOnNextClick(stepNumber);
        }
    };

    const onPrevClickHandler: MouseEventHandler<HTMLElement> = (e): void => {
        e.stopPropagation();
        if (proppedOnPrevClick) {
            proppedOnPrevClick(stepNumber);
        }
    };

    const onNextClick = useCallback(onNextClickHandler, [stepNumber, proppedOnNextClick]);
    const onPrevClick = useCallback(onPrevClickHandler, [stepNumber, proppedOnPrevClick]);

    return (
        <SelectableDropdownListItem
            title={props.title}
            expanded={props.expanded}
            onClick={onClick}
            leftComponent={<TutorialStepLabel stepNumber={props.stepNumber} visited={visitedState()} />}
            expandableComponent={
                <Box component="div" sx={Styles.rowItems}>
                    <Box component="div" sx={Styles.content}>
                        <Box component="div" sx={Styles.rowItems}>
                            <span>
                                <Typography sx={Styles.overviewTitle} variant="body1">
                                    <strong>Step Overview: </strong>
                                </Typography>
                                {props.description}
                            </span>
                            {props.valueField}
                        </Box>
                        {props.requestResponse && (
                            <Box component="div" sx={Styles.requestResponse}>
                                {props.requestResponse}
                            </Box>
                        )}
                    </Box>
                    <Divider />
                    <Box component="div" sx={Styles.footer}>
                        {props.onPrevClick ? (
                            <Button sx={Styles.prevButton} onClick={onPrevClick} variant="outlined" color="primary">
                                Back
                            </Button>
                        ) : (
                            <div />
                        )}
                        {props.onNextClick && (
                            <Button
                                id="next-tutorial"
                                sx={Styles.nextButton}
                                onClick={onNextClick}
                                variant="contained"
                                color="primary"
                            >
                                Next
                            </Button>
                        )}
                    </Box>
                </Box>
            }
        ></SelectableDropdownListItem>
    );
};
