import * as Types from 'server/api/types';
import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';

export const userPasswordReset = async (
    email: string,
    verificationCode?: string,
    password?: string
): Promise<RequestHelperResult<unknown>> => {
    const requestBody: Types.RequestOrConfirmPasswordResetRequestBody = {
        email: email,
        password: password,
    };

    const queryParameters: Types.RequestOrConfirmPasswordResetQueryParams = {
        code: verificationCode,
    };

    return await axiosRequestHelper('POST', '/api/users/passwordResets', {
        data: requestBody,
        params: queryParameters,
    });
};
