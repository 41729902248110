import { Box, CardContent, CardHeader, Checkbox, FormControlLabel, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { ApiClient } from 'src/client/api-client';
import { useRequest } from 'src/client/hooks/request-hook';
import { Styles } from '../styles';

export const EulaContent: React.FC<{
    eulaAccepted: boolean;
    setEulaAccepted: (isAccepted: boolean) => void;
}> = (props): JSX.Element => {
    // i18n (translation) is not part of Phase 1 of App, hence 'en-US' is hardcoded in.
    // This will need to be updated at some point.
    const [isLoading, eulaContent] = useRequest(() => ApiClient.getEula('en-US'));

    return (
        <>
            <CardHeader
                data-testid="card-header"
                title={
                    <Typography variant={'h6'} sx={Styles.heavyFont}>
                        License Agreement
                    </Typography>
                }
                sx={Styles.dialogTitle}
            />
            <CardContent sx={Styles.dialogContent} data-testid="card-content">
                {isLoading ? <Typography variant="body1">Loading End User License Agreement...</Typography> : ''}
                {eulaContent && (
                    <>
                        <Box
                            sx={Styles.eulaContent}
                            dangerouslySetInnerHTML={{
                                /* eslint-disable-next-line @typescript-eslint/naming-convention */
                                __html: DOMPurify.sanitize(eulaContent),
                            }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={'primary'}
                                    checked={props.eulaAccepted}
                                    disabled={!eulaContent}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                        props.setEulaAccepted(event.target.checked)
                                    }
                                />
                            }
                            label={'I have read and agree to the Terms & Conditions'}
                            sx={Styles.checkbox}
                        />
                    </>
                )}
            </CardContent>
        </>
    );
};
