import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Typography,
    InputLabel,
    Input,
    Link,
    Stack,
    Box,
} from '@mui/material';
import { RadioCardModal } from 'src/client/components/RadioCardModal';
import { Styles } from './style';
import { Error } from '@mui/icons-material';
import React, { useState } from 'react';
import { IMaskInput } from 'react-imask';
import { ApiClient } from 'src/client/api-client';
import { Spinner } from 'src/client/components/Spinner';
import * as Colors from '@brightlayer-ui/colors';
import { ResultSuccessData } from 'src/client/types/result';
import { DateTimeHelpers } from 'src/shared/lib/date-helpers';

type EntitlementsList = ResultSuccessData<typeof ApiClient.getEntitlementsDetails>;

const expectedActivationKeyLength = 32;

type Entitlement = {
    activationId: string;
    expirationDate?: string | undefined;
    deviceLimit: number;
    duration?: string;
};
type EntitlementActivationModalProps = {
    handleClose: VoidFunction;
    entitlementDetails: Entitlement[] | undefined;
    onActivate: (selectedEntitlement: Entitlement) => void;
};

type InputMaskState = {
    textmask: string;
};

type CustomProps = {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
};

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>((props, ref) => {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="####-####-####-####-####-####-####-####"
            definitions={{
                '#': /[A-Za-z0-9]/,
            }}
            inputRef={ref}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

export const EntitlementActivationModal = (props: EntitlementActivationModalProps): JSX.Element => {
    const [values, setValues] = useState<InputMaskState>({
        textmask: '',
    });
    const [activationKey, setActivationKey] = useState<string | null>(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const [hasSubscriptionDetails, setHasSubscriptionDetails] = useState(false);
    const [getEntitlementsResult, setEntitlementsResult] = useState<Entitlement>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [entitlementDetails, setEntitlementDetails] = useState<EntitlementsList | undefined>(
        props.entitlementDetails
    );
    const [showModal, setShowModal] = useState(true);

    const activateClick = () => {
        if (getEntitlementsResult) {
            props.onActivate(getEntitlementsResult);
        }
        setShowModal(false);
    };

    const getEntitlementByActivationKey = async (activationKeyData: string | undefined) => {
        setShowSpinner(true);

        const result = await ApiClient.getEntitlementsDetails({ activationId: activationKeyData });

        if (!result.success || !result.data[0]?.deviceLimit) {
            setErrorMessage('Invalid Activation Key');
            setHasSubscriptionDetails(true);
            setShowSpinner(false);
            return;
        }

        if (result.success) {
            setEntitlementsResult(result.data[0]);
            setErrorMessage(undefined);
            setShowSpinner(false);
            setHasSubscriptionDetails(true);
        }
    };

    const handleRadioSelect = (value: string) => {
        setEntitlementDetails(undefined);
        setActivationKey(value);
        setValues({
            ...values,
            textmask: value,
        });
    };

    const handleClose = () => {
        props.handleClose();
        setActivationKey(null);
        setHasSubscriptionDetails(false);
        setValues({ textmask: '' });
    };

    return (
        <>
            <Dialog data-testid="entitlement-activation-modal" open={showModal} onClose={handleClose}>
                <DialogTitle data-testid="enter-activation-title" sx={Styles.dialogBlock}>
                    Enter Activation Key
                </DialogTitle>
                <DialogContent>
                    <DialogContentText data-testid="enter-activation-text" sx={Styles.inputBox}>
                        Enter the Application Activation Key
                    </DialogContentText>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel
                            data-testid="enter-activation-label"
                            htmlFor="formatted-text-mask-input"
                            sx={Styles.inputLabel}
                        >
                            Activation Key
                        </InputLabel>
                        <Input
                            data-testid="enter-activation-input"
                            margin="dense"
                            fullWidth
                            onChange={(event): void => {
                                setActivationKey(event.target.value);
                                setValues({
                                    ...values,
                                    [event.target.name]: event.target.value,
                                });
                                if (event.target.value.replace(/[-]/g, '').length === expectedActivationKeyLength) {
                                    setEntitlementDetails(undefined);
                                    void getEntitlementByActivationKey(event.target.value);
                                }
                                if (event.target.value.replace(/[-]/g, '').length === 0) {
                                    setEntitlementDetails(props.entitlementDetails);
                                }
                                setHasSubscriptionDetails(false);
                            }}
                            name="textmask"
                            id="formatted-text-mask-input"
                            value={values.textmask}
                            inputComponent={TextMaskCustom as any}
                            inputProps={{ min: 0, style: { textAlign: 'center' } }}
                        />
                    </FormControl>
                    <Box sx={Styles.infoOuterBox}>
                        <DialogContentText data-testid="enter-activation-info-data" marginTop={2}>
                            If you need to purchase an activation key, please visit the link below.
                        </DialogContentText>
                        <Link
                            data-testid="purchase-activation-hyperlink"
                            href="https://www.eaton.com/us/en-us/digital/marketplace/smart-breaker-api.html"
                        >
                            Purchase Activation Key
                        </Link>
                    </Box>
                    {!!entitlementDetails?.length && (
                        <RadioCardModal data={entitlementDetails} onRadioSelect={handleRadioSelect} />
                    )}
                    <Box>
                        {showSpinner ? <Spinner sx={Styles.emptyState} /> : ''}
                        {hasSubscriptionDetails && (
                            <Box sx={Styles.infoData}>
                                {!errorMessage && getEntitlementsResult ? (
                                    <>
                                        <Typography data-testid="label-text-testId" variant="subtitle2">
                                            Subscription Details:
                                        </Typography>
                                        <Typography variant="body2">
                                            {getEntitlementsResult?.deviceLimit} Devices Available
                                        </Typography>
                                        <Typography variant="body2">
                                            {getEntitlementsResult?.duration
                                                ? `Expires ${getEntitlementsResult.duration} from first device activation`
                                                : getEntitlementsResult.expirationDate
                                                ? `Expires on ${DateTimeHelpers.formatDateStr(
                                                      new Date(getEntitlementsResult.expirationDate).toISOString()
                                                  )}`
                                                : 'Expiration date not defined'}
                                        </Typography>
                                    </>
                                ) : (
                                    <Box sx={Styles.errorMsg}>
                                        <Error htmlColor={Colors.red[500]} />
                                        <Typography
                                            margin={1}
                                            data-testid="error-msg-testId"
                                            variant="subtitle2"
                                            color={Colors.red[500]}
                                        >
                                            {errorMessage}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        )}
                        <Stack sx={Styles.buttonContainer} spacing={2} direction="row">
                            <Button
                                data-testid="activate-button"
                                variant="contained"
                                onClick={activateClick}
                                disabled={
                                    activationKey?.replace(/[-]/g, '').length !== expectedActivationKeyLength ||
                                    showSpinner ||
                                    Boolean(errorMessage)
                                }
                            >
                                Activate
                            </Button>
                            <Button variant="contained" onClick={handleClose}>
                                Cancel
                            </Button>
                        </Stack>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
