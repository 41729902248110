/* eslint-disable @typescript-eslint/no-redeclare */

// Enum style based on: // https://github.com/microsoft/TypeScript/issues/3192#issuecomment-261720275
function mkenum<T extends { [index: string]: U }, U extends string>(x: T): T {
    return x;
}

export const TeamRole = mkenum({
    Member: 'Member',
    Owner: 'Owner',
});
export type TeamRole = (typeof TeamRole)[keyof typeof TeamRole];

export const ApplicationRole = mkenum({
    Admin: 'Admin',
    Developer: 'Developer',
});
export type ApplicationRole = (typeof ApplicationRole)[keyof typeof ApplicationRole];

export const TeamKind = mkenum({
    Group: 'Group',
    Individual: 'Individual',
});

export type TeamKind = (typeof TeamKind)[keyof typeof TeamKind];
