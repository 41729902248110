import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    codeBlock: {
        minHeight: '210px',
        maxHeight: '500px',
        backgroundColor: Colors.gray[900],
        overflow: 'auto',
        color: Colors.white[50],
        marginBottom: '15px',
        paddingBottom: '20px',
        paddingRight: '15px',
    },
    codeTitle: {
        marginTop: '12px',
        marginLeft: '20px',
    },
    codeContainer: {
        fontFamily: 'monospace',
        fontSize: '0.8rem',
        lineHeight: '18px',
        marginLeft: '20px',
        marginTop: '14px',
        wordBreak: 'break-all',
        whiteSpace: 'pre-wrap',
        '& span': {
            display: 'block',
            class: 'tab',
        },
    },
    bodyText: {
        maxWidth: '95%',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
    },
    button: {
        textTransform: 'uppercase',
        borderRadius: '50px',
    },
};
