import * as Types from 'server/api/types';
import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';

type CreateAuthTokenResponse = {
    id: string;
    email: string;
    firstName?: string;
    lastName?: string;
    isEulaAccepted: boolean;
    isProfileComplete?: boolean;
};

export const createAuthToken = async (
    email: string,
    password: string
): Promise<RequestHelperResult<CreateAuthTokenResponse>> => {
    const requestBody: Types.SecurityTokensCreateRequestBody = {
        email: email,
        password: password,
    };
    const result = await axiosRequestHelper<Types.SecurityTokensCreateResponseBody>('POST', '/api/security/tokens', {
        data: requestBody,
    });

    if (!result.success) {
        return result;
    }

    return {
        success: true,
        data: {
            id: result.data.user.id,
            email: result.data.user.email,
            firstName: result.data.user.firstName,
            lastName: result.data.user.lastName,
            isProfileComplete: result.data.isProfileComplete,
            isEulaAccepted: result.data.isEulaAccepted,
        },
    };
};
