import _ from 'lodash';
import React from 'react';
import { UsersListHeader } from '../UsersListHeader';
import { UserAbilities } from 'src/client/types/user-abilities';
import { UsersListItemContainer } from '../UserListItemContainer';
import { TeamRole } from 'src/shared/types/developer-portal-roles';
import { useApp } from 'src/client/contexts/AppContextProvider';

type Member = {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    teamRole: TeamRole;
    isPendingInvite?: boolean;
};

type UsersListProps = {
    id: string;
    name: string;
    users: Member[];
    userAbilities: UserAbilities;
    actionButtonText?: string;
    actionButtonOnClick?: () => void;
    requeryTeamDetails: () => void;
};

const userSort = (first: Member, second: Member): number => {
    const firstFullName = `${first.firstName} ${first.lastName}`;
    const secondFullName = `${second.firstName} ${second.lastName}`;
    const firstVal = (
        _.isEmpty(first.firstName) && _.isEmpty(first.lastName) ? first.email : firstFullName
    ).toUpperCase();
    const secondVal = (
        _.isEmpty(second.firstName) && _.isEmpty(second.lastName) ? second.email : secondFullName
    ).toUpperCase();
    if (firstVal < secondVal) {
        return -1;
    }
    if (firstVal > secondVal) {
        return 1;
    }
    return 0;
};

export const UsersList: React.FC<UsersListProps> = (props) => {
    const app = useApp();
    const sorted = _.clone(props.users).sort(userSort);

    const buttonText = props.userAbilities.includes('Edit') ? props.actionButtonText : undefined;
    const buttonAction = props.userAbilities.includes('Edit') ? props.actionButtonOnClick : undefined;

    const loggedInUser = sorted.find((member) => member.userId === app.loginData.userId);
    const otherOwners = (userId: string): boolean =>
        sorted.some((member) => member.teamRole === TeamRole.Owner && member.userId !== userId);
    return (
        <div data-testid="users-list" id={`team-${props.id}`}>
            <UsersListHeader teamCount={props.users.length} buttonText={buttonText} buttonOnClick={buttonAction} />
            {sorted.map((value, index) => (
                <UsersListItemContainer
                    key={index}
                    team={{ id: props.id, name: props.name }}
                    loggedInAsOwner={loggedInUser?.teamRole === TeamRole.Owner}
                    isLastExistingOwner={!otherOwners(value.userId)}
                    loggedInUserAbilities={props.userAbilities}
                    loggedInUserId={app.loginData.userId}
                    teamMember={value}
                    requeryTeamDetails={props.requeryTeamDetails}
                />
            ))}
        </div>
    );
};
