import { Box, Typography } from '@mui/material';
import { RequestHelperResult } from 'src/client/api-client/endpoints/endpoint-helpers';
import { UserAbilities } from 'src/client/types/user-abilities';
import { EditableTextField } from '../EditableTextField';
import { Styles } from './styles';
import { SxProps } from '@mui/material/styles';
import { generateSxProps } from '../../helpers/generate-sx-props';

type HeaderProps = {
    title: string;
    subtitle: string;
    details: string;
    sx?: SxProps;
    onSave?: (...args: any[]) => Promise<RequestHelperResult<undefined>>;
    userAbilities: UserAbilities;
};

export const ItemDetailsHeader = (props: HeaderProps): JSX.Element => (
    <Box sx={generateSxProps(props.sx)}>
        <Box sx={Styles.title}>
            {props.onSave && props.userAbilities.includes('Edit') ? (
                <EditableTextField text={props.title} onSave={props.onSave} />
            ) : (
                <Typography data-testid="item-details-header-title" variant="h4" sx={Styles.header}>
                    {props.title}
                </Typography>
            )}
        </Box>
        <Box sx={Styles.subheader}>
            <Typography data-testid="item-details-header-subtitle" variant="subtitle2" color="initial">
                {props.subtitle}
            </Typography>
            <Typography data-testid="item-details-header-created" variant="body2">
                {props.details}
            </Typography>
        </Box>
    </Box>
);
