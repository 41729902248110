import { subMilliseconds, differenceInMilliseconds } from 'date-fns';

let timeTravelOffset = 0;
let frozenTime: Date | undefined;

function internalNow(): Date {
    if (frozenTime) {
        return frozenTime;
    }

    return subMilliseconds(new Date(), timeTravelOffset);
}

function now(): Date {
    return internalNow();
}

// Would really love to assert in test when using time travel, however this is
// only working in jest tests (yarn test) and not in cypress tests. For some reason, Cypress is
// clearing out environment variables while running tests, therefore we cannot
// actually assert we are in a test...
// It would be nice to get this figured out in the near future so that way we have
// this guard incase of misuse.
// Also, NODE_ENV cannot be manually set in a create react app, which is what this is.
// If we do want to assert if we are in a test or not, it would be to our advantage to
// move away from NODE_ENV and set our own variable: REACT_APP_ENV = "test"
function assertInTest(): void {
    if (process.env.NODE_ENV === 'production') {
        throw new Error(`Time travel is only allowed in tests! Found env: ${process.env.NODE_ENV || 'empty'}`);
    }
}

function timeTravel(time: Date | string): void {
    assertInTest();
    timeTravelOffset = differenceInMilliseconds(new Date(), typeof time === 'string' ? new Date(time) : time);
    frozenTime = undefined;
}

function restoreTime(): void {
    assertInTest();
    timeTravelOffset = 0;
    frozenTime = undefined;
}

function freezeTime(time: Date | string | undefined): void {
    assertInTest();
    if (time) {
        timeTravel(time);
    }

    frozenTime = internalNow();
}

function unfreezeTime(): void {
    assertInTest();
    frozenTime = undefined;
}

export const Clock = {
    now,

    Testing: {
        timeTravel,
        restoreTime,
        freezeTime,
        unfreezeTime,
    },
};
