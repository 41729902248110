import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CLIENT_ID, CLIENT_SECRET, EM_API_SUBSCRIPTION_KEY } from 'src/client/constants/tutorial';
import { useAsyncFn } from 'src/client/hooks/async-fn-hook';
import { SimulatedEmApiClient } from 'src/client/simulated-em-api/simulated-em-api-client';
import { Validator } from 'src/client/util/validator';
import { withDelay } from 'src/shared/lib/with-delay';
import { RequestDefinition, RequestResponse } from '../../RequestResponse';
import { SubmitButton } from '../../SubmitButton';
import { TutorialExpandable, TutorialStepProps } from '../TutorialExpandable';

import { Link } from 'react-router-dom';
import { MakeTextField } from 'src/client/components/HelperComponents/TutorialHelpers/MakeTextField';
import { Styles } from '../styles';

type AuthenticateTextFields = {
    emApiSubscriptionKey: string;
    clientId: string;
    clientSecret: string;
};

export const TutorialAuthenticate: React.FC<TutorialStepProps> = React.memo((props) => {
    const [tutorialAuthenticateTextFieldValues, setAuthenticationBodyValues] = useState<AuthenticateTextFields>({
        emApiSubscriptionKey: EM_API_SUBSCRIPTION_KEY,
        clientId: CLIENT_ID,
        clientSecret: CLIENT_SECRET,
    });

    const [inFlight, response, invokeAsyncFn] = useAsyncFn(
        withDelay(750, SimulatedEmApiClient.createServiceAccountAuthToken)
    );

    const requestBody = {
        clientId: tutorialAuthenticateTextFieldValues.clientId,
        clientSecret: tutorialAuthenticateTextFieldValues.clientSecret,
    };

    const submitRequest = (): void => invokeAsyncFn(requestBody);

    const request: RequestDefinition = {
        method: 'POST',
        endpointPath: '/serviceAccount/authToken',
        headers: [
            { name: 'Em-Api-Subscription-Key', value: tutorialAuthenticateTextFieldValues.emApiSubscriptionKey },
            { name: 'Accept', value: 'application/json' },
            { name: 'Content-Type', value: 'application/json; charset=utf-8' },
        ],
        body: requestBody,
    };

    const validationErrors = Validator.validateSchema(tutorialAuthenticateTextFieldValues, {
        emApiSubscriptionKey: {
            type: 'string',
            format: {
                pattern: `^${EM_API_SUBSCRIPTION_KEY}$`,
                message: `needs to match the API key assigned to your application. The tutorial application's key is ${EM_API_SUBSCRIPTION_KEY}.`,
            },
            presence: {
                allowEmpty: false,
            },
        },
        clientId: {
            type: 'string',
            format: {
                pattern: `^${CLIENT_ID}$`,
                message: `comes from your Application. For the tutorial, this is ${CLIENT_ID}.`,
            },
            length: { maximum: 255 },
            presence: {
                allowEmpty: false,
            },
        },
        clientSecret: {
            type: 'string',
            format: {
                pattern: `^${CLIENT_SECRET}$`,
                message: `can match one of 2 secrets issued to you at the creation of your application. For the tutorial, this is ${CLIENT_SECRET}`,
            },
            length: { maximum: 255 },
            presence: {
                allowEmpty: false,
            },
        },
    });

    return (
        <TutorialExpandable
            {...props}
            title="Acquire an Application Auth Token"
            description={
                <Typography variant="body1" sx={Styles.description}>
                    A number of higher level requests, such as creating and managing organizations, require an{' '}
                    <strong>Application Auth Token</strong>. This token can be acquired using the credentials provided
                    when the Application was first created on the{' '}
                    <Link to="/applications" target="_blank">
                        My Apps
                    </Link>{' '}
                    page.
                </Typography>
            }
            valueField={
                <Box component="div" sx={Styles.values}>
                    <form noValidate autoComplete="off">
                        <Box component="div" sx={Styles.entryFields}>
                            {MakeTextField(
                                tutorialAuthenticateTextFieldValues,
                                validationErrors,
                                'emApiSubscriptionKey',
                                'EM API Subscription Key',
                                setAuthenticationBodyValues,
                                {
                                    required: true,
                                }
                            )}

                            {MakeTextField(
                                tutorialAuthenticateTextFieldValues,
                                validationErrors,
                                'clientId',
                                'Client ID',
                                setAuthenticationBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                tutorialAuthenticateTextFieldValues,
                                validationErrors,
                                'clientSecret',
                                'Client Secret',
                                setAuthenticationBodyValues,
                                {
                                    required: true,
                                }
                            )}
                        </Box>
                    </form>
                    <SubmitButton
                        id="send-request-button"
                        onClick={submitRequest}
                        disabled={!!validationErrors}
                        spinning={inFlight}
                        buttonText="Send Request"
                        width={144}
                    />
                </Box>
            }
            requestResponse={<RequestResponse request={request} response={response} />}
        />
    );
});
