import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    scrollableContainer: {
        maxHeight: '200px',
        overflow: 'auto',
        marginTop: '12px',
    },
    divider: {
        marginTop: '18px',
        marginBottom: '24px',
    },
    errorTextField: {
        color: Colors.red[500],
    },
    errorListItem: { marginBottom: '6px' },
};
