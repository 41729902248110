import { Box, DialogContentText, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Styles } from './styles';
import EmailIcon from '@mui/icons-material/Email';
import ErrorIcon from '@mui/icons-material/Error';
import _ from 'lodash';

type AddMemberEditProps = {
    successfulEmailInvites: string[];
    failedEmailInvites: Array<{ email: string; errorMessage: string }>;
};

export const AddMemberSuccess: React.FC<AddMemberEditProps> = (props) => {
    const hasSuccessfulEmails = props.successfulEmailInvites.length > 0;
    const hasFailedEmails = props.failedEmailInvites.length > 0;

    const sortedSuccess = _.sortBy(props.successfulEmailInvites);
    const sortedFailure = _.sortBy(props.failedEmailInvites);

    return (
        <Box sx={Styles.successContainer}>
            {hasSuccessfulEmails && (
                <div data-testid="add-member-success-message">
                    <Typography variant="h5" sx={Styles.successHeader}>
                        Your team just got bigger!
                    </Typography>
                    <DialogContentText>
                        Team invites have been successfully sent to the following email addresses:
                    </DialogContentText>
                    <List dense sx={Styles.emailList}>
                        {sortedSuccess.map((email, index) => (
                            <ListItem key={index} data-testid={`email-item-${index}`}>
                                <ListItemIcon>
                                    <EmailIcon />
                                </ListItemIcon>
                                <ListItemText primary={email} />
                            </ListItem>
                        ))}
                    </List>
                </div>
            )}
            {hasFailedEmails && (
                <div data-testid="add-member-failure-message">
                    {hasSuccessfulEmails && <Divider sx={Styles.divider} />}
                    <DialogContentText variant="subtitle2" sx={Styles.errorTextField}>
                        {props.failedEmailInvites.length} Email{props.failedEmailInvites.length !== 1 && 's'} Failed to
                        Send:
                    </DialogContentText>
                    <Box sx={Styles.errorContainer}>
                        <List dense>
                            {sortedFailure.map((failure, index) => (
                                <ListItem
                                    key={index}
                                    data-testid={`failure-item-${index}`}
                                    sx={{ ...Styles.errorTextField, ...Styles.errorListItem }}
                                >
                                    <ListItemIcon>
                                        <ErrorIcon sx={Styles.errorTextField} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={failure.email}
                                        primaryTypographyProps={{ variant: 'subtitle2' }}
                                        secondary={failure.errorMessage}
                                        secondaryTypographyProps={{ sx: Styles.errorTextField }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </div>
            )}
        </Box>
    );
};
