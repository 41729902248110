import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { PageWrapper } from 'src/client/components/PageWrapper';
import { HomeContent } from '../components/HomeContent';
import { Styles } from './styles';
import { useApp } from '../contexts/AppContextProvider';

export const HomePage = (): JSX.Element => {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const navigate = useNavigate();
    const app = useApp();

    const navigateTo = (route: string): void => {
        navigate(route);
    };
    return (
        <PageWrapper title="Home">
            <Box sx={Styles.body} data-testid="home-page">
                <Box sx={Styles.header}>
                    <Typography sx={Styles.title1} variant={xs ? 'h4' : 'h2'} paragraph data-testid="welcome-message">
                        Innovative Power Management Starts Here
                    </Typography>

                    <Typography sx={Styles.subtitle} variant="body1">
                        Manage your custom integrations in one place
                    </Typography>
                    <div>
                        <Button
                            sx={Styles.tutorialButton}
                            variant="contained"
                            color="primary"
                            id={'home-tutorial-button'}
                            onClick={(): void => navigateTo(`/tutorial`)}
                        >
                            Tutorial
                        </Button>
                        {!app.isAuthenticated ? (
                            <Button
                                sx={Styles.tutorialButton}
                                variant="outlined"
                                color="primary"
                                id={'home-login-button'}
                                onClick={(): void => navigateTo(`/login`)}
                            >
                                Login
                            </Button>
                        ) : (
                            <Button
                                sx={Styles.tutorialButton}
                                variant="outlined"
                                color="primary"
                                id={'home-apps-button'}
                                onClick={(): void => navigateTo(`/applications`)}
                            >
                                My Apps
                            </Button>
                        )}
                    </div>
                </Box>
                <HomeContent />
            </Box>
        </PageWrapper>
    );
};
