import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';
import * as Types from 'server/api/types';

export const updateTeamUser = (
    teamId: string,
    userId: string,
    role: 'Member' | 'Owner'
): Promise<RequestHelperResult<undefined>> => {
    const requestBody: Types.TeamsUserUpdateRequestBody = {
        role: role,
    };

    return axiosRequestHelper('PUT', `/api/teams/${teamId}/users/${userId}`, {
        data: requestBody,
    });
};
