import { add } from 'date-fns';
import { Clock } from 'src/shared/lib/clock';
import { StatusMessage } from '../StatusMessage';
import { Box } from '@mui/material';
import { Styles } from './styles';

type EntitlementWarningMessageProps = {
    deviceLimit?: number;
    expirationDate?: string;
    claimedDevices: number;
};

const isExpirationDateWithinNextMonth = (expirationDate: string): boolean => {
    const futureDate = new Date(add(Clock.now(), { days: 30 }));
    const expiration = new Date(expirationDate);
    return futureDate >= expiration;
};

const statusUpdateMessage =
    'the status will automatically update within 24 hours, or you can click the "Refresh" button to refresh the status right away.';

export const EntitlementWarningMessage: React.FC<EntitlementWarningMessageProps> = ({
    expirationDate,
    deviceLimit,
    claimedDevices,
}: EntitlementWarningMessageProps) => {
    const getMessage = (): { message: string; level: 'error' | 'warning' | 'info' } | null => {
        if (expirationDate) {
            if (Clock.now() > new Date(expirationDate)) {
                return {
                    message: `Looks like your subscription is expired.
                        Contact your Eaton sales representative to renew your subscription.
                        If you have already renewed it, ${statusUpdateMessage}`,
                    level: 'error',
                };
            }
            if (isExpirationDateWithinNextMonth(expirationDate)) {
                return {
                    message: `Looks like your subscription is expiring soon.
                        If you have already renewed it, ${statusUpdateMessage}`,
                    level: 'warning',
                };
            }
        }
        if (deviceLimit !== undefined) {
            if (claimedDevices >= deviceLimit) {
                return {
                    message: `Looks like you have claimed the maximum number of devices allowed by your subscription.
                        Contact your Eaton sales representative to increase your device limit if you need to claim more devices.
                        If you have already upgraded your device limit, ${statusUpdateMessage}`,
                    level: 'error',
                };
            }
        }
        return null;
    };

    const messageObject = getMessage();
    if (messageObject) {
        return (
            <Box data-testid="warning-message" sx={Styles.warningBox}>
                <StatusMessage message={messageObject.message} level={messageObject.level} />
            </Box>
        );
    }
    return null;
};
