import _ from 'lodash';
import { ChangeEvent } from 'react';
import { TutorialTextField } from 'src/client/components/Tutorial/TutorialTextField';

const getValidationError = (
    validationErrors: Record<string, string[] | undefined> | undefined,
    field: string
): string | undefined => {
    if (validationErrors && validationErrors[field]) {
        const validationFieldValue = validationErrors[field];
        if (validationFieldValue && validationFieldValue.length > 0) {
            return validationFieldValue[0];
        }
    }
    return undefined;
};

export const MakeTextField = <T extends Record<string, string>>(
    textFieldValues: T,
    validationErrors: Record<string, string[] | undefined> | undefined,
    field: keyof T,
    labelText: string,
    setterHook: (value: T) => void,
    opts?: { required?: boolean; type?: string }
): JSX.Element => (
    <TutorialTextField
        //compiler couldnt recognize that field is going to be a string
        //we are confident that it will be a string with current use cases
        id={`${_.kebabCase(field as string)}-text-field`}
        label={`${labelText} ${opts?.required ? '*' : ''}`}
        errorMessage={getValidationError(validationErrors, field as string)}
        value={textFieldValues[field]}
        type={opts?.type}
        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            setterHook({
                ...textFieldValues,
                [field]: event.target.value,
            });
        }}
    />
);
