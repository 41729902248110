import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    listItem: {
        maxHeight: '50px',
        '& .MuiListItemText-root': { display: 'flex', flexDirection: 'row' },
        '& .MuiTypography-root.MuiTypography-body1': { fontWeight: 'normal', fontSize: '1rem' },
        '& .MuiTypography-root.MuiTypography-subtitle2': { fontWeight: 'bold', fontSize: '1rem', marginLeft: '8px' },
    },
    rightComponent: { display: 'flex', flexDirection: 'row', alignItems: 'center', textTransform: 'lowercase' },
    settingsIcon: { color: Colors.gray[300] },
    leaveButton: { marginRight: '54px', textTransform: 'uppercase' },
    userRole: { marginRight: '54px' },
};
