import { Box } from '@mui/material';
import { MakeTextField } from 'src/client/components/HelperComponents/TutorialHelpers/MakeTextField';
import {
    ADDRESS_LOCATION_ID,
    EM_API_SUBSCRIPTION_KEY,
    EQUIPMENT_LOCATION_ID,
    INSTALLER_ROLE_ID,
    ORGANIZATION_AUTH_TOKEN,
    ORGANIZATION_ID,
} from 'src/client/constants/tutorial';
import { PropertySchema, Validator } from 'src/client/util/validator';
import { BasicDropdown } from '../../BasicDropdown';
import { SubmitButton } from '../../SubmitButton';
import { Styles } from '../styles';
import { IdSelections, InviteInstallerFormFields, InviteType } from './types';

type Props = {
    fieldValues: InviteInstallerFormFields;
    setFieldValues: (values: InviteInstallerFormFields) => void;
    inviteType: InviteType;
    setInviteType: (selected: InviteType) => void;
    selectedId: IdSelections;
    setSelectedId: (selected: IdSelections) => void;
    submitRequest: () => void;
    inFlight: boolean;
};

const baseValidations: { [key in keyof InviteInstallerFormFields]?: PropertySchema } = {
    emApiSubscriptionKey: {
        type: 'string',
        format: {
            pattern: `^${EM_API_SUBSCRIPTION_KEY}$`,
            message: `key should be the same as the subscription key from the rest of the tutorial: ${EM_API_SUBSCRIPTION_KEY}.`,
        },
        presence: {
            allowEmpty: false,
        },
    },
    organizationAuthToken: {
        type: 'string',
        format: {
            pattern: `^${ORGANIZATION_AUTH_TOKEN}$`,
            message: `should be the token that was given at step 4: ${ORGANIZATION_AUTH_TOKEN}.`,
        },
        presence: {
            allowEmpty: false,
        },
    },
    roleId: {
        type: 'string',
        format: {
            pattern: `^${INSTALLER_ROLE_ID}$`,
            message: `is the id of a user role. In this case it is the role id that will be associated with an installer, which for the tutorial is: ${INSTALLER_ROLE_ID}`,
        },
        presence: {
            allowEmpty: false,
        },
    },
};

const siteIdValidations = (
    idSelection: IdSelections
): { [key in keyof InviteInstallerFormFields]?: PropertySchema } => {
    switch (idSelection) {
        case 'locationIdAddress':
            return {
                locationIdAddress: {
                    type: 'string',
                    format: {
                        pattern: `^${ADDRESS_LOCATION_ID}$`,
                        message: `should match the id of the location that was created earlier in the tutorial. This will inform the installer where the device is located. For the tutorial the value is ${ADDRESS_LOCATION_ID}`,
                    },
                    presence: {
                        allowEmpty: false,
                    },
                },
            };
        case 'locationIdEquipment':
            return {
                locationIdEquipment: {
                    type: 'string',
                    format: {
                        pattern: `^${EQUIPMENT_LOCATION_ID}$`,
                        message: `should match the id of the location that was created earlier in the tutorial. This will inform the installer where the device is located. For the tutorial the value is ${EQUIPMENT_LOCATION_ID}`,
                    },
                    presence: {
                        allowEmpty: false,
                    },
                },
            };
        default:
            return {
                organizationId: {
                    type: 'string',
                    format: {
                        pattern: `^${ORGANIZATION_ID}$`,
                        message: `should match the id of the organization that was created earlier in the tutorial. This will inform the installer where the device is located. For the tutorial the value is ${ORGANIZATION_ID}`,
                    },
                    presence: {
                        allowEmpty: false,
                    },
                },
            };
    }
};

const inviteTypeValidations = (
    inviteType: InviteType
): { [key in keyof InviteInstallerFormFields]?: PropertySchema } => {
    if (inviteType === 'Email') {
        return {
            email: { type: 'string', presence: { allowEmpty: false } },
        };
    }

    return {
        userLimit: {
            type: 'string',
            format: {
                pattern: /^[0-9]+$|^$/, //must be all numerc or empty string
                message: `must be a number greater than 0`,
            },
        },
        emailDomain: {
            type: 'string',
            format: {
                pattern: /^((?!\.)(?!.*\.$)(?!.*\.\.)[a-zA-Z0-9-.]+)$|^$/,
                message: 'must be a valid email domain (example.com)',
            },
        },
        expirationDate: {
            type: 'string',
            format: {
                pattern: /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z$|^$/,
                message: 'must be a valid date string (YYYY-MM-DDThh:mm:ssZ)',
            },
        },
    };
};

export const InviteInstallerForm: React.FC<Props> = (props) => {
    const setSelectedId = (value: string): void =>
        value === 'Location ID - Address'
            ? props.setSelectedId('locationIdAddress')
            : value === 'Location ID - Equipment'
            ? props.setSelectedId('locationIdEquipment')
            : props.setSelectedId('organizationId');

    const validationErrors = Validator.validateSchema(props.fieldValues, {
        ...baseValidations,
        ...siteIdValidations(props.selectedId),
        ...inviteTypeValidations(props.inviteType),
    });

    return (
        <Box component="div" sx={Styles.values}>
            <form noValidate autoComplete="off">
                <Box component="div" sx={Styles.entryFields}>
                    <BasicDropdown
                        data-testid="invite-type-dropdown"
                        sx={Styles.formField}
                        value={props.inviteType}
                        label="Invitation Type"
                        onChange={(value): void => props.setInviteType(value === 'Email' ? 'Email' : 'Invite Code')}
                        items={['Email', 'Invite Code']}
                    />

                    {MakeTextField(
                        props.fieldValues,
                        validationErrors,
                        'emApiSubscriptionKey',
                        'EM API Subscription Key',
                        props.setFieldValues,
                        {
                            required: true,
                        }
                    )}
                    {MakeTextField(
                        props.fieldValues,
                        validationErrors,
                        'organizationAuthToken',
                        'Organization Auth Token',
                        props.setFieldValues,
                        {
                            required: true,
                        }
                    )}
                    {props.inviteType === 'Email' &&
                        MakeTextField(props.fieldValues, validationErrors, 'email', 'Email', props.setFieldValues, {
                            required: true,
                        })}
                    {MakeTextField(props.fieldValues, validationErrors, 'roleId', 'Role ID', props.setFieldValues, {
                        required: true,
                    })}

                    <BasicDropdown
                        data-testid="request-dropdown"
                        sx={Styles.formField}
                        value={
                            props.selectedId === 'locationIdAddress'
                                ? 'Location ID - Address'
                                : props.selectedId === 'locationIdEquipment'
                                ? 'Location ID - Equipment'
                                : 'Organization ID'
                        }
                        label="Installer Permission Level"
                        onChange={setSelectedId}
                        items={['Location ID - Address', 'Location ID - Equipment', 'Organization ID']}
                    />

                    {MakeTextField(
                        props.fieldValues,
                        validationErrors,
                        props.selectedId,
                        props.selectedId === 'locationIdAddress'
                            ? 'Location ID'
                            : props.selectedId === 'locationIdEquipment'
                            ? 'Equipment ID'
                            : 'Organization ID',
                        props.setFieldValues,
                        {
                            required: true,
                        }
                    )}

                    {props.inviteType === 'Invite Code' && (
                        <>
                            {MakeTextField(
                                props.fieldValues,
                                validationErrors,
                                'userLimit',
                                'User Limit',
                                props.setFieldValues,
                                {
                                    type: 'number',
                                }
                            )}
                            {MakeTextField(
                                props.fieldValues,
                                validationErrors,
                                'emailDomain',
                                'Email Domain',
                                props.setFieldValues
                            )}
                            {MakeTextField(
                                props.fieldValues,
                                validationErrors,
                                'expirationDate',
                                'Expiration Date',
                                props.setFieldValues
                            )}
                        </>
                    )}
                </Box>
            </form>
            <SubmitButton
                id="send-request-button"
                onClick={props.submitRequest}
                disabled={!!validationErrors}
                spinning={props.inFlight}
                buttonText="Send Request"
                width={144}
            />
        </Box>
    );
};
