export const CONTENT_TYPE = 'application/json; charset=utf-8';
export const EM_API_SUBSCRIPTION_KEY = 'the-fake-api-key';
export const SERVICE_ACCOUNT_AUTH_TOKEN = 'eyJ0e-application-auth-token';
export const EM_API_BASE_URL = 'https://api.em.eaton.com/api/v1';
export const ORGANIZATION_ID = 'organization-uuid';
export const CLIENT_ID = 'service-account-client-uuid';
export const CLIENT_SECRET = 'organization-first-secret-value';
export const ORGANIZATION_AUTH_TOKEN = 'eyJ0e-organization-auth-token';
export const ADDRESS_LOCATION_ID = 'address-location-uuid';
export const EQUIPMENT_LOCATION_ID = 'equipment-location-uuid';
export const DEVICE_ID = 'device-uuid';
export const INSTALLER_ROLE_ID = 'installer-role-uuid';
export const USER_ROLE_ID = 'user-role-uuid';
export const INVITE_CODE = 'invite-code';
