export const Styles = {
    stepNumber: {
        borderRadius: '50%',
        minWidth: '40px',
        minHeight: '40px',
        marginRight: '10px',
    },
    nextButton: {
        margin: '24px',
        alignSelf: 'flex-end',
        justifySelf: 'flex-end',
        textTransform: 'uppercase',
    },
    prevButton: {
        margin: '24px',
        textTransform: 'uppercase',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
    },
    rowItems: { display: 'flex', flexDirection: 'column' },
    root: { margin: '0px', padding: '0px', boxShadow: '0px' },
    requestResponse: { minWidth: '50%', maxWidth: '50%' },
    footer: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
    overviewTitle: {
        marginLeft: '18px',
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'row',
    },
};
