import { PageWrapper } from 'src/client/components/PageWrapper';
import { ScrollableNav } from 'src/client/components/ScrollableNav';
import source from 'src/client/markdown-content/markdown-pages/UnderstandingLocalCommunication.md';

const UNDERSTANDING_LOCAL_COMMUNICATION = [
    {
        id: 'localCommunicationsOverview',
        title: 'Local Communications Overview',
    },
    {
        id: 'securityOnTheLocalNetwork',
        title: 'Security on the Local Network',
    },
    {
        id: 'keyTypes',
        title: 'Key Types',
    },
    {
        id: 'unicastCommunications',
        title: 'Unicast Communications',
    },
    {
        id: 'broadcastCommunications',
        title: 'Broadcast Communications',
    },
    {
        id: 'keyGenerationAndAssignment',
        title: 'Key Generation and Assignment',
    },
    {
        id: 'keyLifetimeAndRotation',
        title: 'Key Lifetime and Rotation',
    },
    {
        id: 'dataAvailability',
        title: 'Data Availability',
    },
    {
        id: 'developingASmartBreakerCoordinator',
        title: 'Developing a Smart Breaker Coordinator',
    },
    {
        id: 'securityConsiderations',
        title: 'Security Considerations',
    },
];

export const UnderstandingLocalCommunicationPage: React.FC = () => (
    <PageWrapper title="Understanding Local Communications">
        <ScrollableNav
            sectionList={UNDERSTANDING_LOCAL_COMMUNICATION}
            source={source}
            pageRoute="/advancedTopics/localCommunication"
        />
    </PageWrapper>
);
