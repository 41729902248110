import { getRuntimeConfig } from 'src/shared/lib/runtime-config';
import { CodeBlock } from '../CodeBlock';
import { Styles } from './styles';
import { Box } from '@mui/material';

type ResponseDefinition = { statusLine: string; headers: Array<{ name: string; value: string }>; body?: any };
export type RequestDefinition = {
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    endpointPath: string;
    headers: Array<{ name: string; value: string }>;
    body?: any;
};

type RequestResponseProps = {
    request: RequestDefinition;
    response?: ResponseDefinition;
};

export const RequestResponse: React.FC<RequestResponseProps> = (props) => {
    const emApiBaseUrl = `${getRuntimeConfig(window).emApiBaseUrl}/api/v1`;
    return (
        <Box sx={Styles.container}>
            <CodeBlock
                id="request-block"
                title="Request"
                line={`${props.request.method} ${emApiBaseUrl}${props.request.endpointPath}`}
                headers={props.request.headers}
                body={props.request.body}
                buttonArgs={{
                    text: 'Copy cURL',
                    onClick: (): void => {
                        let curlString = 'curl --location';

                        props.request.headers.forEach((header) => {
                            curlString = curlString.concat(` --header `);
                            curlString = curlString.concat(`'${header.name}:${header.value}' `);
                        });

                        if (
                            props.request.body !== 'undefined' &&
                            (props.request.method === 'POST' ||
                                props.request.method === 'PUT' ||
                                props.request.method === 'PATCH')
                        ) {
                            curlString = curlString.concat(` --data-raw '${JSON.stringify(props.request.body)}'`);
                        }

                        curlString = curlString.concat(
                            ` --request ${props.request.method} '${emApiBaseUrl}${props.request.endpointPath}' `
                        );

                        void navigator.clipboard.writeText(curlString);
                    },
                }}
            />

            {props.response && (
                <CodeBlock
                    id="response-block"
                    title="Response"
                    line={props.response.statusLine}
                    headers={props.response.headers}
                    body={props.response.body}
                />
            )}
        </Box>
    );
};
