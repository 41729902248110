import { Box, Button } from '@mui/material';
import { Styles } from './styles';

type ListPageNavProps = {
    buttonId?: string;
    buttonText: string;
    onClick: VoidFunction;
    startIcon?: React.ReactNode;
};

export const ListPageNavBar: React.FC<ListPageNavProps> = (props) => (
    <Box sx={Styles.container}>
        <Button
            sx={Styles.createButton}
            id={`${props.buttonId || 'nav-bar'}-button`}
            variant="contained"
            color="primary"
            startIcon={props.startIcon}
            onClick={props.onClick}
        >
            {props.buttonText}
        </Button>
    </Box>
);
