import * as Types from 'server/api/types';
import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';

type CreateUserArgs = {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    companyName: string;
    jobTitle: string;
    department: string;
    optedOutOfEmailNotifications: boolean;
};

type User = {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
};

export const createUser = (validationCode: string, userArgs: CreateUserArgs): Promise<RequestHelperResult<User>> => {
    const requestBody: Types.CreateUserRequestBody = {
        email: userArgs.email,
        password: userArgs.password,
        firstName: userArgs.firstName,
        lastName: userArgs.lastName,
        phoneNumber: userArgs.phoneNumber,
        companyName: userArgs.companyName,
        jobTitle: userArgs.jobTitle,
        department: userArgs.department,
        optedOutOfEmailNotifications: userArgs.optedOutOfEmailNotifications,
    };

    const queryParameters: Types.CreateUserQueryParams = {
        code: validationCode,
    };

    return axiosRequestHelper<Types.CreateUserResponseBody>('POST', '/api/users', {
        data: requestBody,
        params: queryParameters,
    });
};
