import { Box, Button, CircularProgress, IconButton, TextField, Typography } from '@mui/material';
import { Create } from '@mui/icons-material';
import _ from 'lodash';
import { useState } from 'react';
import { RequestHelperResult } from 'src/client/api-client/endpoints/endpoint-helpers';
import { SchemaValidationResult, Validator } from 'src/client/util/validator';
import { Styles } from './styles';

type HeaderProps = {
    text: string;
    onSave: (updatedText: string) => Promise<RequestHelperResult<undefined>>;
};

type EditableText = {
    text: string;
};

export const EditableTextField = (props: HeaderProps): JSX.Element => {
    const [inEditMode, setInEditMode] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<SchemaValidationResult<EditableText> | undefined>(
        undefined
    );
    const [savedText, setSavedText] = useState<EditableText>({ text: props.text });
    const [textObject, setTextObject] = useState<EditableText>({ text: props.text });
    const [saveInFlight, setSaveInFlight] = useState<boolean>(false);
    const [saveError, setSaveError] = useState<string | undefined>(undefined);

    const clearValidationErrors = (): void => {
        setValidationErrors(undefined);
        setSaveError(undefined);
    };

    const saveText = async (): Promise<void> => {
        const validation = Validator.validateSchema(textObject, {
            text: {
                type: 'string',
                length: { maximum: 100 },
                presence: {
                    allowEmpty: false,
                },
            },
        });

        setValidationErrors(validation);

        if (!validation) {
            setSaveInFlight(true);
            const result = await props.onSave(textObject.text);
            if (result.success) {
                setSavedText(textObject);
                setInEditMode(false);
                setSaveError(undefined);
            } else {
                setSaveError(result.error || 'Unknown error occured, please try again.');
            }
            setSaveInFlight(false);
        }
    };

    const cancelEditText = (): void => {
        setTextObject(savedText);
        setInEditMode(false);
    };

    const canSave = !_.isEqual(savedText, textObject) && !validationErrors;

    return (
        <Box sx={Styles.title}>
            {inEditMode ? (
                <Box sx={Styles.editableState}>
                    <TextField
                        data-testid="editable-text-content"
                        inputProps={{
                            id: 'edit-text-content',
                            'data-testid': 'edit-text-content',
                        }}
                        variant="filled"
                        color="primary"
                        value={textObject.text}
                        onChange={(event): void => {
                            if (validationErrors) {
                                clearValidationErrors();
                            }
                            setTextObject({ text: event.target.value });
                        }}
                        helperText={validationErrors?.text ?? undefined}
                        error={!!validationErrors?.text}
                        sx={Styles.root}
                        disabled={saveInFlight}
                    />
                    <Button
                        id="save-editable-text-button"
                        data-testid="save-editable-text-button"
                        variant="contained"
                        color="primary"
                        onClick={(): void => void saveText()}
                        disabled={!canSave || saveInFlight}
                        sx={Styles.button}
                    >
                        {saveInFlight ? (
                            <CircularProgress sx={Styles.circularProgress} size={20} variant={'indeterminate'} />
                        ) : (
                            'Save'
                        )}
                    </Button>
                    <Button
                        id="cancel-editable-text-button"
                        data-testid="cancel-editable-text-button"
                        variant="outlined"
                        color="primary"
                        onClick={cancelEditText}
                        sx={Styles.button}
                        disabled={saveInFlight}
                    >
                        Cancel
                    </Button>
                    {saveError && <Box sx={Styles.errorTextField}>{saveError}</Box>}
                </Box>
            ) : (
                <>
                    <Typography data-testid="saved-text-content" variant="h4" sx={Styles.header}>
                        {textObject.text}
                    </Typography>
                    <IconButton
                        id="name-edit"
                        data-testid="name-edit"
                        color="primary"
                        aria-label="edit"
                        component="span"
                        onClick={(): void => setInEditMode(true)}
                        sx={Styles.icon}
                    >
                        <Create />
                    </IconButton>
                </>
            )}
        </Box>
    );
};
