import * as Colors from '@brightlayer-ui/colors';
import { Theme } from '@mui/material';

export const Styles = {
    image: {
        display: 'flex',
        maxWidth: '100%',
        marginTop: '8px',
        marginBottom: '8px',
    },
    codeBlock: {
        backgroundColor: Colors.gray[500],
        margin: '30px',
        padding: '10px',
        color: Colors.white[50],
    },
    table: {
        border: Colors.gray[500],
    },
    blockquote: {
        padding: '0 1em',
        color: '#6a737d',
        borderLeft: '0.25em solid #dfe2e5',
    },
    paragraph: {
        marginTop: '8px',
    },
    anchor: {
        position: 'relative',
        top: '-90px',
        left: '0',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '24px',
    },
    contentBlock: (theme: Theme) => ({
        marginTop: '20px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '50%',
        [theme.breakpoints.down('sm')]: { maxWidth: '90%' },
    }),
    subheader: {
        marginTop: '16px',
    },
    link: {
        margin: '4px',
    },
    divider: {
        width: '100%',
        margin: '24px 0px',
        color: '#0000001f',
        borderStyle: 'solid',
        borderBottomStyle: 'none',
        height: '1px',
    },
    code: {
        fontSize: '0.813rem',
        padding: '0 5px',
        inlineSize: '80%',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
    },
    href: {
        textDecoration: 'none',
        color: '#32329f',
    },
    tableRow: (theme: Theme) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: 'white',
        },
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover,
        },
    }),
};
