import { PageWrapper } from 'src/client/components/PageWrapper';
import { ScrollableNav } from 'src/client/components/ScrollableNav';
import source from 'src/client/markdown-content/markdown-pages/SystemHierarchy.md';

const SYSTEM_HIERARCHY_SECTIONS = [
    {
        id: 'systemHierarchy',
        title: 'System Hierarchy Overview',
    },
    {
        id: 'createAnApplication',
        title: 'Create an Application',
    },
    {
        id: 'createOrganizations',
        title: 'Create Organizations',
    },
    {
        id: 'createLocations',
        title: 'Create Locations',
    },
    {
        id: 'manageInstallers',
        title: 'Manage Installers',
    },
    {
        id: 'manageDevices',
        title: 'Manage Devices',
    },
];

export const SystemHierarchyPage: React.FC = () => (
    <PageWrapper title="System Hierarchy">
        <ScrollableNav
            sectionList={SYSTEM_HIERARCHY_SECTIONS}
            source={source}
            pageRoute="/advancedTopics/systemHierarchy"
        />
    </PageWrapper>
);
