import { useCallback, useState } from 'react';
import { PromiseType } from 'src/shared/types/general';

export const useAsyncFn = <Fn extends (...args: any[]) => Promise<any>, Result extends PromiseType<ReturnType<Fn>>>(
    fn: Fn
): [boolean, Result | undefined, (...args: Parameters<Fn>) => void] => {
    const [result, setResult] = useState<Result | undefined>(undefined);
    const [inFlight, setInFlight] = useState(false);

    const requestFn = useCallback(
        (...args: Parameters<Fn>): void => {
            const invokeIt = async (): Promise<void> => {
                setInFlight(true);
                setResult(undefined);
                try {
                    const val = await fn(...args);
                    setResult(val);
                } finally {
                    setInFlight(false);
                }
            };

            void invokeIt();
        },
        [fn, setResult, setInFlight]
    );

    return [inFlight, result, requestFn];
};
