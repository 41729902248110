import React, { useState } from 'react';
import {
    ADDRESS_LOCATION_ID,
    EM_API_SUBSCRIPTION_KEY,
    EQUIPMENT_LOCATION_ID,
    INSTALLER_ROLE_ID,
    ORGANIZATION_AUTH_TOKEN,
    ORGANIZATION_ID,
} from 'src/client/constants/tutorial';
import { useAsyncFn } from 'src/client/hooks/async-fn-hook';
import { SimulatedEmApiClient } from 'src/client/simulated-em-api/simulated-em-api-client';
import { withDelay } from 'src/shared/lib/with-delay';
import { RequestDefinition, RequestResponse } from '../../RequestResponse';
import { TutorialExpandable, TutorialStepProps } from '../TutorialExpandable';
import { InviteInstallerDescription } from './InviteInstallerDescription';
import { InviteInstallerForm } from './InviteInstallerForm';
import { IdSelections, InviteInstallerFormFields, InviteType } from './types';

export const TutorialInviteInstaller: React.FC<TutorialStepProps> = React.memo((props) => {
    const [inviteInstallerFieldValues, setinviteInstallerFieldValues] = useState<InviteInstallerFormFields>({
        emApiSubscriptionKey: EM_API_SUBSCRIPTION_KEY,
        organizationAuthToken: ORGANIZATION_AUTH_TOKEN,
        email: 'The email of the Installer to be invited',
        roleId: INSTALLER_ROLE_ID,
        locationIdAddress: ADDRESS_LOCATION_ID,
        locationIdEquipment: EQUIPMENT_LOCATION_ID,
        organizationId: ORGANIZATION_ID,
    });

    const [emailInFlight, emailResponse, invokeEmailFn] = useAsyncFn(
        withDelay(750, SimulatedEmApiClient.createUserRole)
    );

    const [inviteCodeInFlight, inviteCodeResponse, invokeInviteCodeFn] = useAsyncFn(
        withDelay(750, SimulatedEmApiClient.createInviteCode)
    );

    const [inviteType, setInviteType] = useState<InviteType>('Email');
    const [selectedID, setSelectedID] = useState<IdSelections>('locationIdAddress');

    const getRequestBodyID = (): { locationId: string } | { organizationId: string } =>
        selectedID === 'locationIdAddress'
            ? { locationId: inviteInstallerFieldValues.locationIdAddress }
            : selectedID === 'locationIdEquipment'
            ? { locationId: inviteInstallerFieldValues.locationIdEquipment }
            : { organizationId: inviteInstallerFieldValues.organizationId };

    const emailRequest = {
        email: inviteInstallerFieldValues.email,
        roleId: inviteInstallerFieldValues.roleId,
        ...getRequestBodyID(),
    };

    const inviteCodeRequest = {
        roleId: inviteInstallerFieldValues.roleId,
        ...getRequestBodyID(),
        restrictions:
            inviteInstallerFieldValues.userLimit ||
            inviteInstallerFieldValues.emailDomain ||
            inviteInstallerFieldValues.expirationDate
                ? {
                      userLimit: inviteInstallerFieldValues.userLimit
                          ? parseInt(inviteInstallerFieldValues.userLimit)
                          : undefined,
                      emailDomain: inviteInstallerFieldValues.emailDomain || undefined,
                      expirationDate: inviteInstallerFieldValues.expirationDate || undefined,
                  }
                : undefined,
    };

    const submitRequest = (): void => {
        if (inviteType === 'Email') {
            invokeEmailFn(emailRequest);
        } else {
            invokeInviteCodeFn(inviteCodeRequest);
        }
    };

    const request: RequestDefinition = {
        method: 'POST',
        endpointPath: inviteType === 'Email' ? '/userRoles' : '/inviteCodes',
        headers: [
            { name: 'Em-Api-Subscription-Key', value: inviteInstallerFieldValues.emApiSubscriptionKey },
            { name: 'Authorization', value: `Bearer ${inviteInstallerFieldValues.organizationAuthToken}` },
            { name: 'Accept', value: 'application/json' },
            { name: 'Content-Type', value: 'application/json; charset=utf-8' },
        ],
        body: inviteType === 'Email' ? emailRequest : inviteCodeRequest,
    };

    return (
        <TutorialExpandable
            {...props}
            title="Invite an Installer"
            description={<InviteInstallerDescription />}
            valueField={
                <InviteInstallerForm
                    fieldValues={inviteInstallerFieldValues}
                    setFieldValues={setinviteInstallerFieldValues}
                    inviteType={inviteType}
                    setInviteType={setInviteType}
                    selectedId={selectedID}
                    setSelectedId={setSelectedID}
                    inFlight={inviteType === 'Email' ? emailInFlight : inviteCodeInFlight}
                    submitRequest={submitRequest}
                />
            }
            requestResponse={
                <div>
                    <RequestResponse
                        request={request}
                        response={inviteType === 'Email' ? emailResponse : inviteCodeResponse}
                    />
                </div>
            }
        />
    );
});
