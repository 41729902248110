import axios, { AxiosRequestConfig, Method } from 'axios';
import { isError } from 'lodash';
import { Result } from 'src/client/types/result';

export type RequestHelperResult<T> = Result<T, { statusCode?: number }>;

// eslint-disable-next-line @typescript-eslint/ban-types
type ApiResponseObject = { data: any } | {};

// The generic being passed into here should be the schema type. The expected response is everything
// contained inside 'data: ' of that schema type.
// e.g.
// PASSED IN SCHEMA
// // EulaResponse {
// //    data: string;
// // }
// EXPECTED RESPONSE
// // string

// eslint-disable-next-line @typescript-eslint/ban-types
type EmptyObject = {};

export const axiosRequestHelper = async <
    T extends ApiResponseObject = EmptyObject,
    R = T extends { data: any } ? T['data'] : undefined
>(
    method: Method,
    url: string,
    requestConfig?: Omit<AxiosRequestConfig, 'method' | 'url' | 'baseURL'>
): Promise<RequestHelperResult<R>> => {
    try {
        const result = await axios.request<T>({
            method,
            url,
            data: requestConfig?.data,
            params: requestConfig?.params,
            headers: requestConfig?.headers,
        });

        if (result.status === 204) {
            return {
                success: true,
                data: undefined as unknown as R,
            };
        }

        if (result.data !== undefined && 'data' in result.data) {
            return {
                success: true,
                data: result.data.data,
            };
        }

        return {
            success: false,
            error: "Missing 'data' in response",
            errorDetail: {
                statusCode: result.status,
            },
        };
    } catch (err) {
        if (axios.isAxiosError(err)) {
            return {
                success: false,
                error: err.response?.data?.error?.message,
                errorDetail: {
                    statusCode: err.response?.status,
                },
            };
        }
        return {
            success: false,
            error: isError(err) ? err.message : undefined,
            errorDetail: {},
        };
    }
};
