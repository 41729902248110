import { Box, Button, Typography } from '@mui/material';
import Group from '@mui/icons-material/Group';
import _ from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectableDropdownList } from '../SelectableDropdownList';
import { TeamInviteResponseModal } from '../TeamInviteResponseModal';
import { LeaveTeamConfirmationModal } from '../LeaveTeamConfirmationModal';
import { Styles } from './styles';

type Team = {
    id: string;
    name: string;
    requestingUserRole: string;
    createdBy: string;
    createdOn: string;
    userCount: number;
    pendingInviteId?: string;
};

export const TeamsOverview: React.FC<{
    teamsData: Team[];
    requeryTeamsData: () => void;
}> = (props) => {
    const navigate = useNavigate();
    const [leaveTeamInfo, setLeaveTeamInfo] = useState<Team | undefined>();
    const [teamInviteInfo, setTeamInviteInfo] = useState<{ teamId: string; inviteId: string } | undefined>();

    const navigateTo = (route: string): void => {
        navigate(route);
    };

    const sorted = _.sortBy(props.teamsData, ['name']);

    const isPendingUser = (team: Team): boolean => !_.isEmpty(team.pendingInviteId);

    const onClick = (team: Team): (() => void) | undefined =>
        _.isEmpty(team.pendingInviteId)
            ? (): void => {
                  navigateTo(`/teams/${team.id}`);
              }
            : undefined;

    const getUserRole = (team: Team): string =>
        `${team.pendingInviteId ? 'pending ' : ''}${_.lowerCase(team.requestingUserRole)}`;

    return (
        <Box sx={Styles.list}>
            <SelectableDropdownList
                testId="teams-overview-list"
                listItems={sorted.map((team) => ({
                    id: team.id,
                    title: team.name,
                    subtitle: getUserRole(team),
                    onClick: onClick(team),
                    rightComponent: (
                        <Box sx={Styles.rightComponent}>
                            {isPendingUser(team) && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={Styles.inviteButton}
                                    onClick={(e): void => {
                                        e.stopPropagation();
                                        setTeamInviteInfo({
                                            teamId: team.id,
                                            // we check above if pending, so we *do* have the invite id
                                            // but typescript is struggling with the property, hence the or'd string
                                            inviteId: team.pendingInviteId || '',
                                        });
                                    }}
                                >
                                    Respond To Team Invite
                                </Button>
                            )}
                            {!isPendingUser(team) && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    sx={Styles.leaveButton}
                                    onClick={(e): void => {
                                        e.stopPropagation();
                                        setLeaveTeamInfo(team);
                                    }}
                                >
                                    Leave
                                </Button>
                            )}
                            <Typography data-testid={`team-user-count`} variant="body1">
                                {team.userCount}
                            </Typography>
                            <Group sx={Styles.groupIcon} />
                        </Box>
                    ),
                }))}
            />
            {leaveTeamInfo && (
                <LeaveTeamConfirmationModal
                    team={leaveTeamInfo}
                    onClose={(): void => {
                        setLeaveTeamInfo(undefined);
                    }}
                    onSuccess={props.requeryTeamsData}
                />
            )}
            {teamInviteInfo && (
                <TeamInviteResponseModal
                    teamId={teamInviteInfo.teamId}
                    inviteId={teamInviteInfo.inviteId}
                    onClose={(): void => setTeamInviteInfo(undefined)}
                    requeryTeams={props.requeryTeamsData}
                />
            )}
        </Box>
    );
};
