import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAbilities } from 'src/client/types/user-abilities';
import { ChangeRoleConfirmationModal } from '../ChangeRoleConfirmationModal';
import { LeaveTeamConfirmationModal } from '../LeaveTeamConfirmationModal';
import { UserListItem } from '../UserListItem';
import { RemoveFromTeamConfirmationModal } from '../RemoveFromTeamConfirmationModal';
import { TeamRole } from 'src/shared/types/developer-portal-roles';

type UserRole = TeamRole;

type Member = {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    teamRole: UserRole;
    isPendingInvite?: boolean;
};

type UsersListContainerProps = {
    team: { id: string; name: string };
    teamMember: Member;
    loggedInUserId?: string;
    loggedInUserAbilities: UserAbilities;
    loggedInAsOwner: boolean;
    isLastExistingOwner: boolean;
    requeryTeamDetails: () => void;
};

enum ModalKind {
    EMPTY,
    LEAVE_TEAM,
    REMOVE_FROM_TEAM,
    CHANGE_ROLE,
}

const getOtherRole = (currentRole: UserRole): UserRole =>
    currentRole === TeamRole.Owner ? TeamRole.Member : TeamRole.Owner;

const memberDisplayName = (member: { firstName: string; lastName: string; email: string }): string =>
    _.trim(
        _.isEmpty(member.firstName) && _.isEmpty(member.lastName)
            ? member.email
            : `${member.firstName} ${member.lastName}`
    );

const createListItemTitle = (member: {
    firstName: string;
    lastName: string;
    email: string;
    isPendingInvite?: boolean;
}): string => `${memberDisplayName(member)}${member.isPendingInvite ? ' - (pending invitation)' : ''}`;

const displayModal = (args: {
    modalKind: ModalKind;
    clearModalKind: () => void;
    historyPush: (route: string) => void;
    team: { id: string; name: string };
    teamMember: Member;
    isLastExistingOwner: boolean;
    requeryTeamDetails: () => void;
}): JSX.Element | void => {
    switch (args.modalKind) {
        case ModalKind.LEAVE_TEAM:
            return (
                <LeaveTeamConfirmationModal
                    team={args.team}
                    onClose={args.clearModalKind}
                    onSuccess={(): void => {
                        args.historyPush('/teams');
                    }}
                    isLastExistingOwner={args.isLastExistingOwner}
                />
            );
        case ModalKind.REMOVE_FROM_TEAM:
            return (
                <RemoveFromTeamConfirmationModal
                    team={args.team}
                    targetUserId={args.teamMember.userId}
                    targetDisplayName={memberDisplayName({
                        email: args.teamMember.email,
                        firstName: args.teamMember.firstName,
                        lastName: args.teamMember.lastName,
                    })}
                    onClose={args.clearModalKind}
                    onSuccess={args.requeryTeamDetails}
                />
            );
        case ModalKind.CHANGE_ROLE:
            return (
                <ChangeRoleConfirmationModal
                    newRole={getOtherRole(args.teamMember.teamRole)}
                    team={args.team}
                    targetUserId={args.teamMember.userId}
                    targetDisplayName={memberDisplayName(args.teamMember)}
                    isLastExistingOwner={args.isLastExistingOwner}
                    onClose={args.clearModalKind}
                    onSuccess={args.requeryTeamDetails}
                />
            );
        default:
            return;
    }
};

export const UsersListItemContainer: React.FC<UsersListContainerProps> = (props) => {
    const navigate = useNavigate();
    const [currentModalToDisplay, setCurrentModalToDisplay] = useState<ModalKind>(ModalKind.EMPTY);

    const onLeaveClick = useCallback((): void => {
        setCurrentModalToDisplay(ModalKind.LEAVE_TEAM);
    }, [setCurrentModalToDisplay]);

    const onRemoveUserClick = useCallback((): void => {
        setCurrentModalToDisplay(ModalKind.REMOVE_FROM_TEAM);
    }, [setCurrentModalToDisplay]);

    const onChangeRoleClick = useCallback((): void => {
        setCurrentModalToDisplay(ModalKind.CHANGE_ROLE);
    }, [setCurrentModalToDisplay]);

    const clearModalKind = useCallback((): void => {
        setCurrentModalToDisplay(ModalKind.EMPTY);
    }, [setCurrentModalToDisplay]);

    const canEdit = props.loggedInUserAbilities.includes('Edit');
    const isAuthenticatedUser = (): boolean => props.loggedInUserId === props.teamMember.userId;

    const getMenuItems = (): any[] | undefined => {
        const menuItems = [];
        if (canEdit) {
            if (!props.teamMember.isPendingInvite) {
                menuItems.push({
                    text: `Make ${getOtherRole(props.teamMember.teamRole)}`,
                    onClick: onChangeRoleClick,
                });
            }
            if (!isAuthenticatedUser()) {
                menuItems.push({ text: 'Remove User', onClick: onRemoveUserClick });
            }
            return menuItems;
        }
        return undefined;
    };

    return (
        <>
            <UserListItem
                title={createListItemTitle(props.teamMember)}
                subtitle={isAuthenticatedUser() ? `•\xa0\xa0You` : ''}
                itemLabel={props.teamMember.teamRole}
                onLeaveClick={props.loggedInUserId === props.teamMember.userId ? onLeaveClick : undefined}
                userMenuItems={getMenuItems()}
            />
            {displayModal({
                modalKind: currentModalToDisplay,
                clearModalKind,
                historyPush: navigate,
                team: props.team,
                teamMember: props.teamMember,
                isLastExistingOwner: props.isLastExistingOwner,
                requeryTeamDetails: props.requeryTeamDetails,
            })}
        </>
    );
};
