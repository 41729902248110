import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Styles } from './styles';
import { GenericTextAndTitle } from '../../GenericTextAndTitle';
import { DateTimeHelpers } from 'src/shared/lib/date-helpers';

type EntitlementData = {
    activationId: string;
    expirationDate?: string;
    deviceLimit: number;
    duration?: string;
};

type CreateApplicationFormProps = {
    inFlight: boolean;
    errorMessage: string | undefined;
    clearErrorMessage: () => void;
    onSubmit: (name: string | undefined) => Promise<void>;
    data: EntitlementData | undefined;
};

export const ApplicationCreateForm: React.FC<CreateApplicationFormProps> = (props) => {
    const [applicationName, setApplicationName] = useState<string | undefined>(undefined);

    return (
        <Box data-testid="application-create-form">
            <Typography sx={Styles.bodyHeader} variant={'h4'} data-testid="create-app-title">
                Create New Application
            </Typography>
            <Box sx={Styles.applicationNameForm}>
                <TextField
                    variant="filled"
                    id="application-name-text-box"
                    sx={Styles.textField}
                    label="Application Name"
                    FormHelperTextProps={{ sx: Styles.formHelperText }}
                    helperText={props.errorMessage ? props.errorMessage : 'This will be the name of your application.'}
                    color="primary"
                    onChange={(event): void => {
                        if (props.errorMessage) {
                            props.clearErrorMessage();
                        }
                        setApplicationName(event.target.value);
                    }}
                    disabled={props.inFlight}
                    error={!!props.errorMessage}
                />

                <Button
                    sx={Styles.submitButton}
                    data-testid="submit-button"
                    variant="contained"
                    color="primary"
                    onClick={(): void => void props.onSubmit(applicationName)}
                    disabled={!applicationName || props.inFlight || !!props.errorMessage}
                >
                    {props.inFlight ? (
                        <CircularProgress sx={Styles.circularProgress} size={20} variant={'indeterminate'} />
                    ) : (
                        'Submit'
                    )}
                </Button>
                <Box sx={Styles.infoData}>
                    {props.data && (
                        <>
                            <Typography variant="h6"> Subscription Details:</Typography>
                            <GenericTextAndTitle
                                id="activation-txt-id"
                                title="Activation Key"
                                content={props.data.activationId}
                            />
                            <GenericTextAndTitle
                                id="quantity-txt-id"
                                title="Quantity"
                                content={props.data.deviceLimit.toString()}
                            />
                            <GenericTextAndTitle
                                id="expiration-txt-id"
                                title="Expiration Date"
                                content={
                                    props.data.duration
                                        ? `Expires ${props.data.duration} from first device activation`
                                        : props.data.expirationDate
                                        ? `Expires on ${DateTimeHelpers.formatDateStr(
                                              new Date(props.data.expirationDate).toISOString()
                                          )}`
                                        : 'Not Defined'
                                }
                            />
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
