import * as Colors from '@brightlayer-ui/colors';
import { Theme } from '@mui/material/styles';

export const Styles = {
    infoListItem: {
        maxHeight: '60px',
        '&:hover': {
            backgroundColor: Colors.blue[50],
        },
    },
    navSectionLink: {
        textDecoration: 'none',
    },
    navSectionText: {
        textDecoration: 'none',
        color: Colors.black[500],
        whiteSpace: 'pre-wrap',
    },
    contentBox: (theme: Theme) => ({
        padding: '30px 45px 30px 30px',
        width: '85%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    }),
    advancedTopicsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        minHeight: '100vh',
    },
    sideNav: {
        minWidth: '200px',
        width: '15%',
        position: 'sticky',
        height: '100vh',
        top: '64px',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    },
};
