import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { MakeTextField } from 'src/client/components/HelperComponents/TutorialHelpers/MakeTextField';
import { EM_API_SUBSCRIPTION_KEY, ORGANIZATION_AUTH_TOKEN } from 'src/client/constants/tutorial';
import { useAsyncFn } from 'src/client/hooks/async-fn-hook';
import { SimulatedEmApiClient } from 'src/client/simulated-em-api/simulated-em-api-client';
import { Validator } from 'src/client/util/validator';
import { getRuntimeConfig } from 'src/shared/lib/runtime-config';
import { withDelay } from 'src/shared/lib/with-delay';
import { RequestDefinition, RequestResponse } from '../../RequestResponse';
import { SubmitButton } from '../../SubmitButton';
import { Styles } from '../styles';
import { TutorialExpandable, TutorialStepProps } from '../TutorialExpandable';

type TextFields = {
    emApiSubscriptionKey: string;
    AuthorizationToken: string;
};

export const TutorialListDevices: React.FC<TutorialStepProps> = React.memo((props) => {
    const emApiBaseUrl = `${getRuntimeConfig(window).apiDocumentationUrl}`;

    const [tutorialListDevicesTextFieldValues, setListDevicesBodyValues] = useState<TextFields>({
        emApiSubscriptionKey: EM_API_SUBSCRIPTION_KEY,
        AuthorizationToken: ORGANIZATION_AUTH_TOKEN,
    });

    const [inFlight, response, invokeAsyncFn] = useAsyncFn(withDelay(750, SimulatedEmApiClient.listDevices));

    const submitRequest = (): void => invokeAsyncFn();

    const request: RequestDefinition = {
        method: 'GET',
        endpointPath: '/devices',
        headers: [
            { name: 'Em-Api-Subscription-Key', value: tutorialListDevicesTextFieldValues.emApiSubscriptionKey },
            { name: 'Authorization', value: `Bearer ${tutorialListDevicesTextFieldValues.AuthorizationToken}` },
            { name: 'Accept', value: 'application/json' },
        ],
    };

    const validationErrors = Validator.validateSchema(tutorialListDevicesTextFieldValues, {
        emApiSubscriptionKey: {
            type: 'string',
            format: {
                pattern: `^${EM_API_SUBSCRIPTION_KEY}$`,
                message: `key should be the same as the subscription key from the rest of the tutorial: ${EM_API_SUBSCRIPTION_KEY}.`,
            },
            presence: {
                allowEmpty: false,
            },
        },
        AuthorizationToken: {
            type: 'string',
            format: {
                pattern: `^${ORGANIZATION_AUTH_TOKEN}$`,
                message: `should be the token that was given at step 4: ${ORGANIZATION_AUTH_TOKEN}.`,
            },
            presence: {
                allowEmpty: false,
            },
        },
    });

    return (
        <TutorialExpandable
            {...props}
            title="List All Added Devices"
            description={
                <div>
                    <Typography variant="body1" sx={Styles.description}>
                        After the <strong>Installer</strong> has commissioned the Smart Breaker, it is accessible via
                        the{' '}
                        <a href={emApiBaseUrl} target="_blank" rel="noreferrer">
                            APIs
                        </a>{' '}
                        to the <strong>Organization</strong>.
                    </Typography>
                    <br />
                    <Typography variant="body1" sx={Styles.description}>
                        As an example, using an <strong>Organization Auth Token</strong>, we can confirm that the newly
                        commissioned device is returned in the list of overall devices. This api call can also filter
                        devices by location. However, for simplicity we have not included that here. Check out this{' '}
                        <a href={`${emApiBaseUrl}#operation/getDevices`} target="_blank" rel="noreferrer">
                            documentation
                        </a>{' '}
                        for more details.
                    </Typography>
                </div>
            }
            valueField={
                <Box component="div" sx={Styles.values}>
                    <form noValidate autoComplete="off">
                        <Box component="div" sx={Styles.entryFields}>
                            {MakeTextField(
                                tutorialListDevicesTextFieldValues,
                                validationErrors,
                                'emApiSubscriptionKey',
                                'EM API Subscription Key',
                                setListDevicesBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                tutorialListDevicesTextFieldValues,
                                validationErrors,
                                'AuthorizationToken',
                                'Organization Auth Token',
                                setListDevicesBodyValues,
                                {
                                    required: true,
                                }
                            )}
                        </Box>
                    </form>
                    <SubmitButton
                        id="send-request-button"
                        onClick={submitRequest}
                        disabled={!!validationErrors}
                        spinning={inFlight}
                        buttonText="Send Request"
                        width={144}
                    />
                </Box>
            }
            requestResponse={<RequestResponse request={request} response={response} />}
        />
    );
});
