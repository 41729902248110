import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    description: {
        marginLeft: '18px',
        marginRight: '18px',
        maxWidth: '700px',
    },
    valueField: {
        margin: '18px',
    },
    codeFormat: {
        backgroundColor: Colors.white[500],
    },
};
