import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    container: {
        position: 'relative',
    },
    stepNumberLayout: {
        minWidth: '40px',
        minHeight: '40px',
    },
    stepNumberVisited: {
        backgroundColor: Colors.gray[900],
    },
    stepNumberCurrent: {
        backgroundColor: Colors.blue[500],
    },
    stepNumberNotVisited: {
        backgroundColor: Colors.gray[500],
    },
    root: {
        color: Colors.white[50],
    },
    iconPosition: {
        maxWidth: '19px',
        height: '19px',
        minWidth: '19px',
        position: 'absolute',
        top: 0,
        left: '26px',
        zIndex: 100,
        backgroundColor: Colors.green[500],
    },
    checkIcon: {
        color: Colors.white[50],
        width: '16px',
        height: '16px',
    },
};
