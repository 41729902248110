import { Radio, ListItem, Box, Typography, List, RadioGroup, FormControlLabel } from '@mui/material';
import React from 'react';
import { Styles } from './style';
import { DateTimeHelpers } from 'src/shared/lib/date-helpers';

type Entitlements = {
    activationId: string;
    expirationDate?: string | undefined;
    deviceLimit: number;
    duration?: string;
};

type RadioCardModalProps = {
    data: Entitlements[];
    onRadioSelect?: (selectedValue: string) => void;
};

export const RadioCardModal: React.FC<RadioCardModalProps> = (props) => {
    const [selectedValue, setSelectedValue] = React.useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSelectedValue(value);
        if (props.onRadioSelect) {
            props.onRadioSelect(value);
        }
    };

    return (
        <Box sx={Styles.boxLayout}>
            <List dense>
                {props.data?.map((entitlement) => (
                    <div key={entitlement.activationId}>
                        <ListItem sx={Styles.listView} disablePadding>
                            <RadioGroup
                                data-testid="radio-item-block"
                                aria-labelledby="demo-error-radios"
                                name="radio-buttons"
                                value={selectedValue}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value={entitlement.activationId}
                                    control={<Radio />}
                                    label={entitlement.activationId}
                                />
                            </RadioGroup>
                        </ListItem>
                        <Typography sx={Styles.infoData}>
                            ({entitlement.deviceLimit} devices available,
                            {entitlement.duration
                                ? ` Expires ${entitlement.duration} from first device activation`
                                : entitlement.expirationDate
                                ? ` Expires on ${DateTimeHelpers.formatDateStr(
                                      new Date(entitlement.expirationDate).toISOString()
                                  )}`
                                : ' Expiration date not defined'}
                            )
                        </Typography>
                    </div>
                ))}
            </List>
        </Box>
    );
};
