export const Styles = {
    emptyState: {
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    emptyStateIcon: {
        alignItems: 'center',
        fontSize: '140px',
    },
    contentBody: { marginTop: '20px' },
};
