import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';
import * as Types from 'server/api/types';

export const getTeamInvite = (
    teamId: string,
    inviteId: string
): Promise<RequestHelperResult<Types.TeamsInvitesGetResponseBody['data']>> => {
    const pathParams: Types.TeamsInvitesGetPathParams = {
        teamId,
        inviteId,
    };

    return axiosRequestHelper<Types.TeamsInvitesGetResponseBody>(
        'GET',
        `/api/teams/${pathParams.teamId}/invites/${pathParams.inviteId}`
    );
};
