import { Box, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';
import { ApiClient } from 'src/client/api-client';
import { ApplicationRole } from 'src/shared/types/developer-portal-roles';
import { GenericConfirmationModal } from '../GenericConfirmationModal';
import { Styles } from './styles';

type ChangeRoleModalProps = {
    newRole: ApplicationRole;
    targetTeamId: string;
    targetDisplayName: string;
    application: {
        id: string;
        name: string;
    };
    isLastExistingAdmin: boolean;
    isOwnerIndividualTeam: boolean;
    isUserLosingAdminAccess: boolean;
    onClose: () => void;
    onSuccess: () => void;
};

export const ChangeTeamRoleConfirmationModal: React.FC<ChangeRoleModalProps> = (props) => {
    const [errorText, setErrorText] = useState<string>('');

    const changeRoleOnClick = async (): Promise<void> => {
        const result = await ApiClient.updateApplicationTeam(props.application.id, props.targetTeamId, props.newRole);

        if (!result.success) {
            setErrorText(`Failed to change role. ${result.error || ''} `);
            return;
        }
        props.onSuccess();
        props.onClose();
    };

    const getDemotionMessage = (): ReactElement<any, any> => {
        if (props.isUserLosingAdminAccess) {
            return (
                <div data-testid="modal-admin-access-message">
                    <Typography>
                        Team <b>{props.targetDisplayName}</b> is the last remaining admin team that you are a member of
                        on application <b>{props.application.name}</b>.
                    </Typography>
                    <br />
                    <Typography>
                        If you choose to demote team <b>{props.targetDisplayName}</b> to developer permissions, you will
                        no longer have any editing privileges for application <b>{props.application.name}</b>.
                    </Typography>
                </div>
            );
        }
        return (
            <div data-testid="modal-admin-access-message">
                <Typography>
                    {' '}
                    You are a member of one or more other teams that have admin permissions within application{' '}
                    <b>{props.application.name}</b>.
                </Typography>
                <br />
                <Typography>
                    Demoting team <b>{props.targetDisplayName}</b> to developer permissions will not affect your level
                    of access to application <b>{props.application.name}</b>.
                </Typography>
            </div>
        );
    };

    const defaultChangeRoleContent = (
        <Box sx={Styles.modalTextContainer}>
            {props.isLastExistingAdmin ? (
                <>
                    <Typography sx={Styles.confirmationQuestionText}>
                        You are the only admin on application <b>{props.application.name}</b>.
                    </Typography>
                    <Typography sx={Styles.confirmationInstructionalText}>
                        In order to change your role, you will have to first promote another team to admin.
                    </Typography>
                </>
            ) : (
                <>
                    <Typography sx={Styles.confirmationQuestionText}>
                        Are you sure you want to make{' '}
                        <b>{props.isOwnerIndividualTeam ? 'yourself' : props.targetDisplayName} </b>a
                        {props.newRole === ApplicationRole.Admin ? 'n' : ''} <b>{props.newRole}</b> of application{' '}
                        <b>{props.application.name}</b>?
                    </Typography>
                    {props.isOwnerIndividualTeam && props.newRole === ApplicationRole.Developer && (
                        <Typography sx={Styles.errorInformationText} data-testid="modal-warning-text">
                            You are attempting to change <b>yourself</b> from an admin to a developer on this
                            application.
                        </Typography>
                    )}
                    <br />
                    {getDemotionMessage()}
                </>
            )}
        </Box>
    );
    return (
        <GenericConfirmationModal
            errorText={errorText}
            modalTitle={'Change Team Role'}
            submitActionButtonText={'Change Role'}
            submitActionButtonDisabled={props.isLastExistingAdmin}
            cancelActionButtonText={'Cancel'}
            submitActionButtonOnClick={changeRoleOnClick}
            onClose={props.onClose}
        >
            {defaultChangeRoleContent}
        </GenericConfirmationModal>
    );
};
