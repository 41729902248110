import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    modalTextContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    confirmationQuestionText: {
        fontWeight: 600,
        fontSize: '1.25rem',
        marginBottom: '24px',
    },
    confirmationInstructionalText: {
        fontSize: '1rem',
    },
    errorInformationText: {
        color: Colors.red[500],
        fontSize: '1rem',
    },
};
