import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { UserAbilities } from 'src/client/types/user-abilities';
import { DateTimeHelpers } from 'src/shared/lib/date-helpers';
import { AddMembersModal } from '../AddMembersModal';
import { ItemDetailsHeader } from '../ItemDetailsHeader';
import { SelectableDropdownList } from '../SelectableDropdownList';
import { TabsDisplay } from '../TabsDisplay';
import { UsersList } from '../UsersList';
import { Styles } from './styles';
import { useNavigate, useLocation } from 'react-router';
import _ from 'lodash';
import { TeamRole } from 'src/shared/types/developer-portal-roles';
import { ApiClient } from 'src/client/api-client';
import { RequestHelperResult } from 'src/client/api-client/endpoints/endpoint-helpers';

type Member = {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    teamRole: TeamRole;
    isPendingInvite?: boolean;
};

type Details = {
    id: string;
    name: string;
    createdBy: {
        userId: string;
        firstName?: string;
        lastName?: string;
    };
    createdOn: string;
    members: Member[];
};

type Applications = Array<{
    name: string;
    id: string;
}>;

type TeamDetailsProps = {
    teamDetails: Details;
    userAbilities: UserAbilities;
    applications: Applications;
    requeryTeamDetails: () => void;
    appsFlag: boolean;
};

const getTeamCreationDetails = (createdBy: { userId: string; firstName?: string; lastName?: string }): string => {
    if (createdBy.firstName && createdBy.lastName) {
        return `created by ${createdBy.firstName} ${createdBy.lastName} on`;
    }
    return `created on`;
};

const getTeamSubtitleDisplay = (teamCount: number): string => `${teamCount} team member${teamCount > 1 ? 's' : ''}`;

export const TeamDetails: React.FC<TeamDetailsProps> = (props) => {
    const navigate = useNavigate();
    const path = useLocation().pathname;

    const navigateTo = (route: string): void => {
        navigate(route);
    };

    const [memberModalOpen, setMemberModalOpen] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState(+props.appsFlag);

    const changeTabView = (tab: number): void => {
        const teamRoute = /\/teams\/([a-f0-9\\-]*)/.exec(path);
        if (!teamRoute) {
            return;
        }
        if (tab === 0) {
            window.history.pushState('', 'My Teams', `${teamRoute[0]}`);
            setSelectedTab(tab);
            return;
        }
        window.history.pushState('', 'My Teams', `${teamRoute[0]}/applications`);
        setSelectedTab(tab);
    };

    const saveTeamName = (name: string): Promise<RequestHelperResult<undefined>> =>
        ApiClient.updateTeamInfo(props.teamDetails.id, name);

    const sortedApps = _.sortBy(props.applications, ['name']);

    return (
        <Box sx={Styles.body} data-testid="team-details">
            <ItemDetailsHeader
                title={props.teamDetails.name}
                subtitle={`${getTeamSubtitleDisplay(props.teamDetails.members.length)}`}
                details={`${getTeamCreationDetails(props.teamDetails.createdBy)} ${DateTimeHelpers.formatDateStr(
                    props.teamDetails.createdOn
                )}`}
                onSave={saveTeamName}
                userAbilities={props.userAbilities}
            />

            <TabsDisplay labels={['Members', 'Apps']} setSelectedTab={changeTabView} selectedTab={selectedTab} />
            {selectedTab === 0 ? (
                <>
                    <UsersList
                        data-testid="users-list"
                        id={props.teamDetails.id}
                        name={props.teamDetails.name}
                        users={props.teamDetails.members}
                        userAbilities={props.userAbilities}
                        actionButtonText={'+ Add Member'}
                        actionButtonOnClick={(): void => setMemberModalOpen(true)}
                        requeryTeamDetails={props.requeryTeamDetails}
                    />
                    <AddMembersModal
                        teamId={props.teamDetails.id}
                        isOpen={memberModalOpen}
                        setOpen={setMemberModalOpen}
                        onComplete={props.requeryTeamDetails}
                    />
                </>
            ) : (
                <>
                    <SelectableDropdownList
                        testId="applications-overview-list"
                        listItems={sortedApps.map((app, index) => ({
                            id: app.id,
                            title: `App: ${app.name}`,
                            rightComponent: (
                                <Button
                                    id={`${index}-view-app-button`}
                                    variant="outlined"
                                    color="primary"
                                    sx={Styles.viewAppButton}
                                    onClick={(): void => navigateTo(`/applications/${app.id}`)}
                                >
                                    View App
                                </Button>
                            ),
                        }))}
                    />
                </>
            )}
        </Box>
    );
};
