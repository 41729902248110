import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    bar: { minHeight: '72px', paddingTop: 0, paddingBottom: 0, margin: 0 },
    teamsBox: { zIndex: 2 },
    rightComponent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: '30%',
        minHeight: 'inherit',
    },
    groupIcon: { marginLeft: '10px', color: Colors.gray[300] },
    teamCount: { marginLeft: '25px' },
    button: { textTransform: 'uppercase' },
};
