import { Typography } from '@mui/material';
import { useState } from 'react';
import { ApiClient } from 'src/client/api-client';
import { GenericConfirmationModal } from '../GenericConfirmationModal';
import { Styles } from './styles';

type RemoveTeamFromAppConfirmationModalProps = {
    app: { id: string; name: string };
    team: { teamId: string; name: string };
    isLastExistingAdmin: boolean;
    onClose: () => void;
    onSuccess: () => void;
};

export const RemoveTeamFromAppConfirmationModal: React.FC<RemoveTeamFromAppConfirmationModalProps> = (props) => {
    const [errorText, setErrorText] = useState<string>('');

    const onSubmitClick = async (): Promise<void> => {
        const result = await ApiClient.removeTeamFromApplication(props.app.id, props.team.teamId);

        if (!result.success) {
            setErrorText(`Failed to remove team from application. ${result.error || 'no error found'} `);
            return;
        }
        props.onSuccess();
        props.onClose();
    };

    return (
        <GenericConfirmationModal
            errorText={errorText}
            submitActionButtonOnClick={onSubmitClick}
            onClose={props.onClose}
            modalTitle="Remove from Application"
            submitActionButtonText="Remove Team"
            cancelActionButtonText="Cancel"
            submitActionButtonDisabled={props.isLastExistingAdmin}
        >
            {props.isLastExistingAdmin ? (
                <>
                    <Typography sx={Styles.confirmationQuestionText}>
                        This is the last application admin team for <b>{props.app.name}</b>.{' '}
                    </Typography>
                    <Typography sx={Styles.confirmationInstructionalText}>
                        In order to remove this team from the application, you will have to assign admin permissions to
                        another team.
                    </Typography>
                </>
            ) : (
                <>
                    <Typography sx={Styles.confirmationQuestionText}>
                        Are you sure you want to remove the
                        <b> {props.team.name}</b> team from the <b> {props.app.name}</b>?
                    </Typography>
                    <Typography sx={Styles.confirmationInstructionalText}>
                        The team will have to be added again to rejoin.
                    </Typography>
                </>
            )}
        </GenericConfirmationModal>
    );
};
