/* eslint-disable @typescript-eslint/no-unused-vars */
import addHours from 'date-fns/addHours';
import addYears from 'date-fns/addYears';
import { Clock } from 'src/shared/lib/clock';
import { DateTimeHelpers } from 'src/shared/lib/date-helpers';
import { getRuntimeConfig } from 'src/shared/lib/runtime-config';
import { v4 as uuidv4 } from 'uuid';
import {
    ADDRESS_LOCATION_ID,
    CLIENT_ID,
    CLIENT_SECRET,
    CONTENT_TYPE,
    DEVICE_ID,
    EQUIPMENT_LOCATION_ID,
    INSTALLER_ROLE_ID,
    INVITE_CODE,
    ORGANIZATION_AUTH_TOKEN,
    ORGANIZATION_ID,
    SERVICE_ACCOUNT_AUTH_TOKEN,
    USER_ROLE_ID,
} from '../constants/tutorial';

type NoContentResponse = {
    statusLine: string;
    headers: Array<{ name: string; value: string }>;
};

type Response<T> = NoContentResponse & {
    body: {
        data: T;
    };
};

const currentTime = Clock.now;
const expiryTime = (duration: 'Hours' | 'Years', amount: number): Date => {
    if (duration === 'Hours') {
        return addHours(currentTime(), amount);
    }
    return addYears(currentTime(), amount);
};
const formattedDateHeader = (): string =>
    `${DateTimeHelpers.formatUTCDateStr(currentTime(), 'EEE, dd MMM yyy HH:mm:ss')} GMT`;

const commonHeaders = (): Array<{ name: string; value: string }> => [
    { name: 'Date', value: formattedDateHeader() },
    { name: 'Content-Type', value: CONTENT_TYPE },
    { name: 'X-Etn-Em-Correlation-Id', value: uuidv4() },
];

type AuthTokenResponse = Response<{
    token: string;
    expiresAt: string;
}>;

type StatusCodes = '200 OK' | '201 Created' | '204 No Content';
const statusLine = (statusCode: StatusCodes): string => `HTTP/1.1 ${statusCode}`;

// args in the simulated EM API Client calls are used to mimic the args in a real non-simulated call.
// even though the args are not necessarily being used by the simulated function, we still want them to be included.
const createServiceAccountAuthToken = (_args: { clientId: string; clientSecret: string }): Promise<AuthTokenResponse> =>
    Promise.resolve({
        statusLine: statusLine('200 OK'),
        headers: commonHeaders(),
        body: {
            data: {
                token: SERVICE_ACCOUNT_AUTH_TOKEN,
                expiresAt: expiryTime('Hours', 1).toJSON(),
            },
        },
    });

type CreateOrganizationResponse = Response<{
    id: string;
    name: string;
    description: string;
    serviceAccount: {
        clientId: string;
        secrets: Array<{
            name: string;
            value: string;
            expiry: string;
        }>;
    };
}>;

const createOrganization = (args: { name: string; description: string }): Promise<CreateOrganizationResponse> =>
    Promise.resolve({
        statusLine: statusLine('201 Created'),
        headers: [
            ...commonHeaders(),
            {
                name: 'Location',
                value: `${getRuntimeConfig(window).emApiBaseUrl}/api/v1/organizations/${ORGANIZATION_ID}`,
            },
        ],
        body: {
            data: {
                id: ORGANIZATION_ID,
                name: args.name,
                description: args.description,
                serviceAccount: {
                    clientId: CLIENT_ID,
                    secrets: [
                        { name: 'secret1', expiry: expiryTime('Years', 1).toJSON(), value: CLIENT_SECRET },
                        {
                            name: 'secret2',
                            expiry: expiryTime('Years', 1).toJSON(),
                            value: '-Qn3.f3W42PS6jY.vA--0VTr04U7l_4eK.',
                        },
                    ],
                },
            },
        },
    });

type LoginOrganizationResponse = Response<{
    token: string;
    expiresAt: string;
}>;

const loginAsOrganizationAccount = (_args: {
    clientId: string;
    clientSecret: string;
}): Promise<LoginOrganizationResponse> =>
    Promise.resolve({
        statusLine: statusLine('200 OK'),
        headers: commonHeaders(),
        body: {
            data: {
                token: ORGANIZATION_AUTH_TOKEN,
                expiresAt: expiryTime('Hours', 1).toJSON(),
            },
        },
    });

type CreateLocationAddressResponse = Response<{
    id: string;
    organizationId: string;
    name: string;
    description?: string;
    contact?: string;
    email?: string;
    phone?: string;
    locationType: string;
    address: {
        city: string;
        state: string;
        postalCode: string;
        street1: string;
        street2?: string;
    };
}>;

const createLocationAddress = (args: {
    organizationId: string;
    name: string;
    parentLocationId?: string;
    description?: string;
    contact?: string;
    email?: string;
    phone?: string;
    locationType: string;
    address: {
        city: string;
        state: string;
        postalCode: string;
        street1: string;
        street2?: string;
    };
}): Promise<CreateLocationAddressResponse> =>
    Promise.resolve({
        statusLine: statusLine('201 Created'),
        headers: [
            ...commonHeaders(),
            {
                name: 'Location',
                value: `${getRuntimeConfig(window).emApiBaseUrl}/api/v1/locations/${ADDRESS_LOCATION_ID}`,
            },
        ],
        body: {
            data: {
                id: ADDRESS_LOCATION_ID,
                ...args,
            },
        },
    });

type UserRoleResponse = Response<{
    id: string;
    status: string;
    email: string;
    roleId: string;
    organizationId?: string;
    locationId?: string;
}>;

const createUserRole = (args: {
    email: string;
    roleId: string;
    locationId?: string;
    organizationId?: string;
}): Promise<UserRoleResponse> =>
    Promise.resolve({
        statusLine: statusLine('201 Created'),
        headers: [
            ...commonHeaders(),
            {
                name: 'Location',
                value: `${getRuntimeConfig(window).emApiBaseUrl}/api/v1/userRoles/${USER_ROLE_ID}`,
            },
        ],
        body: {
            data: {
                id: USER_ROLE_ID,
                status: 'active',
                email: args.email,
                roleId: args.roleId,
                [args.locationId ? 'locationId' : 'organizationId']: args.locationId
                    ? args.locationId
                    : args.organizationId,
            },
        },
    });

type CreateInviteCodeResponse = Response<{
    code: string;
    roleId: string;
    createdAt: string;
    userAcceptances: Array<{ id: string; userId: string; email: string; joinedDate: string }>;
    locationId?: string;
    organizationId?: string;
    restrictions?: { userLimit?: number; emailDomain?: string; expirationDate?: string };
}>;

const createInviteCode = (args: {
    roleId: string;
    locationId?: string;
    organizationId?: string;
    restrictions?: { userLimit?: number; emailDomain?: string; expirationDate?: string };
}): Promise<CreateInviteCodeResponse> =>
    Promise.resolve({
        statusLine: statusLine('201 Created'),
        headers: [
            ...commonHeaders(),
            {
                name: 'Location',
                value: `${getRuntimeConfig(window).emApiBaseUrl}/api/v1/inviteCodes/${INVITE_CODE}`,
            },
        ],
        body: {
            data: {
                code: INVITE_CODE,
                roleId: args.roleId,
                createdAt: currentTime().toJSON(),
                [args.locationId ? 'locationId' : 'organizationId']: args.locationId
                    ? args.locationId
                    : args.organizationId,
                restrictions: args.restrictions,
                userAcceptances: [],
            },
        },
    });

type CreateEquipmentLocationResponse = Response<{
    id: string;
    name: string;
    organizationId: string;
    description?: string;
    parentLocationId: string;
    contact?: string;
    email?: string;
    phone?: string;
    locationType: string;
}>;

const createEquipmentLocation = (args: {
    organizationId: string;
    name: string;
    parentLocationId: string;
    description?: string;
    contact?: string;
    email?: string;
    phone?: string;
    locationType: string;
}): Promise<CreateEquipmentLocationResponse> =>
    Promise.resolve({
        statusLine: statusLine('201 Created'),
        headers: [
            ...commonHeaders(),
            {
                name: 'Location',
                value: `${getRuntimeConfig(window).emApiBaseUrl}/api/v1/locations/${EQUIPMENT_LOCATION_ID}`,
            },
        ],
        body: {
            data: {
                id: EQUIPMENT_LOCATION_ID,
                ...args,
            },
        },
    });

type ListAssignableRoles = Response<[{ name: string; id: string }]>;

const getAssignableRoles = (): Promise<ListAssignableRoles> =>
    Promise.resolve({
        statusLine: statusLine('200 OK'),
        headers: [...commonHeaders()],
        body: {
            data: [
                {
                    id: INSTALLER_ROLE_ID,
                    name: 'Installer',
                },
            ],
        },
    });

type ListDevicesResponse = Response<
    [{ id: string; locationId: string; hardwareType: string; sku: string; serialNumber: string }]
>;

const listDevices = (): Promise<ListDevicesResponse> =>
    Promise.resolve({
        statusLine: statusLine('200 OK'),
        headers: [...commonHeaders()],
        body: {
            data: [
                {
                    id: DEVICE_ID,
                    locationId: EQUIPMENT_LOCATION_ID,
                    hardwareType: 'emcb',
                    sku: 'KSCV1289',
                    serialNumber: '30000d3a152e2a80',
                },
            ],
        },
    });

const controlDevice = (_args: {
    command: 'open' | 'close';
    reason: string;
    secondsUntilReset?: number;
}): Promise<NoContentResponse> =>
    Promise.resolve({
        headers: [{ name: 'Date', value: formattedDateHeader() }],
        statusLine: statusLine('204 No Content'),
    });

export const SimulatedEmApiClient = {
    controlDevice,
    createEquipmentLocation,
    createLocationAddress,
    createOrganization,
    createServiceAccountAuthToken,
    getAssignableRoles,
    createUserRole,
    listDevices,
    loginAsOrganizationAccount,
    createInviteCode,
};
