export const Styles = {
    description: {
        marginLeft: '18px',
        marginRight: '18px',
        maxWidth: '700px',
    },
    values: {
        margin: '18px',
    },
};
