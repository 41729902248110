import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerNavGroup,
    DrawerNavItem,
} from '@brightlayer-ui/react-components';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from '@mui/icons-material/Menu';
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearAndDeleteToken } from 'src/client/helpers/clear-and-delete-token';
import { EatonLogo } from 'src/client/icons/eaton-logo';
import { useDrawer } from '../../contexts/DrawerContextProvider';
import { AdvancedTopicsPages, DrawerPages, DrawerPagesAuthorized, getExternalDrawerPages } from '../../router/routes';
import { Styles } from './styles';
import { useApp } from 'src/client/contexts/AppContextProvider';

export const NavigationDrawer: React.FC = () => {
    const { drawerOpen, setDrawerOpen } = useDrawer();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const app = useApp();
    const [selected, setSelected] = useState(
        DrawerPagesAuthorized.concat(DrawerPages)
            .concat(AdvancedTopicsPages)
            .find((page) => {
                if (page.routeRegex && page.routeRegex.test(location.pathname)) {
                    return page;
                } else if (page.route === location.pathname) {
                    return page;
                }
                return undefined;
            })?.itemID
    );

    const logOut = (): void => {
        void clearAndDeleteToken(app.loginData.userId || '').then(() => {
            app.onUserNotAuthenticated();
        });
    };

    const navigateTo = (id: string, route: string): void => {
        navigate(route);
        setSelected(id);
    };

    const accountInfo = useCallback((): void => {
        navigate('/account');
    }, [navigate]);

    const setPasswordOnClick = useCallback((): void => {
        accountInfo();
        app.setShowChangePasswordDialog(true);
    }, [accountInfo, app]);

    return (
        <Drawer
            data-testid={drawerOpen ? 'drawer-nav-open' : 'drawer-nav-closed'}
            open={drawerOpen}
            ModalProps={{
                onBackdropClick: (): void => {
                    setDrawerOpen(false);
                },
            }}
            variant={isMobile ? 'temporary' : 'persistent'}
            activeItem={selected}
        >
            <DrawerHeader
                title={'Eaton Developer Portal'}
                sx={Styles.drawerHeader}
                icon={<Menu data-testid="drawer-nav-menu-icon" />}
                onIconClick={(): void => {
                    setDrawerOpen(!drawerOpen);
                }}
            />
            <DrawerBody>
                <DrawerNavGroup
                    items={DrawerPages.map((page) => {
                        const Icon = page.icon;
                        return {
                            title: page.title,
                            itemID: page.itemID,
                            icon: Icon && <Icon />,
                            onClick: page.route
                                ? (): void => {
                                      navigateTo(_.kebabCase(page.title), page.route);
                                      if (isMobile) setDrawerOpen(false);
                                  }
                                : undefined,
                            items: page.items?.map((subPage) => ({
                                ...subPage,
                                onClick: subPage.route
                                    ? (): void => {
                                          navigateTo(_.kebabCase(subPage.title), subPage.route);
                                          if (isMobile) setDrawerOpen(false);
                                      }
                                    : undefined,
                            })),
                        };
                    })}
                    hidePadding
                />
                <Divider />
                <DrawerNavGroup
                    sx={Styles.navGroupTitle}
                    items={getExternalDrawerPages().map((page) => {
                        const Icon = page.icon;
                        return {
                            title: page.title,
                            itemID: page.itemID,
                            rightComponent: page.rightComponent,
                            icon: Icon && <Icon />,
                            onClick: page.externalRoute
                                ? (): void => {
                                      window.open(page.externalRoute);
                                  }
                                : undefined,
                        };
                    })}
                    hidePadding
                />
                {app.isAuthenticated && (
                    <>
                        <Divider />
                        <DrawerNavGroup
                            items={DrawerPagesAuthorized.map((page) => {
                                const Icon = page.icon;
                                return {
                                    title: page.title,
                                    itemID: page.itemID,
                                    icon: Icon && <Icon />,
                                    onClick: page.route
                                        ? (): void => {
                                              navigateTo(_.kebabCase(page.title), page.route);
                                              if (isMobile) setDrawerOpen(false);
                                          }
                                        : undefined,
                                };
                            })}
                            hidePadding
                        />
                    </>
                )}
            </DrawerBody>

            <DrawerFooter data-testid="drawer-footer" hideContentOnCollapse={true}>
                <Box sx={Styles.drawerFooter}>
                    {app.isAuthenticated && (
                        <DrawerNavItem
                            title={
                                app.loginData.firstName || app.loginData.lastName
                                    ? `${app.loginData.firstName || ''} ${app.loginData.lastName || ''}`
                                    : app.loginData.email || ''
                            }
                            itemID="drawer-user-menu"
                            icon={<AccountCircleIcon />}
                            items={[
                                {
                                    itemID: 'my-account-button',
                                    title: 'My Account',
                                    onClick: accountInfo,
                                },
                                {
                                    title: 'Change Password',
                                    itemID: 'change-password-menu-item',
                                    onClick: setPasswordOnClick,
                                },
                                {
                                    title: 'Log Out',
                                    itemID: 'log-out-menu-item',
                                    onClick: logOut,
                                },
                            ]}
                        />
                    )}
                    <Box sx={Styles.sideDrawerImageCopywriteFooter}>
                        <EatonLogo sx={Styles.eatonLogo} />

                        <Typography variant={'caption'} sx={Styles.copywriteText}>
                            Copyright © Eaton 2021 <br />
                            All Rights Reserved
                        </Typography>
                    </Box>
                </Box>
            </DrawerFooter>
        </Drawer>
    );
};
