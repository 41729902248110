import { PageWrapper } from 'src/client/components/PageWrapper';
import { ScrollableNav } from 'src/client/components/ScrollableNav';
import source from 'src/client/markdown-content/markdown-pages/UnderstandingEvseConfiguration.md';

const UNDERSTANDING_EVSE_CONFIGURATION = [
    {
        id: 'modesAndConfiguration',
        title: 'Modes and Configurations',
    },
    {
        id: 'modeNoRestrictions',
        title: 'Mode: No Restrictions',
    },
    {
        id: 'modeOcpp',
        title: 'Mode: OCPP',
    },
    {
        id: 'modeCloudApi',
        title: 'Mode: Cloud API',
    },
    {
        id: 'modeChargeWindows',
        title: 'Mode: Charge Windows',
    },
    {
        id: 'modeApiOverrideEnable',
        title: 'Mode: API Override Enable',
    },
    {
        id: 'modeApiOverrideDisable',
        title: 'Mode: API Override Disable',
    },
    {
        id: 'modeManualOverride',
        title: 'Mode: Manual Override',
    },
    {
        id: 'configurationOfflineConfiguration',
        title: 'Configuration: Offline Configuration',
    },
    {
        id: 'currentlyAppliedSettings',
        title: 'Currently Applied Settings',
    },
];

export const UnderstandingEvseConfigurationPage: React.FC = () => (
    <PageWrapper title="Understanding EVSE Configurations">
        <ScrollableNav
            sectionList={UNDERSTANDING_EVSE_CONFIGURATION}
            source={source}
            pageRoute="/advancedTopics/evseConfiguration"
        />
    </PageWrapper>
);
