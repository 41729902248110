import * as Colors from '@brightlayer-ui/colors';
import { Error, Info, Warning } from '@mui/icons-material';

export const getStatusIndicators = (level: 'info' | 'warning' | 'error', includeIcons?: boolean) => {
    switch (level) {
        case 'info':
            return {
                statusColor: Colors.green[400],
                backgroundColor: Colors.green[50],
                icon: includeIcons ? <Info /> : undefined,
            };
        case 'warning':
            return {
                statusColor: Colors.yellow[500],
                backgroundColor: Colors.yellow[50],
                icon: includeIcons ? <Warning /> : undefined,
            };
        default:
            return {
                statusColor: Colors.red[400],
                backgroundColor: Colors.red[50],
                icon: includeIcons ? <Error /> : undefined,
            };
    }
};
