import * as BrightLayerThemes from '@brightlayer-ui/react-themes';
import '@brightlayer-ui/react-themes/open-sans';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { App } from 'src/client/App';
import 'src/client/index.css';
import { Version } from './client/components/Version';

const rootElement = document.getElementById('root');

// eslint-disable-next-line
const root = createRoot(rootElement!);

// Intentionally not using StrictMode around the whole app to avoid issues with BLUI Auth component effects.
root.render(
    <>
        <Version />
        <ThemeProvider theme={createTheme(BrightLayerThemes.blue)}>
            <CssBaseline />
            <App />
        </ThemeProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// Move this to proper internal azure logging
// reportWebVitals(console.log);
