import { useLocation, useParams } from 'react-router';
import { ApiClient } from 'src/client/api-client';
import { DisplayError } from 'src/client/components/DisplayError';
import { PageWrapper } from 'src/client/components/PageWrapper/index';
import { Spinner } from 'src/client/components/Spinner';
import { useRequest } from 'src/client/hooks/request-hook';
import { TeamDetails } from 'src/client/components/TeamDetails';

export const TeamsDetailsPage = (): JSX.Element => {
    const params = useParams();
    const id = params.id ?? ''; // TODO better solution pls

    const [isTeamLoading, teamDetails, requeryTeam] = useRequest(() => ApiClient.getTeamById(id));
    const [isSecurityLoading, userAbilities, requeryAbilities] = useRequest(() =>
        ApiClient.getSecurityAbilities('Team', id)
    );
    const [areAppsLoading, teamApps, requeryApps] = useRequest(() => ApiClient.getApplicationsByTeam(id));

    const location = useLocation();
    const appsFlag = location.pathname.includes('/applications');

    const requery = (): void => {
        requeryTeam();
        requeryAbilities();
        requeryApps();
    };

    return (
        <PageWrapper title="My Teams">
            {isTeamLoading || isSecurityLoading || areAppsLoading ? (
                <Spinner />
            ) : !teamDetails || !userAbilities || !teamApps ? (
                <DisplayError />
            ) : (
                <TeamDetails
                    teamDetails={teamDetails}
                    userAbilities={userAbilities}
                    applications={teamApps}
                    requeryTeamDetails={requery}
                    appsFlag={appsFlag}
                />
            )}
        </PageWrapper>
    );
};
