import {
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { ApiClient } from 'src/client/api-client';
import { useRequest } from 'src/client/hooks/request-hook';
import { SimpleModal } from '../SimpleModal';
import { Spinner } from '../Spinner';
import { Styles } from './styles';

type TeamInviteResponseModalProps = {
    inviteId: string;
    teamId: string;
    onClose: () => void;
    requeryTeams: () => void;
};

type InviteResponseKind = 'Accept' | 'Reject';

export const TeamInviteResponseModal: React.FC<TeamInviteResponseModalProps> = (props) => {
    const [isLoading, inviteData] = useRequest(() => ApiClient.getTeamInvite(props.teamId, props.inviteId));
    const [inviteResponse, setInviteResponse] = useState<InviteResponseKind | ''>('');
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const submitResponse = async (): Promise<void> => {
        if (inviteResponse) {
            const result = await ApiClient.deleteTeamInvite(props.teamId, props.inviteId, inviteResponse);
            if (result.success) {
                props.onClose();
                props.requeryTeams();
            } else {
                setErrorMessage(result.error);
            }
        }
    };

    if (!isLoading && !inviteData) {
        return (
            <SimpleModal
                isOpen={true}
                modalTitle={'Respond to Team Invite'}
                submitActionButtonText={'Ok'}
                submitActionButtonOnClick={(): Promise<void> => Promise.resolve(props.onClose())}
                onClose={props.onClose}
            >
                <Typography variant="body1" sx={Styles.errorMessage} data-testid="error-content">
                    <b>Error:</b> Failed to retrieve team invite information.
                </Typography>
            </SimpleModal>
        );
    }

    return (
        <SimpleModal
            isOpen={true}
            modalTitle={'Respond to Team Invite'}
            submitActionButtonText={'Submit'}
            submitActionButtonOnClick={submitResponse}
            submitActionButtonDisabled={_.isEmpty(inviteResponse) || isLoading}
            cancelActionButtonText={'Cancel'}
            onClose={props.onClose}
        >
            {isLoading ? (
                <Spinner sx={Styles.modalSpinner} />
            ) : (
                <>
                    <DialogContentText data-testid="invite-description">
                        <b>{inviteData?.inviterName}</b> has invited you to join the team <b>{inviteData?.teamName}</b>.
                        Please respond to the invite by either accepting or rejecting it.
                    </DialogContentText>
                    <DialogContentText data-testid="invite-description">
                        If you have any questions about this team invite, you can contact {inviteData?.inviterName} via
                        e-mail at:{' '}
                        <a data-testid="email-url" href={`mailto:${inviteData?.inviterEmail || ''}`}>
                            {inviteData?.inviterEmail}
                        </a>
                    </DialogContentText>
                    <Divider sx={Styles.divider} />
                    <FormControl component="fieldset">
                        <FormLabel component="legend" sx={Styles.formLabel}>
                            Invite Response
                        </FormLabel>
                        <RadioGroup
                            aria-label="invite response"
                            name="invite-response"
                            value={inviteResponse}
                            onChange={(e): void => setInviteResponse(e.target.value as InviteResponseKind)}
                            data-testid="invite-response-form"
                        >
                            <FormControlLabel value="Accept" control={<Radio />} label="Accept Invite" />
                            <FormControlLabel value="Reject" control={<Radio />} label="Reject Invite" />
                        </RadioGroup>
                    </FormControl>
                    {errorMessage && (
                        <>
                            <Divider sx={Styles.divider} />
                            <Typography variant="body1" sx={Styles.errorMessage} data-testid="error-content">
                                <b>Failed to respond to team invite:</b> {errorMessage}
                            </Typography>
                        </>
                    )}
                </>
            )}
        </SimpleModal>
    );
};
