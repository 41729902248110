import { Box, Divider, FormGroup, Typography } from '@mui/material';
import _ from 'lodash';
import { ApplicationRole } from 'src/shared/types/developer-portal-roles';
import { Team } from '..';
import { BasicDropdown } from '../../BasicDropdown';
import { Styles } from './styles';

type SetRolesProps = {
    teams: Team[];
    setTeams: (team: Team[]) => void;
    submitting?: boolean;
    submitError?: string;
};

export const SetRoles: React.FC<SetRolesProps> = (props) => {
    const onRoleSelect = (team: Team, role: ApplicationRole): void => {
        props.setTeams(_.sortBy(_.without(props.teams, team).concat([{ ...team, role }]), 'name'));
    };

    return (
        <div>
            <div data-testid="role-content">
                <Typography variant="body1">Set the preferred role you would like for each team.</Typography>
                <ul>
                    <li>
                        <Typography variant="body1">
                            The <strong>Admin Role</strong> gives the team permission to <strong>edit</strong> the
                            application.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            The <strong>Developer Role</strong> gives the team permission to <strong>view</strong> the
                            application. The default role is Developer.
                        </Typography>
                    </li>
                </ul>
            </div>
            <FormGroup data-testid="form-group" sx={Styles.scrollableContainer}>
                <Divider />
                {_.sortBy(props.teams, 'name').map((team, i) => (
                    <div key={i} data-testid="form-item">
                        <Box sx={Styles.listItem}>
                            <Typography variant="subtitle1" sx={Styles.dropdownHeader}>
                                {team.name}
                            </Typography>
                            <BasicDropdown
                                label={`Team Role for ${team.name}`}
                                value={team.role || ApplicationRole.Developer}
                                onChange={(role): void => onRoleSelect(team, role as ApplicationRole)}
                                items={[ApplicationRole.Admin, ApplicationRole.Developer]}
                                disabled={props.submitting}
                            />
                        </Box>
                        <Divider />
                    </div>
                ))}
            </FormGroup>
            {props.submitError && (
                <Box data-testid="team-submit-error" sx={Styles.errorTextField}>
                    <Typography variant="subtitle2">Failed to add teams to application:</Typography>
                    <Typography variant="body2">{props.submitError}</Typography>
                </Box>
            )}
        </div>
    );
};
