import { StatusCodes } from 'http-status-codes';
import { useCallback, useEffect, useState } from 'react';
import { Result } from 'src/client/types/result';
import { useApp } from '../contexts/AppContextProvider';

export const useRequest = <T>(
    request: () => Promise<Result<T, { statusCode?: number }>>
): [boolean, T | undefined, () => void] => {
    const [data, setData] = useState<T | undefined>(undefined);
    const [loading, setIsLoading] = useState(true);
    const [requery, setRequery] = useState(true);
    const app = useApp();

    useEffect(
        () => {
            const fetchData = async (): Promise<void> => {
                setIsLoading(true);
                setData(undefined);
                const result = await request();
                setRequery(false);
                setIsLoading(false);

                if (result.success) {
                    setData(result.data);
                } else {
                    setData(undefined);
                    // We should come up with a better solution that is inside the axios request helper.
                    if (result.errorDetail?.statusCode === StatusCodes.UNAUTHORIZED) {
                        app.onUserNotAuthenticated();
                    }
                }
            };
            if (requery) {
                void fetchData();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [requery]
    );

    const requestFn = useCallback(() => {
        setRequery(true);
    }, [setRequery]);

    return [loading, data, requestFn];
};
