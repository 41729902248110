import { Button, InputAdornment, TextField } from '@mui/material';
import _ from 'lodash';
import { Styles } from './styles';
import { SxProps } from '@mui/material/styles';
import { generateSxProps } from '../../helpers/generate-sx-props';

type ReadonlyTextAndCopyProps = {
    title: string;
    content: string;
    sx?: SxProps;
    helperText?: string;
};

export const ReadOnlyTextAndCopy: React.FC<ReadonlyTextAndCopyProps> = (props) => (
    <TextField
        id={`${_.kebabCase(props.title)}-read-only-input`}
        data-testid={`${_.kebabCase(props.title)}-read-only-input`}
        label={props.title}
        variant="filled"
        defaultValue={props.content}
        sx={[Styles.readOnlyField, ...generateSxProps(props.sx)]}
        helperText={props.helperText}
        InputProps={{
            readOnly: true,
            endAdornment: (
                <InputAdornment position="end">
                    <Button
                        sx={Styles.copyButton}
                        variant="contained"
                        color="primary"
                        onClick={(): void => {
                            void navigator.clipboard.writeText(props.content);
                        }}
                    >
                        Copy
                    </Button>
                </InputAdornment>
            ),
        }}
    />
);
