import * as Colors from '@brightlayer-ui/colors';
import { Theme } from '@mui/material/styles';
export const Styles = {
    body: (theme: Theme) => ({
        marginLeft: theme.spacing(3),
        marginTop: '25px',
    }),
    teamNameForm: {
        display: 'flex',
        flexDirection: 'column',
    },
    bodyHeader: {
        marginBottom: '25px',
    },
    formHelperText: {
        marginLeft: '0px',
    },
    textField: (theme: Theme) => ({
        maxWidth: '400px',
        color: Colors.white[200],
        width: '100%',
        marginBottom: theme.spacing(2),
    }),
    submitButton: {
        maxWidth: '90px',
        marginTop: '10px',
        textTransform: 'uppercase',
    },
};
