import { AuthUIActions } from '@brightlayer-ui/react-auth-workflow';
import { ApiClient } from 'src/client/api-client';
import { LocalStorage } from 'src/client/store/local-storage';
import { AppContextType } from '../contexts/AppContextProvider';

/**
 * Example implementation of [[AuthUIActions]] to start with during development.
 *
 * Authentication Actions to be performed based on the user's UI actions. The application will create
 * appropriate actions (often api calls, local network storage, credential updates, etc) and update
 * the global security state based on the actionable needs of the user.
 */
export const ProjectAuthUIActions = (appHelper: AppContextType): AuthUIActions => ({
    /**
     * Initialize the application security state. This will involve reading any local storage,
     * validating existing credentials (token expiration, for example). At the end of validation,
     * the [[SecurityContextActions]] should be called with either:
     * [[onUserAuthenticated]] (which will present the application), or
     * [[onUserNotAuthenticated]] (which will present the Auth UI).
     *
     * Note: Until this method returns, the applications Splash screen will be presented.
     *
     * @returns Should always resolve. Never throw.
     */
    initiateSecurity: async (): Promise<void> => {
        const readAuthData = async () => {
            try {
                return await LocalStorage.readAuthData();
            } catch (e) {
                return;
            }
        };

        const authData = await readAuthData();

        // Using the auth token stored in a cookie, retrieve info about the current
        // user. This will verify that the user is actually still logged in.
        const userInformationResult = await ApiClient.getUserInformation({ includeUnacceptedEulas: true });
        if (!userInformationResult.success) {
            const rememberMeEmail = authData?.rememberMeData.rememberMe ? authData.rememberMeData.user : undefined;
            appHelper.onUserNotAuthenticated(false, rememberMeEmail);
            return;
        }

        appHelper.onUserAuthenticated({
            email: userInformationResult.data.email,
            userId: userInformationResult.data.id,
            rememberMe: authData?.rememberMeData.rememberMe || false,
            firstName: userInformationResult.data.firstName,
            lastName: userInformationResult.data.lastName,
            isEulaAccepted: userInformationResult.data.isEulaAccepted,
            isProfileComplete: userInformationResult.data.isProfileComplete,
        });
    },
    /**
     * The user wants to log into the application. Perform a login with the user's credentials.
     * The application should provide the user's email and password to the authentication server.
     *
     * In the case of valid credentials, the applications code should store the returned data
     * (such as token, user information, etc.). Then the [[onUserAuthenticated]] function should
     * be called on the [[SecurityContextActions]] object.
     *
     * For example:
     * ```
     * LocalStorage.saveAuthCredentials(email, email);
     * LocalStorage.saveRememberMeData(email, rememberMe);
     *
     * securityHelper.onUserAuthenticated({ email: email, userId: email, rememberMe: rememberMe });
     * ```
     *
     * In the case of invalid credentials, an error should be thrown.
     *
     * @param email Email address the user entered into the UI.
     * @param password Password the user entered into the UI.
     * @param rememberMe Indicates whether the user's email should be remembered on success.
     *
     * @returns Resolve if code is credentials are valid, otherwise reject.
     */
    logIn: async (email: string, password: string, rememberMe: boolean): Promise<void> => {
        const result = await ApiClient.createAuthToken(email, password);

        if (!result.success) {
            throw new Error('LOGIN.INVALID_CREDENTIALS');
        }

        LocalStorage.saveAuthCredentials(email, result.data.id);
        LocalStorage.saveRememberMeData(email, rememberMe);

        appHelper.onUserAuthenticated({
            email: email,
            userId: result.data.id,
            rememberMe: rememberMe,
            firstName: result.data.firstName,
            lastName: result.data.lastName,
            isEulaAccepted: result.data.isEulaAccepted,
            isProfileComplete: result.data.isProfileComplete,
        });
    },
    /**
     * The user has forgotten their password and wants help.
     * The application generally should call an API which will then send a password reset
     * link to the user's email.
     *
     * @param email Email address the user entered into the UI.
     *
     * @returns Resolve if email sending was successful, otherwise reject.
     */
    forgotPassword: async (email: string): Promise<void> => {
        const result = await ApiClient.userPasswordReset(email);
        if (result.success) {
            return;
        }
        throw new Error('Failed to reset password.');
    },
    /**
     * The user has tapped on an email with a password reset link, which they received after
     * requesting help for forgetting their password.
     * The application should take the password reset code and then verify that it is still
     * valid.
     *
     * @param code Password reset code from a reset password link.
     * @param email Email if it was passed from the reset link
     *
     * @returns Resolve if code is valid, otherwise reject.
     */
    verifyResetCode: async (code: string, email?: string): Promise<void> => {
        if (!email) {
            throw new Error('Email not found with verification code. Email is required.');
        }

        // We need to decode the email because it is encoded in the url
        const decodedEmail = decodeURIComponent(email);
        const result = await ApiClient.userPasswordReset(decodedEmail, code);
        if (result.success) {
            return;
        }
        throw new Error('Code could not be validated.');
    },
    /**
     * A user who has previously used "forgotPassword" now has a valid password reset code
     * and has entered a new password.
     * The application should take the user's password reset code and the newly entered
     * password and then reset the user's password.
     *
     * Note: Upon success, the user will be taken to the Login screen.
     *
     * @param code Password reset code from a link
     * @param password New Password the user entered into the UI
     * @param email Email if it was passed from the reset link
     *
     * @returns Resolve if successful, otherwise reject with an error message.
     */
    setPassword: async (code: string, password: string, email?: string): Promise<void> => {
        if (!email) {
            throw new Error('Email not found with verification code. Email is required.');
        }
        // We need to decode the email because it is encoded in the url
        const decodedEmail = decodeURIComponent(email);
        const result = await ApiClient.userPasswordReset(decodedEmail, code, password);
        if (result.success) {
            return;
        }
        throw new Error('Password Reset Failed');
    },
    /**
     * An authenticated user wants to change their password.
     * The application should try to change the user's password. Upon completion,
     * the user will be logged out of the application. Upon cancellation, the user
     * will be taken back to the application's home screen.
     *
     * @param oldPassword The user's current password as entered into the UI.
     * @param newPassword The user's new password as entered into the UI.
     *
     * @returns Resolve if successful, otherwise reject with an error message.
     */
    changePassword: async (oldPassword: string, newPassword: string): Promise<void> => {
        if (oldPassword === newPassword) {
            throw new Error('New password cannot be the same as your old password.');
        }

        const userId = (await LocalStorage.readAuthData()).userId ?? '';
        const result = await ApiClient.userPasswordChange(oldPassword, newPassword, userId);
        if (result.success) {
            return;
        }

        throw new Error(result.error || 'Failed to change password.');
    },
});
