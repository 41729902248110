/* eslint-disable no-console */
export const Version: React.FC = () => {
    const info = process.env.REACT_APP_VERSION_INFO;
    if (!info) {
        console.info('No version information present at build time.');
        return <></>;
    }
    console.info(`version: ${info}`);
    return <span style={{ display: 'none' }}>{info}</span>;
};
