export type RuntimeConfig = {
    emApiBaseUrl: string;
    apiDocumentationUrl: string;
    previewApiDocumentationUrl: string;
};

export const buildRuntimeConfigObject = (processEnv: any): RuntimeConfig => ({
    emApiBaseUrl: processEnv.EM_API_BASE_URL,
    apiDocumentationUrl: `${processEnv.EM_API_BASE_URL}/docs`,
    previewApiDocumentationUrl: `${processEnv.EM_API_BASE_URL}/preview/docs`,
});

export const runtimeConfigKeyName = 'devPortalConfig';

function hasConfig(x: unknown): x is { [runtimeConfigKeyName]: unknown } {
    if (typeof x === 'object' && x !== null) {
        return runtimeConfigKeyName in x;
    }
    return false;
}

export const getRuntimeConfig = (window: unknown): RuntimeConfig => {
    if (!hasConfig(window)) {
        throw new Error(`Invalid window given. (expected ${runtimeConfigKeyName} property)`);
    }
    const runtimeConfig = window[runtimeConfigKeyName];
    if (typeof runtimeConfig !== 'object' || runtimeConfig === null) {
        throw new Error(`invalid ${runtimeConfigKeyName} (expected object)`);
    }
    return runtimeConfig as RuntimeConfig;
};
