import { InfoListItem } from '@brightlayer-ui/react-components';
import * as Colors from '@brightlayer-ui/colors';
import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import Settings from '@mui/icons-material/Settings';
import { Styles } from './styles';
import React, { useRef, useState } from 'react';
import _ from 'lodash';

type UserListItemProps = {
    title: string;
    subtitle?: string;
    itemLabel: string;
    onLeaveClick?: () => void;
    onSettingsIconClick?: () => void;
    userMenuItems?: Array<{ text: string; onClick: () => void }>;
};

export const UserListItem: React.FC<UserListItemProps> = (props) => {
    const onLeaveClick = props.onLeaveClick;

    const userMenuAnchor = useRef<Element | null>(null);
    const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);

    const handleMenuClose = (): void => {
        userMenuAnchor.current = null;
        setUserMenuIsOpen(false);
    };

    return (
        <Box>
            <InfoListItem
                data-testid={`list-item`}
                sx={Styles.listItem}
                title={props.title}
                subtitle={props.subtitle}
                backgroundColor={Colors.white[50]}
                statusColor={Colors.blue[500]}
                hidePadding={true}
                divider={'full'}
                rightComponent={
                    <Box sx={Styles.rightComponent}>
                        {onLeaveClick && (
                            <Button
                                data-testid="leave-button"
                                sx={Styles.leaveButton}
                                variant="outlined"
                                color="primary"
                                onClick={(e): void => {
                                    e.stopPropagation();
                                    onLeaveClick();
                                }}
                            >
                                Leave
                            </Button>
                        )}
                        <Typography data-testid={'team-user-role'} variant="caption" sx={Styles.userRole}>
                            {props.itemLabel}
                        </Typography>
                        {props.userMenuItems && (
                            <>
                                <IconButton
                                    data-testid="edit-user-button"
                                    onClick={(e): void => {
                                        e.stopPropagation();
                                        userMenuAnchor.current = e.currentTarget;
                                        setUserMenuIsOpen(true);
                                    }}
                                >
                                    <Settings data-testid="edit-icon" sx={Styles.settingsIcon} />
                                </IconButton>

                                <Menu
                                    transitionDuration={{ exit: 0 }}
                                    data-testid="user-menu"
                                    open={userMenuIsOpen}
                                    anchorEl={userMenuAnchor.current}
                                    onClose={handleMenuClose}
                                >
                                    {props.userMenuItems.map((value, index) => (
                                        <MenuItem
                                            onClick={(): void => {
                                                setUserMenuIsOpen(false);
                                                value.onClick();
                                            }}
                                            key={`user-menu-item-${index}`}
                                            data-testid={`${_.kebabCase(value.text)}-menu-item`}
                                        >
                                            {value.text}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                        )}
                    </Box>
                }
            ></InfoListItem>
        </Box>
    );
};
