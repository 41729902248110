import { PageWrapper } from 'src/client/components/PageWrapper';
import { ScrollableNav } from 'src/client/components/ScrollableNav';
import source from 'src/client/markdown-content/markdown-pages/Permissions.md';

const PERMISSIONS_SECTIONS = [
    {
        id: 'applicationPermissions',
        title: 'Application Permissions',
    },
    {
        id: 'organizationPermissions',
        title: 'Organization Permissions',
    },
    {
        id: 'installerPermissions',
        title: 'Installer Permissions',
    },
];

export const PermissionsPage: React.FC = () => (
    <PageWrapper title="Permissions">
        <ScrollableNav sectionList={PERMISSIONS_SECTIONS} source={source} pageRoute="/advancedTopics/permissions" />
    </PageWrapper>
);
