import { SelectableDropdownListItem } from 'src/client/components/SelectableDropdownListItem';
import { MouseEventHandler, ReactElement } from 'react';

type ListItem = {
    title: string;
    subtitle?: string;
    icon?: ReactElement;
    onClick?: MouseEventHandler<HTMLLIElement>;
    rightComponent?: ReactElement;
    statusColor?: string;
};

type SelectableDropdownListProps = {
    id?: string;
    testId?: string;
    listItems: ListItem[];
};

export const SelectableDropdownList: React.FC<SelectableDropdownListProps> = (props) => (
    <div id={props.id} data-testid={props.testId}>
        {props.listItems.map((value, index) => (
            <SelectableDropdownListItem
                data-testid="list-item"
                key={`list-item-${index}`}
                title={value.title}
                subtitle={value.subtitle}
                onClick={value.onClick}
                rightComponent={value.rightComponent}
                icon={value.icon}
                statusColor={value.statusColor}
            />
        ))}
    </div>
);
