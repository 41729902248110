import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RemoveTeamFromAppConfirmationModal } from '../RemoveTeamFromAppConfirmationModal';
import { UserListItem } from '../UserListItem';
import { UserAbilities } from 'src/client/types/user-abilities';
import { ChangeTeamRoleConfirmationModal } from '../ChangeTeamRoleConfirmationModal';
import { ApplicationRole } from 'src/shared/types/developer-portal-roles';

enum ModalKind {
    EMPTY,
    REMOVE_FROM_APP,
    CHANGE_TEAM_ROLE,
}

type Application = {
    name: string;
    id: string;
};

type Team = {
    name: string;
    teamId: string;
    role: ApplicationRole;
};

type TeamsListItemContainerProps = {
    app: Application;
    team: Team;
    isLastAdminTeam: boolean;
    isOwnerIndividualTeam: boolean;
    loggedInUserAbilities: UserAbilities;
    isUserLosingAdminAccess: boolean;
    requeryAppDetails: () => void;
};

const getOtherRole = (currentRole: ApplicationRole): ApplicationRole =>
    currentRole === ApplicationRole.Admin ? ApplicationRole.Developer : ApplicationRole.Admin;

const displayModal = (args: {
    modalKind: ModalKind;
    clearModalKind: () => void;
    historyPush: (route: string) => void;
    app: Application;
    team: Team;
    isOwnerIndividualTeam: boolean;
    isLastExistingAdmin: boolean;
    isUserLosingAdminAccess: boolean;
    requeryAppDetails: () => void;
}): JSX.Element | void => {
    switch (args.modalKind) {
        case ModalKind.REMOVE_FROM_APP:
            return (
                <RemoveTeamFromAppConfirmationModal
                    app={args.app}
                    team={args.team}
                    onClose={args.clearModalKind}
                    onSuccess={(): void => {
                        args.historyPush(`/applications/${args.app.id}`);
                        args.requeryAppDetails();
                    }}
                    isLastExistingAdmin={args.isLastExistingAdmin}
                />
            );
        case ModalKind.CHANGE_TEAM_ROLE:
            return (
                <ChangeTeamRoleConfirmationModal
                    isOwnerIndividualTeam={args.isOwnerIndividualTeam}
                    application={args.app}
                    targetTeamId={args.team.teamId}
                    newRole={getOtherRole(args.team.role)}
                    targetDisplayName={args.team.name}
                    onClose={args.clearModalKind}
                    onSuccess={(): void => {
                        args.historyPush(`/applications/${args.app.id}`);
                        args.requeryAppDetails();
                    }}
                    isLastExistingAdmin={args.isLastExistingAdmin}
                    isUserLosingAdminAccess={args.isUserLosingAdminAccess}
                />
            );
        default:
            return;
    }
};

export const TeamsListItemContainer: React.FC<TeamsListItemContainerProps> = (props) => {
    const navigate = useNavigate();
    const [currentModalToDisplay, setCurrentModalToDisplay] = useState<ModalKind>(ModalKind.EMPTY);

    const clearModalKind = useCallback((): void => {
        setCurrentModalToDisplay(ModalKind.EMPTY);
    }, [setCurrentModalToDisplay]);

    const canEdit = props.loggedInUserAbilities.includes('Edit');
    const getMenuItems = (): any[] | undefined => {
        const menuItems = [];
        if (canEdit) {
            menuItems.push({
                text: props.team.role === ApplicationRole.Admin ? 'Make Developer' : 'Make Admin',
                onClick: (): void => {
                    setCurrentModalToDisplay(ModalKind.CHANGE_TEAM_ROLE);
                },
            });
            menuItems.push({
                text: 'Remove Team',
                onClick: (): void => {
                    setCurrentModalToDisplay(ModalKind.REMOVE_FROM_APP);
                },
            });
            return menuItems;
        }
        return undefined;
    };

    return (
        <>
            <UserListItem
                title={props.team.name}
                subtitle={props.isOwnerIndividualTeam ? `•\xa0\xa0You` : ''}
                itemLabel={props.team.role}
                userMenuItems={getMenuItems()}
            />
            {displayModal({
                modalKind: currentModalToDisplay,
                clearModalKind,
                app: props.app,
                isOwnerIndividualTeam: props.isOwnerIndividualTeam,
                historyPush: navigate,
                team: props.team,
                isLastExistingAdmin: props.isLastAdminTeam,
                requeryAppDetails: props.requeryAppDetails,
                isUserLosingAdminAccess: props.isUserLosingAdminAccess,
            })}
        </>
    );
};
