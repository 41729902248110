import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { EM_API_SUBSCRIPTION_KEY } from 'src/client/constants/tutorial';
import { TutorialExpandable, TutorialStepProps } from '../TutorialExpandable';
import { Styles } from './styles';

export const TutorialIntro: React.FC<TutorialStepProps> = React.memo((props) => (
    <TutorialExpandable
        {...props}
        title="Application API Key"
        description={
            <Box component="div" sx={Styles.description}>
                <Typography variant="body1">
                    All API requests require an Application API Key to be provided in the{' '}
                    <code style={Styles.codeFormat}>Em-Api-Subscription-Key</code> header. This key can be found by
                    selecting the Application you wish to communicate with the API on the{' '}
                    <Link to="/applications" target="_blank">
                        My Apps
                    </Link>{' '}
                    page.
                </Typography>
                <br />
                <Typography>
                    Throughout the tutorial you will see this fake API key used in the{' '}
                    <code style={Styles.codeFormat}>Em-Api-Subscription-Key</code> header of every simulated request.
                </Typography>
            </Box>
        }
        valueField={
            <Typography sx={Styles.valueField} variant="body1">
                <strong>API Key:</strong> <code style={Styles.codeFormat}>{EM_API_SUBSCRIPTION_KEY}</code>
            </Typography>
        }
    />
));
