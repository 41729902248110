import { Box, Typography } from '@mui/material';
import React from 'react';
import { TutorialExpandable, TutorialStepProps } from '../TutorialExpandable';
import { Styles } from './styles';

export const TutorialCommissionDevice: React.FC<TutorialStepProps> = React.memo((props) => (
    <TutorialExpandable
        {...props}
        title="Commission a Device"
        description={
            <>
                <Typography variant="body1" sx={Styles.description}>
                    An <strong>Installer</strong> commissions an Eaton Smart Breaker at an{' '}
                    <strong>Equipment Location</strong> on behalf of an Organization using the EM Install application
                    (available on the{' '}
                    <a
                        href="https://apps.apple.com/us/app/eaton-em-install/id1530627437"
                        target="_blank"
                        rel="noreferrer"
                    >
                        App Store®
                    </a>{' '}
                    and on{' '}
                    <a
                        href="https://play.google.com/store/apps/details?id=com.eaton.eminstall"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Google Play
                    </a>
                    ).
                </Typography>
                <br />
                <Typography variant="body1" sx={Styles.description}>
                    After the Smart Breaker is commissioned, it is accessible via the APIs to the{' '}
                    <strong>Organization</strong>.
                </Typography>
                <br />
                <Typography variant="body1" sx={Styles.description}>
                    <strong>NOTE:</strong> An installer has no API access to the Smart Breaker post-commissioning.
                </Typography>
            </>
        }
        valueField={<Box component="div" sx={Styles.values}></Box>}
    />
));
