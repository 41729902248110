import * as Types from 'server/api/types';
import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';

export const userPasswordChange = (
    currentPassword: string,
    newPassword: string,
    userId: string
): Promise<RequestHelperResult<unknown>> => {
    const requestBody: Types.SetUserPasswordRequestBody = {
        currentPassword: currentPassword,
        newPassword: newPassword,
        userId: userId,
    };

    return axiosRequestHelper('POST', '/api/users/password', {
        data: requestBody,
    });
};
