import { DialogContentText, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import _ from 'lodash';

import Group from '@mui/icons-material/Group';
import ErrorIcon from '@mui/icons-material/Error';
import { Team } from '..';
import { ApplicationRole } from 'src/shared/types/developer-portal-roles';
import { Styles } from './styles';

type AddTeamsSuccessProps = {
    teamsResult: {
        teamsAdded: Array<{ teamId: string; role: ApplicationRole }>;
        teamsFailed: Array<{ teamId: string; errorMessage: string }>;
    };
    teamsList: Team[];
};

// Future improvement would be to combine the display logic of this component
// and the AddMemberSuccess component. Both display lists with some content
// based on success/failure.
export const AddTeamsSuccess: React.FC<AddTeamsSuccessProps> = (props) => {
    const addedTeams = _.reduce(
        props.teamsResult.teamsAdded,
        (prev, curr): Team[] => {
            const existingTeam = props.teamsList.find((team) => team.id === curr.teamId);
            if (existingTeam) {
                prev.push({ ...existingTeam, role: curr.role });
            }
            return prev;
        },
        [] as Team[]
    );

    const failedTeams = _.reduce(
        props.teamsResult.teamsFailed,
        (prev, curr): Array<Team & { errorMessage: string }> => {
            const existingTeam = props.teamsList.find((team) => team.id === curr.teamId);
            if (existingTeam) {
                prev.push({ ...existingTeam, errorMessage: curr.errorMessage });
            }
            return prev;
        },
        [] as Array<Team & { errorMessage: string }>
    );

    return (
        <>
            {addedTeams.length > 0 && (
                <div>
                    <Typography variant="h5" data-testid="header-text">
                        Success! {addedTeams.length} Team{addedTeams.length !== 1 && 's'}{' '}
                        {addedTeams.length !== 1 ? 'have' : 'has'} been given access to your application.
                    </Typography>
                    <List dense sx={Styles.scrollableContainer} data-testid="success-list">
                        {_.sortBy(addedTeams, 'name').map((team, index) => (
                            <ListItem key={index} data-testid="success-list-item">
                                <ListItemIcon>
                                    <Group />
                                </ListItemIcon>
                                <ListItemText
                                    primary={`${team.name} (${team.role ? `${team.role}` : ApplicationRole.Developer})`}
                                    secondary={`${team.userCount} Team Member${team.userCount !== 1 ? 's' : ''}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                </div>
            )}
            {failedTeams.length > 0 && (
                <>
                    {addedTeams.length > 0 && <Divider sx={Styles.divider} />}
                    <DialogContentText variant="subtitle2" sx={Styles.errorTextField}>
                        {failedTeams.length} Team{failedTeams.length !== 1 && 's'} failed to be added:
                    </DialogContentText>
                    <List dense sx={Styles.scrollableContainer}>
                        {_.sortBy(failedTeams, 'name').map((failure, index) => (
                            <ListItem
                                key={index}
                                data-testid={`failure-item-${index}`}
                                sx={{ ...Styles.errorTextField, ...Styles.errorListItem }}
                            >
                                <ListItemIcon>
                                    <ErrorIcon sx={Styles.errorTextField} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={failure.name}
                                    primaryTypographyProps={{ variant: 'subtitle2' }}
                                    secondary={failure.errorMessage}
                                    secondaryTypographyProps={{ sx: Styles.errorTextField }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </>
            )}
        </>
    );
};
