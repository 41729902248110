/* eslint-disable react/no-children-prop */
import { Box } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { getComponentsForPage } from 'src/client/markdown-content/markdown-to-component/component-mapping';
import { DisplayError } from '../DisplayError';
import { Styles } from './styles';

type MarkdownProps = {
    source: string;
    pageRoute: string;
};

export const MarkdownContent = (props: MarkdownProps): JSX.Element => {
    const [postMarkdown, setPostMarkdown] = useState('');

    useEffect(() => {
        try {
            if (!new RegExp(/^.*.(md)$/).test(props.source)) {
                throw new Error();
            }
            void fetch(props.source)
                .then((response) => response.text())
                .then((text) => {
                    setPostMarkdown(text);
                });
        } catch (e) {
            setPostMarkdown('error');
        }
    }, [props.source]);

    return postMarkdown === 'error' ? (
        <Box sx={Styles.errorDisplay}>
            <DisplayError errorMessage={`Failed to load markdown for ${props.source}`} />
        </Box>
    ) : (
        <div>
            <ReactMarkdown
                children={postMarkdown}
                components={getComponentsForPage(props.pageRoute)}
                remarkPlugins={[remarkGfm]}
            />
        </div>
    );
};
