import { Box, Typography } from '@mui/material';
import React from 'react';
import { SimpleModal } from '../SimpleModal';
import { Styles } from './styles';

type ConfirmationModalProps = {
    modalTitle: string;
    submitActionButtonOnClick: () => Promise<void>;
    cancelActionButtonOnClick?: () => void;
    submitActionButtonText: string;
    cancelActionButtonText?: string;
    onClose: () => void;
    submitActionButtonDisabled?: boolean;
    errorText: string;
    allowRefreshOnError?: boolean;
};

export const GenericConfirmationModal: React.FC<ConfirmationModalProps & React.PropsWithChildren> = (props) => {
    const getActionButtonDisabled = (): boolean =>
        props.submitActionButtonDisabled || (!!props.errorText && !props.allowRefreshOnError);

    return (
        <SimpleModal
            isOpen={true}
            modalTitle={props.modalTitle}
            submitActionButtonText={props.submitActionButtonText}
            submitActionButtonDisabled={getActionButtonDisabled()}
            cancelActionButtonText={props.cancelActionButtonText}
            submitActionButtonOnClick={props.submitActionButtonOnClick}
            cancelActionButtonOnClick={props.cancelActionButtonOnClick}
            onClose={props.onClose}
        >
            <>
                {props.errorText ? (
                    <Typography sx={Styles.errorInformationText}> {props.errorText}</Typography>
                ) : (
                    <Box sx={Styles.modalTextContainer} data-testid="modal-text-container">
                        {props.children}
                    </Box>
                )}
            </>
        </SimpleModal>
    );
};
