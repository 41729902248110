import { FormControlLabel, FormGroup, FormHelperText, Switch, TextField } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Departments } from 'src/client/types/enums';
import { Validator } from '../../util/validator';
import { BasicDropdown } from '../BasicDropdown';
import { Styles } from './styles';
import {
    WorkflowCard,
    WorkflowCardHeader,
    WorkflowCardBody,
    WorkflowCardInstructions,
    WorkflowCardActions,
    useRegistrationContext,
    useRegistrationWorkflowContext,
} from '@brightlayer-ui/react-auth-workflow';

export const RegistrationForm: React.FC = () => {
    const { actions } = useRegistrationContext();
    const { nextScreen, previousScreen, currentScreen, totalScreens, screenData } = useRegistrationWorkflowContext();
    const [canGoNext, setCanGoNext] = useState(false);
    const initialDetails = screenData.AccountDetails;

    const [firstName, setFirstName] = useState<string>(initialDetails.firstName || '');
    const firstNameRef = useRef<any>(null);

    const [lastName, setLastName] = useState<string>(initialDetails.lastName || '');
    const lastNameRef = useRef<any>(null);

    const [phone, setPhone] = useState<string>(
        initialDetails.extra?.phone ? initialDetails.extra.phone.toString() : ''
    );
    const phoneRef = useRef<any>(null);

    const [companyName, setCompanyName] = useState<string | undefined>(
        initialDetails.extra?.companyName ? initialDetails.extra.companyName.toString() : ''
    );
    const companyNameRef = useRef<any>(null);

    const [jobTitle, setJobTitle] = useState<string | undefined>(
        initialDetails.extra?.jobTitle ? initialDetails.extra.jobTitle.toString() : ''
    );
    const jobTitleRef = useRef<any>(null);

    const [department, setDepartment] = useState<string | undefined>(
        initialDetails.extra?.department ? initialDetails.extra.department.toString() : ''
    );
    const departmentRef = useRef<any>(null);

    const [optedOutOfEmailNotifications, setOptedOutOfEmailNotifications] = useState(false);

    useEffect((): void => {
        const details = {
            firstName,
            lastName,
            phone,
            companyName,
            jobTitle,
            department,
        };
        const validation = Validator.validateSchema(details, {
            firstName: {
                type: 'string',
                presence: {
                    allowEmpty: false,
                },
            },
            lastName: {
                type: 'string',
                presence: {
                    allowEmpty: false,
                },
            },
            phone: {
                type: 'string',
                length: { maximum: 30 },
            },
            companyName: {
                type: 'string',
                presence: {
                    allowEmpty: false,
                },
                length: { maximum: 255 },
            },
            jobTitle: {
                type: 'string',
                presence: {
                    allowEmpty: false,
                },
                length: { maximum: 255 },
            },
            department: {
                type: 'string',
                presence: {
                    allowEmpty: false,
                },
                inclusion: Object.values(Departments),
            },
        });

        setCanGoNext(!validation);
    }, [firstName, lastName, phone, companyName, jobTitle, department, optedOutOfEmailNotifications]);

    const onNext = useCallback(async (): Promise<void> => {
        const extra = {
            phone,
            jobTitle: jobTitle || '',
            department: department || '',
            companyName: companyName || '',
            optedOutOfEmailNotifications,
        };
        await actions?.setAccountDetails?.({
            firstName,
            lastName,
            extra,
        });
        void nextScreen({
            screenId: 'AccountDetails',
            values: { firstName, lastName, extra },
        });
    }, [
        actions,
        firstName,
        lastName,
        phone,
        jobTitle,
        department,
        companyName,
        optedOutOfEmailNotifications,
        nextScreen,
    ]);

    const onPrevious = useCallback(() => {
        const extra = {
            phone,
            jobTitle: jobTitle || '',
            department: department || '',
            companyName: companyName || '',
            optedOutOfEmailNotifications,
        };
        previousScreen({
            screenId: 'AccountDetails',
            values: { firstName, lastName, extra },
        });
    }, [firstName, lastName, phone, jobTitle, department, companyName, optedOutOfEmailNotifications, previousScreen]);

    return (
        <WorkflowCard>
            <WorkflowCardHeader title="Account Details" />
            <WorkflowCardInstructions instructions="Enter your details below to complete account creation." />
            <WorkflowCardBody>
                <TextField
                    id="first"
                    fullWidth
                    variant="filled"
                    inputRef={firstNameRef}
                    label="First Name"
                    value={firstName}
                    sx={Styles.formField}
                    onChange={(e): void => {
                        setFirstName(e.target.value);
                    }}
                    onKeyUp={(e): void => {
                        if (e.key === 'Enter' && lastNameRef.current) lastNameRef.current.focus();
                    }}
                />
                <TextField
                    id="last"
                    fullWidth
                    variant="filled"
                    sx={Styles.formField}
                    inputRef={lastNameRef}
                    label="Last Name"
                    value={lastName}
                    onChange={(e): void => {
                        setLastName(e.target.value);
                    }}
                    onKeyUp={(e): void => {
                        if (e.key === 'Enter' && phoneRef.current) phoneRef.current.focus();
                    }}
                />
                <TextField
                    id="user-registration-phone-number"
                    fullWidth
                    variant={'filled'}
                    inputRef={phoneRef}
                    label={'Phone Number (Optional)'}
                    value={phone}
                    placeholder={'Enter your Phone Number'}
                    onChange={(evt): void => {
                        if (evt.target.value.length > 30) {
                            return;
                        }
                        setPhone(evt.target.value);
                    }}
                    onKeyUp={(e): void => {
                        if (e.key === 'Enter' && phoneRef.current) companyNameRef.current.focus();
                    }}
                    sx={Styles.formField}
                />

                <TextField
                    id="user-registration-company-name"
                    fullWidth
                    variant={'filled'}
                    inputRef={companyNameRef}
                    label={'Company Name'}
                    value={companyName}
                    onChange={(evt): void => setCompanyName(evt.target.value)}
                    onKeyUp={(e): void => {
                        if (e.key === 'Enter' && companyNameRef.current) jobTitleRef.current.focus();
                    }}
                    sx={Styles.formField}
                />

                <TextField
                    id="user-registration-job-title"
                    fullWidth
                    variant={'filled'}
                    inputRef={jobTitleRef}
                    label={'Job Title'}
                    value={jobTitle}
                    onChange={(evt): void => setJobTitle(evt.target.value)}
                    onKeyUp={(e): void => {
                        if (e.key === 'Enter' && departmentRef.current) departmentRef.current.focus();
                    }}
                    sx={Styles.formField}
                />

                <BasicDropdown
                    value={department}
                    items={Object.values(Departments)}
                    label="Department/Division"
                    onChange={setDepartment}
                    sx={Styles.formField}
                    idPrefix="user-registration-department"
                    inputRef={departmentRef}
                />

                <FormGroup sx={Styles.formField}>
                    <FormControlLabel
                        control={
                            <Switch
                                defaultChecked={!optedOutOfEmailNotifications}
                                onChange={() => {
                                    setOptedOutOfEmailNotifications(!optedOutOfEmailNotifications);
                                }}
                                color="primary"
                            />
                        }
                        label="Receive Email Notifications"
                    />
                    <FormHelperText>
                        If selected, you will receive emails from the EM product team about new releases and any planned
                        outages of the API.
                    </FormHelperText>
                </FormGroup>
            </WorkflowCardBody>
            <WorkflowCardActions
                canGoNext={canGoNext}
                showNext
                showPrevious
                nextLabel="Next"
                previousLabel="Back"
                totalSteps={totalScreens}
                currentStep={currentScreen}
                onNext={() => void onNext()}
                onPrevious={() => void onPrevious()}
            />
        </WorkflowCard>
    );
};
