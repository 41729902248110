import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { UsersListHeader } from '../UsersListHeader';
import { AddTeamsModal } from '../AddTeamsModal';
import { UserAbilities } from 'src/client/types/user-abilities';
import { TeamsListItemContainer } from '../TeamsListItemContainer';
import { ApplicationRole } from 'src/shared/types/developer-portal-roles';
import { Box } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import { generateSxProps } from '../../helpers/generate-sx-props';
import { useApp } from 'src/client/contexts/AppContextProvider';

type Team = {
    teamId: string;
    name: string;
    role: ApplicationRole;
    individualTeamUserId?: string;
};
type Application = {
    name: string;
    id: string;
};

type TeamsListProps = {
    teams: Team[];
    application: Application;
    userAbilities: UserAbilities;
    sx?: SxProps;
    requeryAppDetails: () => void;
    title?: string;
    teamIdsForUser: string[];
};

export const TeamsList: React.FC<TeamsListProps> = (props) => {
    const app = useApp();

    const [showAddTeams, setShowAddTeams] = useState<boolean>(false);

    const sorted = _.sortBy(props.teams, 'name');

    const adminTeams = sorted.filter((team) => team.role === ApplicationRole.Admin);

    const onAddTeamsClicked = (): void => setShowAddTeams(true);

    const onAddTeamsClosed = (): void => {
        setShowAddTeams(false);
    };

    const onComplete = (): void => {
        props.requeryAppDetails();
    };

    const buttonText = props.userAbilities.includes('Edit') ? '+ Add Team' : undefined;
    const buttonAction = props.userAbilities.includes('Edit') ? onAddTeamsClicked : undefined;

    const isOnlyAdmin = (teamId: string): boolean =>
        !sorted.some((team) => team.role === ApplicationRole.Admin && team.teamId !== teamId);

    const isOwnerIndividualTeam = (individualTeamUserId: string | undefined): boolean =>
        !!(individualTeamUserId && app.loginData.userId === individualTeamUserId);

    const isOnlyAdminTeamForUser = useCallback(
        (teamId: string, role: 'Admin' | 'Developer'): boolean => {
            if (role === 'Developer') return false;
            const otherAdminTeams = adminTeams.filter((team) =>
                props.teamIdsForUser.find((id) => id === team.teamId && team.teamId !== teamId)
            );

            const remainingIndividualTeam = adminTeams.find(
                (team) => team.individualTeamUserId === app.loginData.userId && team.individualTeamUserId !== teamId
            );

            if (remainingIndividualTeam) return false;

            if (otherAdminTeams.length > 0) return false;
            return true;
        },
        [adminTeams, props.teamIdsForUser, app.loginData.userId]
    );

    return (
        <Box data-testid="teams-list" sx={generateSxProps(props.sx)}>
            <UsersListHeader
                title={props.title}
                teamCount={props.teams.length}
                buttonText={buttonText}
                buttonOnClick={buttonAction}
            />
            {sorted.map((value, index) => (
                <TeamsListItemContainer
                    app={props.application}
                    team={value}
                    key={index}
                    isOwnerIndividualTeam={isOwnerIndividualTeam(value.individualTeamUserId)}
                    loggedInUserAbilities={props.userAbilities}
                    isLastAdminTeam={isOnlyAdmin(value.teamId)}
                    requeryAppDetails={props.requeryAppDetails}
                    isUserLosingAdminAccess={isOnlyAdminTeamForUser(value.teamId, value.role)}
                />
            ))}
            {showAddTeams && (
                <AddTeamsModal
                    applicationId={props.application.id}
                    onClose={onAddTeamsClosed}
                    onComplete={onComplete}
                    appExistingTeamIds={props.teams.map((team) => team.teamId)}
                />
            )}
        </Box>
    );
};
