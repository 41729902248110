export const Styles = {
    actionsContainer: { display: 'flex', justifyContent: 'space-between' },
    singleButtonActions: { display: 'flex', alignSelf: 'flex-end' },
    button: { margin: '12px' },
    contentContainer: { marginBottom: '20px' },
    circularProgress: {
        color: 'white',
    },
    simpleModalFieldset: {
        border: '0px',
    },
};
