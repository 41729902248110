import { CardContent, CardHeader, Typography } from '@mui/material';
import { SchemaValidationResult } from 'src/client/util/validator';
import { AccountEdit } from '../../AccountInfo/AccountEdit';
import { Styles } from '../styles';

type AccountInformation = {
    email: string;
    firstName: string;
    lastName: string;
    optedOutOfEmailNotifications: boolean;
    phoneNumber?: string;
    companyName?: string;
    jobTitle?: string;
    department?: string;
};

export const AccountContent: React.FC<{
    accountInfo: AccountInformation;
    setAccountInfo: (accountInfo: AccountInformation) => void;
    validationErrors: SchemaValidationResult<Omit<AccountInformation, 'email'>>;
    clearValidationErrors: () => void;
}> = (props): JSX.Element => (
    <>
        <CardHeader
            data-testid="card-header"
            title={
                <Typography variant={'h6'} sx={Styles.heavyFont}>
                    Update Your Account Details
                </Typography>
            }
            sx={Styles.dialogTitle}
        />
        <CardContent sx={Styles.dialogContent}>
            <AccountEdit
                accountData={props.accountInfo}
                setAccountData={(data): void => props.setAccountInfo(data)}
                validationErrors={props.validationErrors}
                clearValidationErrors={props.clearValidationErrors}
            />
        </CardContent>
    </>
);
