import { useState } from 'react';
import { AppContext, LoginData } from './contexts/AppContextProvider';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from './router/AppRouter';

export const App = (): JSX.Element => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loginData, setLoginData] = useState<LoginData>({
        email: '',
        rememberMe: false,
        userId: '',
    });
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);

    return (
        <AppContext.Provider
            value={{
                isAuthenticated,
                onUserAuthenticated: (userData) => {
                    setIsAuthenticated(true);
                    setLoginData(userData);
                },
                onUserNotAuthenticated: () => {
                    setIsAuthenticated(false);
                },
                loginData,
                setLoginData,
                setShowChangePasswordDialog,
                showChangePasswordDialog,
            }}
        >
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
        </AppContext.Provider>
    );
};
