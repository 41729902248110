import { Box, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiClient } from 'src/client/api-client';
import { Validator } from 'src/client/util/validator';
import { Spinner } from '../Spinner';
import { Styles } from './styles';

export const TeamCreate: React.FC = () => {
    const navigate = useNavigate();

    const [teamInFlight, setTeamInFlight] = useState<boolean>(false);
    const [teamName, setTeamName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const navigateToTeamDetails = (route: string): void => {
        navigate(route);
    };

    const submitTeam = async (): Promise<void> => {
        const validation = Validator.validateSchema(
            { teamName },
            {
                teamName: {
                    type: 'string',
                    length: { maximum: 255 },
                    presence: {
                        allowEmpty: false,
                    },
                },
            }
        );

        if (!validation) {
            setTeamInFlight(true);
            const result = await ApiClient.createTeam({ name: teamName });

            if (!result.success) {
                setTeamInFlight(false);
                setErrorMessage(result.error || 'Unknown Error occured, Failed to create team.');
                return;
            }
            setTeamInFlight(false);
            navigateToTeamDetails(`/teams/${result.data.id}`);
            return;
        }
        if (validation.teamName && validation.teamName?.length > 0)
            setErrorMessage(validation.teamName[0] || 'Unknown Error');
    };

    return (
        <Box sx={Styles.body}>
            <Typography sx={Styles.bodyHeader} variant={'h4'}>
                Create New Team
            </Typography>
            {teamInFlight ? (
                <Spinner />
            ) : (
                <Box sx={Styles.teamNameForm}>
                    <TextField
                        variant="filled"
                        id={'team-name-text-box'}
                        sx={Styles.textField}
                        label={'Team Name'}
                        helperText={errorMessage ? errorMessage : 'This will be the name of your team.'}
                        error={!!errorMessage}
                        FormHelperTextProps={{ sx: Styles.formHelperText }}
                        onChange={(event): void => {
                            if (errorMessage) {
                                setErrorMessage(undefined);
                            }
                            setTeamName(event.target.value);
                        }}
                    />
                    <Button
                        sx={Styles.submitButton}
                        variant="contained"
                        color="primary"
                        id={'create-team-submit-button'}
                        onClick={(): void => {
                            void submitTeam();
                        }}
                        disabled={!teamName || !!errorMessage}
                    >
                        Submit
                    </Button>
                </Box>
            )}
        </Box>
    );
};
