import { RouteConfig } from '@brightlayer-ui/react-auth-workflow';

export const routes: RouteConfig = {
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    REGISTER_INVITE: '/register/invite',
    REGISTER_SELF: '/register/create-account',
    SUPPORT: '/support',
};
