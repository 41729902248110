import { Box, Typography } from '@mui/material';
import { Styles } from './styles';

type TextAndTitleProps = {
    id: string;
    title: string;
    content: string;
};

export const GenericTextAndTitle = (props: TextAndTitleProps): JSX.Element => (
    <Box id={`${props.id}-container`} sx={Styles.textAndTitle}>
        <Typography data-testid="generic-text-and-title" id={props.id} variant={'caption'} sx={Styles.title}>
            {props.title}
        </Typography>
        <Typography
            data-testid="generic-text-and-content"
            aria-labelledby={props.id}
            variant={'body1'}
            sx={Styles.content}
        >
            {props.content}
        </Typography>
    </Box>
);
