import React, { createContext, useContext, useEffect, useState } from 'react';
import { ApiClient } from '../api-client';

type NotificationColor = 'yellow' | 'green' | 'blue' | 'red';
type NotificationContextType =
    | {
          notificationOpen: true;
          displayText: string;
          color: NotificationColor;
          dismissNotification: () => void;
      }
    | { notificationOpen: false };

export const NotificationContext = createContext<NotificationContextType | null>(null);

export const NotificationContextProvider: React.FC<React.PropsWithChildren> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const [displayText, setDisplayText] = useState<string>();
    const [color, setColor] = useState<NotificationColor>();

    const fetchNotification = async (): Promise<void> => {
        const result = await ApiClient.getCurrentNotification();
        if (result.success) {
            setDisplayText(result.data.displayText);
            setColor(result.data.color);
            setIsOpen(true);
        }
    };

    useEffect(() => void fetchNotification(), []);

    return (
        <NotificationContext.Provider
            value={
                isOpen && displayText && color
                    ? {
                          notificationOpen: true,
                          displayText,
                          color,
                          dismissNotification: () => setDisplayText(undefined),
                      }
                    : { notificationOpen: false }
            }
        >
            {props.children}
        </NotificationContext.Provider>
    );
};

export const useNotification = (): NotificationContextType => {
    const context = useContext(NotificationContext);
    if (context === null) {
        throw new Error('useNotification must be used within a NotificationContextProvider');
    }
    return context;
};
