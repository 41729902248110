export const Styles = {
    spinner: {
        display: 'flex',
        top: '40%',
        left: '40%',
        position: 'absolute',
    },
    error: {
        display: 'flex',
        top: '30%',
        position: 'absolute',
    },
};
