import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SxProps } from '@mui/material';
import _ from 'lodash';
import { Ref } from 'react';

type DropdownProps = {
    label: string;
    value: string | undefined;
    onChange: (value: string) => void;
    items: string[];
    disabled?: boolean;
    idPrefix?: string;
    sx?: SxProps;
    inputRef?: Ref<any>;
    error?: boolean;
    helperText?: string[];
};

export const BasicDropdown = (props: DropdownProps): JSX.Element => {
    const makeId = (str: string): string => `${props.idPrefix ?? _.kebabCase(props.label)}-${str}`;
    return (
        <FormControl
            data-testid="basic-dropdown"
            variant="filled"
            fullWidth
            sx={props.sx}
            disabled={props.disabled}
            error={props.error}
        >
            <InputLabel id={makeId('label')} error={props.error}>
                {props.label}
            </InputLabel>
            <Select
                labelId={makeId('label')}
                data-testid={makeId('select')}
                value={props.value}
                onChange={(e): void => props.onChange(e.target.value)}
                inputRef={props.inputRef}
                error={props.error}
            >
                {props.items.map((value) => (
                    <MenuItem
                        id={makeId(`menu-item-${_.kebabCase(value)}`)}
                        key={makeId(`menu-item-${_.kebabCase(value)}`)}
                        value={value}
                    >
                        {value}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{props.helperText?.join(' ')}</FormHelperText>
        </FormControl>
    );
};
