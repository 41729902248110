import { PromiseType } from '../types/general';
import { timeout } from './timeout';

/**
 * Add a hardcoded delay to a function that returns a Promise.
 * @param delayMillis the length of the delay in milliseconds
 * @param fn the async function
 * @returns a new function that waits the specified amount of time before calling the passed function
 */
export const withDelay =
    <Fn extends (...args: any[]) => Promise<any>, Result extends PromiseType<ReturnType<Fn>>>(
        delayMillis: number,
        fn: Fn
    ): ((...args: Parameters<Fn>) => Promise<Result>) =>
    (...args: Parameters<Fn>): Promise<Result> =>
        timeout(delayMillis).then(() => fn(...args));
