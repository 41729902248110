import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    list: {
        margin: '25px',
    },
    description: {
        marginLeft: '18px',
        marginRight: '18px',
    },
    values: {
        margin: '18px',
    },
    entryFields: {
        display: 'flex',
        flexDirection: 'column',
    },
    formField: {
        marginBottom: '16px',
    },
    dropdown: {
        paddingLeft: '12px',
        '& .MuiSelect-select:focus': {
            backgroundColor: Colors.white[50],
        },
    },
    dropdownWithMargin: {
        paddingLeft: '12px',
        '& .MuiSelect-select:focus': {
            backgroundColor: Colors.white[50],
        },
        marginBottom: '14px',
    },
    inputLabel: {
        paddingLeft: '12px',
        alignContent: 'center',
    },
};
