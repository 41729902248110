import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import { Styles } from './styles';

export const SubmitButton: React.FC<{
    id?: string;
    onClick: () => void;
    spinning: boolean;
    disabled?: boolean;
    buttonText: string;
    width?: number;
}> = (props) => {
    const styleProp = props.width ? { width: props.width } : {};

    return (
        <Button
            id={props.id}
            variant="contained"
            color="primary"
            sx={Styles.button}
            onClick={props.onClick}
            disabled={props.disabled || props.spinning}
            style={styleProp}
        >
            {props.spinning ? <CircularProgress size={24} variant={'indeterminate'} /> : props.buttonText}
        </Button>
    );
};
