export const Styles = {
    editContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
    },
    formField: {
        maxWidth: '400px',
        width: '100%',
        marginBottom: 2,
    },
    emailContainer: {
        display: 'flex',
        alignItems: 'baseline',
    },
    tooltip: { marginLeft: '17px' },
    notificationSwitch: { marginBottom: 2 },
};
