import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, FormControlLabel, FormGroup, FormHelperText, Switch, TextField, Tooltip } from '@mui/material';
import { Departments } from 'src/client/types/enums';
import { SchemaValidationResult } from 'src/client/util/validator';
import { BasicDropdown } from '../../BasicDropdown';
import { AccountData } from '../types';
import { Styles } from './styles';

type AccountEditProps = {
    accountData: AccountData;
    setAccountData: (data: AccountData) => void;
    validationErrors: SchemaValidationResult<Omit<AccountData, 'email'>> | undefined;
    clearValidationErrors: () => void;
    disabled?: boolean;
};

export const AccountEdit = (props: AccountEditProps): JSX.Element => (
    <Box sx={Styles.editContainer}>
        <form noValidate autoComplete="off">
            <Box sx={Styles.editContainer}>
                <TextField
                    id="first-name-info"
                    data-testid="first-name-info"
                    sx={Styles.formField}
                    variant="filled"
                    color="primary"
                    label="First Name"
                    value={props.accountData.firstName}
                    onChange={(event): void => {
                        if (props.validationErrors) {
                            props.clearValidationErrors();
                        }
                        props.setAccountData({ ...props.accountData, firstName: event.target.value });
                    }}
                    helperText={props.validationErrors?.firstName ?? undefined}
                    error={!!props.validationErrors?.firstName}
                    disabled={props.disabled}
                />
                <TextField
                    id="last-name-info"
                    data-testid="last-name-info"
                    sx={Styles.formField}
                    variant="filled"
                    color="primary"
                    label="Last Name"
                    value={props.accountData.lastName}
                    onChange={(event): void => {
                        if (props.validationErrors) {
                            props.clearValidationErrors();
                        }
                        props.setAccountData({ ...props.accountData, lastName: event.target.value });
                    }}
                    helperText={props.validationErrors?.lastName ?? undefined}
                    error={!!props.validationErrors?.lastName}
                    disabled={props.disabled}
                />
                <Box sx={Styles.emailContainer}>
                    <TextField
                        id="email-info"
                        data-testid="email-info"
                        sx={Styles.formField}
                        variant="filled"
                        color="primary"
                        label="Email"
                        disabled
                        value={props.accountData.email}
                    />
                    <Tooltip title="Email cannot be changed." sx={Styles.tooltip}>
                        <InfoOutlinedIcon aria-label="Email cannot be changed." />
                    </Tooltip>
                </Box>
                <TextField
                    id="phone-info"
                    data-testid="phone-info"
                    sx={Styles.formField}
                    variant="filled"
                    color="primary"
                    label="Phone Number (Optional)"
                    value={props.accountData.phoneNumber ?? ''}
                    onChange={(event): void => {
                        if (props.validationErrors) {
                            props.clearValidationErrors();
                        }
                        props.setAccountData({ ...props.accountData, phoneNumber: event.target.value });
                    }}
                    helperText={props.validationErrors?.phoneNumber ?? undefined}
                    error={!!props.validationErrors?.phoneNumber}
                    disabled={props.disabled}
                />
                <TextField
                    id="company-info"
                    data-testid="company-info"
                    sx={Styles.formField}
                    variant="filled"
                    color="primary"
                    label="Company Name"
                    value={props.accountData.companyName ?? ''}
                    onChange={(event): void => {
                        if (props.validationErrors) {
                            props.clearValidationErrors();
                        }
                        props.setAccountData({ ...props.accountData, companyName: event.target.value });
                    }}
                    helperText={props.validationErrors?.companyName ?? undefined}
                    error={!!props.validationErrors?.companyName}
                    disabled={props.disabled}
                />
                <TextField
                    id="job-title-info"
                    data-testid="job-title-info"
                    sx={Styles.formField}
                    variant="filled"
                    color="primary"
                    label="Job Title"
                    value={props.accountData.jobTitle ?? ''}
                    onChange={(event): void => {
                        if (props.validationErrors) {
                            props.clearValidationErrors();
                        }
                        props.setAccountData({ ...props.accountData, jobTitle: event.target.value });
                    }}
                    helperText={props.validationErrors?.jobTitle ?? undefined}
                    error={!!props.validationErrors?.jobTitle}
                    disabled={props.disabled}
                />
                <BasicDropdown
                    data-testid="department-info"
                    value={props.accountData.department ?? ''}
                    items={Object.values(Departments)}
                    label="Department / Division"
                    sx={Styles.formField}
                    idPrefix="edit-department"
                    onChange={(value): void => {
                        if (props.validationErrors) {
                            props.clearValidationErrors();
                        }
                        props.setAccountData({ ...props.accountData, department: value });
                    }}
                    disabled={props.disabled}
                    error={!!props.validationErrors?.department}
                    helperText={props.validationErrors?.department ?? undefined}
                />

                <FormGroup sx={Styles.notificationSwitch}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={!props.accountData.optedOutOfEmailNotifications}
                                onChange={(): void => {
                                    if (props.validationErrors) {
                                        props.clearValidationErrors();
                                    }
                                    props.setAccountData({
                                        ...props.accountData,
                                        optedOutOfEmailNotifications: !props.accountData.optedOutOfEmailNotifications,
                                    });
                                }}
                                color="primary"
                            />
                        }
                        label="Receive Email Notifications"
                    />
                    <FormHelperText>
                        If selected, you will receive emails from the EM product team about new releases and any planned
                        outages of the API.
                    </FormHelperText>
                </FormGroup>
            </Box>
        </form>
    </Box>
);
