import { DrawerLayout, Spacer } from '@brightlayer-ui/react-components';
import Menu from '@mui/icons-material/Menu';
import { AppBar, Button, Hidden, IconButton, Toolbar, Typography, SxProps, Theme, Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationDrawer } from 'src/client/components/NavigationDrawer/NavigationDrawer';
import { useDrawer } from 'src/client/contexts/DrawerContextProvider';
import { ProfileIcon } from 'src/client/icons/profile-icon';
import { NotificationBanner } from '../NotificationBanner';
import { Styles } from './styles';
import { useApp } from 'src/client/contexts/AppContextProvider';

type PageWrapperProps = {
    title: string;
    userProfile?: boolean;
    children?: React.ReactNode;
    sx?: SxProps<Theme>;
};

export const PageWrapper: React.FC<PageWrapperProps> = (props: PageWrapperProps) => {
    const { setDrawerOpen } = useDrawer();
    const navigate = useNavigate();
    const app = useApp();

    return (
        <React.StrictMode>
            <DrawerLayout drawer={<NavigationDrawer />}>
                <Box data-testid="page-content" sx={Styles.pageBackground}>
                    <AppBar position={'sticky'} sx={Styles.appBar}>
                        <Toolbar sx={props.userProfile ? Styles.accountInfoToolbar : Styles.toolbar}>
                            {props.userProfile && <ProfileIcon sx={Styles.accountProfile} />}
                            <Hidden mdUp={true}>
                                <IconButton
                                    color={'inherit'}
                                    onClick={(): void => {
                                        setDrawerOpen(true);
                                    }}
                                    edge={'start'}
                                >
                                    <Menu />
                                </IconButton>
                            </Hidden>
                            <Typography
                                sx={Styles.title}
                                variant={'h6'}
                                color={'inherit'}
                                data-testid="navigation-top-title"
                            >
                                {props.title}
                            </Typography>

                            <Spacer />

                            {!app.isAuthenticated && (
                                <Button
                                    sx={Styles.loginButton}
                                    onClick={(): void => navigate('/login')}
                                    data-testid="navigation-top-login-button"
                                >
                                    Login
                                </Button>
                            )}
                        </Toolbar>
                    </AppBar>

                    <NotificationBanner paddingTop={props.userProfile ? 30 : undefined} />
                    <Box sx={props.userProfile ? Styles.accountInfoContent : props.sx}>{props.children}</Box>
                </Box>
            </DrawerLayout>
        </React.StrictMode>
    );
};
