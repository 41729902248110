import { Avatar, Box } from '@mui/material';
import { Styles } from './styles';
import CheckIcon from '@mui/icons-material/Check';

type TutorialStepLabelProps = {
    visited: VisitedState;
    stepNumber: number;
};

export type VisitedState = 'Not Visited' | 'Current' | 'Visited';

export const TutorialStepLabel: React.FC<TutorialStepLabelProps> = (props) => {
    const getStyleFromVisited = (visited: VisitedState) =>
        visited === 'Current'
            ? Styles.stepNumberCurrent
            : visited === 'Not Visited'
            ? Styles.stepNumberNotVisited
            : Styles.stepNumberVisited;

    return (
        <Box sx={Styles.container}>
            <Avatar sx={{ ...Styles.root, ...Styles.stepNumberLayout, ...getStyleFromVisited(props.visited) }}>
                {props.stepNumber}
            </Avatar>
            {props.visited === 'Visited' && (
                <Avatar sx={Styles.iconPosition}>
                    <CheckIcon sx={Styles.checkIcon} fontSize="large" />
                </Avatar>
            )}
        </Box>
    );
};
