import {
    Box,
    Link as MuiLink,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import { ContentAnchor } from '../../components/ContentAnchor';
import { Styles } from './styles';

const MakeTypographyComponent = (
    props: any,
    header: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
    pageRoute?: string
): JSX.Element => (
    <Typography
        variant={header}
        data-testid={`${_.kebabCase(_.lowerCase(props.children))}-header`}
        {...props}
        sx={header !== 'h1' && Styles.subheader}
    >
        <ContentAnchor {...props} sx={Styles.anchor} id={_.camelCase(props.children)} page={pageRoute} />
    </Typography>
);

const TypographyWithContentAnchor =
    (pageRoute: string, headerSize: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6') =>
    ({ ...props }): JSX.Element =>
        MakeTypographyComponent(props, headerSize, pageRoute);

const TypographyBody1 = ({ ...props }): JSX.Element => <Typography variant="body1" {...props} sx={Styles.paragraph} />;

const Image = ({ ...props }): JSX.Element => <Box component="img" {...props} sx={Styles.image} alt="" />;

const PreBlock = ({ ...props }): JSX.Element => <Box component="pre" {...props} sx={Styles.codeBlock} />;

const MarkdownTable = ({ ...props }): JSX.Element => (
    <TableContainer component={Paper}>
        <Table size="small">{props.children}</Table>
    </TableContainer>
);

const MarkdownTableRow = ({ ...props }): JSX.Element => <TableRow sx={Styles.tableRow}>{props.children}</TableRow>;

const Blockquote = ({ ...props }): JSX.Element => <Box component="blockquote" {...props} sx={Styles.blockquote} />;

const Link = ({ ...props }): JSX.Element => <Link {...props} sx={Styles.link} />;

const Divider = ({ ...props }): JSX.Element => <Box component="hr" {...props} sx={Styles.divider} />;

const Code = ({ ...props }): JSX.Element => <Box component="code" {...props} sx={Styles.code} />;

const AHref = ({ ...props }): JSX.Element => {
    if (props.href.includes('http') || props.href.includes('https')) {
        return (
            <MuiLink role="link" target="_blank" {...props} sx={Styles.href}>
                {props.children}
            </MuiLink>
        );
    }

    return (
        <MuiLink role="link" {...props} sx={Styles.href}>
            {props.children}
        </MuiLink>
    );
};

export const getComponentsForPage = (pageRoute: string): any => ({
    h1: TypographyWithContentAnchor(pageRoute, 'h1'),
    h2: TypographyWithContentAnchor(pageRoute, 'h2'),
    h3: TypographyWithContentAnchor(pageRoute, 'h3'),
    h4: TypographyWithContentAnchor(pageRoute, 'h4'),
    h5: TypographyWithContentAnchor(pageRoute, 'h5'),
    h6: TypographyWithContentAnchor(pageRoute, 'h6'),
    img: Image,
    pre: PreBlock,
    blockquote: Blockquote,
    p: TypographyBody1,
    hr: Divider,
    code: Code,
    a: AHref,
    table: MarkdownTable,
    thead: TableHead,
    tbody: TableBody,
    tr: MarkdownTableRow,
    th: TableCell,
    td: TableCell,
});
