import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    root: {
        '& label.Mui-disabled': {
            color: Colors.black[500],
        },
        '& input.Mui-disabled': {
            color: Colors.black[500],
        },
        marginBottom: '16px',
    },
};
