import { Box, Button, Typography } from '@mui/material';
import Group from '@mui/icons-material/Group';
import { InfoListItem } from '@brightlayer-ui/react-components';
import * as Colors from '@brightlayer-ui/colors';
import _ from 'lodash';
import { Styles } from './styles';

type ListHeaderProps = {
    teamCount: number;
    buttonText?: string;
    buttonOnClick?: () => void;
    title?: string;
};

export const UsersListHeader: React.FC<ListHeaderProps> = (props) => (
    <Box sx={Styles.teamsBox} boxShadow={3}>
        <InfoListItem
            sx={Styles.bar}
            title={props.title || ''}
            data-testid="list-header"
            avatar={false}
            hidePadding={true}
            divider={'full'}
            statusColor={Colors.blue[500]}
            rightComponent={
                <Box sx={Styles.rightComponent}>
                    {props.buttonText && (
                        <Button
                            data-testid={`${_.kebabCase(props.buttonText)}-button`}
                            sx={Styles.button}
                            variant="outlined"
                            color="primary"
                            onClick={props.buttonOnClick}
                        >
                            {props.buttonText}
                        </Button>
                    )}
                    <Typography data-testid="user-count" sx={Styles.teamCount} variant="body1">
                        {props.teamCount}
                    </Typography>
                    <Group sx={Styles.groupIcon} />
                </Box>
            }
        ></InfoListItem>
    </Box>
);
