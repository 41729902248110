import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    copywriteText: {
        maxWidth: '46%',
        maxHeight: '60%',
        flexWrap: 'wrap',
        lineHeight: '16px',
    },
    drawerFooter: {
        background: Colors.white['300'],
    },
    drawerHeader: {
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: Colors.blue['500'],
        color: 'white',
        backgroundImage:
            'linear-gradient(rgba(0, 123, 193, 0.5), rgba(0, 123, 193, 0.5)), url(/images/title-overlay.png)',
    },
    eatonLogo: {
        width: 104,
        height: 28,
        maxWidth: '46%',
    },
    sideDrawerImageCopywriteFooter: {
        backgroundColor: Colors.white['50'],
        height: '110px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    navGroupTitle: {
        '& .MuiListItemText-root': {
            maxWidth: 'fit-content',
        },
    },
};
