import EmailIcon from '@mui/icons-material/Email';
import {
    Box,
    DialogContentText,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import { Styles } from './styles';

type AddMemberEditProps = {
    emails: string[];
    setEmails: (emails: string[]) => void;
    setError: (error?: string) => void;
    error?: string;
    disabled?: boolean;
};

export const AddMemberEdit: React.FC<AddMemberEditProps> = (props) => {
    const formatEmailList = (emailList: string): string[] =>
        _.compact(emailList.replace(/\s/g, '').split(',').reverse());

    return (
        <>
            <DialogContentText>Add new team members by providing a comma separated list of emails.</DialogContentText>
            <TextField
                multiline
                margin="dense"
                id="email-list-field"
                data-testid="email-list-field"
                label="Email Address List"
                type="email"
                fullWidth
                disabled={props.disabled}
                onChange={(event): void => {
                    if (props.error) {
                        props.setError(undefined);
                    }
                    props.setEmails(formatEmailList(event.target.value));
                }}
                sx={Styles.emailTextField}
            />
            <List dense sx={Styles.emailList}>
                {props.emails.map((email, index) => (
                    <ListItem key={index} data-testid={`email-item-${index}`}>
                        <ListItemIcon>
                            <EmailIcon />
                        </ListItemIcon>
                        <ListItemText primary={`${email}`} />
                    </ListItem>
                ))}
            </List>
            {props.error && (
                <Box sx={Styles.errorTextField}>
                    <Typography variant="subtitle2">Failed to submit invites:</Typography>
                    <Typography variant="body2">{props.error}</Typography>
                </Box>
            )}
        </>
    );
};
