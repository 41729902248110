import { useParams } from 'react-router-dom';
import { ApiClient } from 'src/client/api-client';
import { AppDetails } from 'src/client/components/AppDetails';
import { DisplayError } from 'src/client/components/DisplayError';
import { PageWrapper } from 'src/client/components/PageWrapper';
import { Spinner } from 'src/client/components/Spinner';
import { useRequest } from 'src/client/hooks/request-hook';

export const ApplicationsDetailsPage = (): JSX.Element => {
    const params = useParams();
    const id = params.id ?? ''; // TODO better fix
    const [isAppLoading, appDetails, requeryApps] = useRequest(() => ApiClient.getApplicationById(id));
    const [isEntitlementLoading, entitlementDetails, requeryEntitlement] = useRequest(() =>
        ApiClient.getEntitlementByApplicationId(id)
    );
    const [isSecurityLoading, userAbilities, requeryAbilities] = useRequest(() =>
        ApiClient.getSecurityAbilities('Application', id)
    );
    const [isTeamDetailsLoading, teamDetails, requeryTeamDetails] = useRequest(() => ApiClient.getTeams());

    const getTeamIds = (): string[] => teamDetails?.map((team) => team.id) ?? [];

    const requery = (): void => {
        requeryApps();
        requeryAbilities();
        requeryTeamDetails();
        requeryEntitlement();
    };

    const isLoading = isAppLoading || isSecurityLoading || isTeamDetailsLoading || isEntitlementLoading;

    return (
        <PageWrapper title="My Apps">
            {isLoading ? (
                <Spinner />
            ) : !appDetails || !userAbilities || !teamDetails || !entitlementDetails ? (
                <DisplayError />
            ) : (
                <AppDetails
                    application={appDetails}
                    userAbilities={userAbilities}
                    requery={requery}
                    teamIdsForUser={getTeamIds()}
                    entitlement={entitlementDetails}
                />
            )}
        </PageWrapper>
    );
};
