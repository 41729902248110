import { PageWrapper } from 'src/client/components/PageWrapper';
import { ScrollableNav } from 'src/client/components/ScrollableNav';
import source from 'src/client/markdown-content/markdown-pages/UnderstandingOcpp.md';

const UNDERSTANDING_OCPP_SECTIONS = [
    {
        id: 'settingUpOcpp',
        title: 'Setting Up OCPP',
    },
    {
        id: 'affectedEvApIs',
        title: 'Affected EV APIs',
    },
    {
        id: 'offlineBehavior',
        title: 'Offline Behavior',
    },
    {
        id: 'supportedProfiles',
        title: 'Supported Profiles',
    },
    {
        id: 'supportedMeasurands',
        title: 'Supported Measurands',
    },
    {
        id: 'supportedConfigurations',
        title: 'Supported Configurations',
    },
    {
        id: 'vendorSpecificErrors',
        title: 'Vendor Specific Errors',
    },
];

export const UnderstandingOcppPage: React.FC = () => (
    <PageWrapper title="Understanding OCPP">
        <ScrollableNav sectionList={UNDERSTANDING_OCPP_SECTIONS} source={source} pageRoute="/advancedTopics/ocpp" />
    </PageWrapper>
);
