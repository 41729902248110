import * as Colors from '@brightlayer-ui/colors';
import { Theme } from '@mui/material/styles';

export const Styles = {
    body: (theme: Theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        marginLeft: theme.spacing(3),
        marginTop: '25px',
    }),
    title: { marginBottom: '25px' },
    informationWarning: { marginBottom: '6px' },
    apiKeyInformation: { marginBottom: '28px', maxWidth: '700px' },
    textBoxHeader: { marginBottom: '10px' },
    completeButton: { marginTop: '30px', textTransform: 'uppercase' },
    readOnlyTextBox: { marginBottom: '15px' },
    securityInfoBar: {
        marginBottom: '15px',
        backgroundColor: Colors.yellow[50],
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    secretsContainer: (theme: Theme) => ({
        backgroundColor: Colors.yellow[100],
        paddingTop: '25px',
        paddingBottom: '40px',
        paddingLeft: theme.spacing(3),
        marginTop: '30px',
    }),
    readOnlyTextGroup: { display: 'flex', flexFlow: 'column' },
};
