import { InfoListItem } from '@brightlayer-ui/react-components';
import { ReactNode } from 'react';
import { getStatusIndicators } from 'src/client/helpers/get-status-indicators';
import { Styles } from './styles';
import { SxProps } from '@mui/material/styles';
import { generateSxProps } from '../../helpers/generate-sx-props';

type Props = {
    message: string | ReactNode;
    level: 'info' | 'warning' | 'error';
    rightComponent?: ReactNode;
    sx?: SxProps;
};

export const StatusMessage: React.FC<Props> = (props) => (
    <InfoListItem
        dense
        title={props.message}
        {...getStatusIndicators(props.level, true)}
        data-testid="status-message"
        wrapTitle
        rightComponent={props.rightComponent}
        sx={[Styles.statusMessage, ...generateSxProps(props.sx)]}
    />
);
