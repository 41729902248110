import { PageWrapper } from 'src/client/components/PageWrapper';
import { ScrollableNav } from 'src/client/components/ScrollableNav';
import source from 'src/client/markdown-content/markdown-pages/ControlDevices.md';

const CONTROL_DEVICES_SECTIONS = [
    {
        id: 'authTokenExchangeWorkflow',
        title: 'Auth Token Exchange Workflow',
    },
    {
        id: 'examples',
        title: 'Examples',
    },
    {
        id: 'scheduleBatchCommands',
        title: 'Schedule Batch Commands',
    },
    {
        id: 'exampleRequests',
        title: 'Example Requests',
    },
];

export const ControlDevicesPage: React.FC = () => (
    <PageWrapper title="Control Devices">
        <ScrollableNav
            sectionList={CONTROL_DEVICES_SECTIONS}
            source={source}
            pageRoute="/advancedTopics/controlDevices"
        />
    </PageWrapper>
);
