import { Theme } from '@mui/material/styles';
import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    accountInfoContent: (theme: Theme) => ({
        paddingTop: '50px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        height: '56px',
        alignItems: 'baseline',
        [theme.breakpoints.down('sm')]: {
            height: '64px',
        },
    }),
    accountInfoToolbar: (theme: Theme) => ({
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        height: '108px',
        backgroundImage:
            'linear-gradient(90deg, #007BC1 5%, rgba(0, 123, 193, 0.2) 100%), url(/images/title-overlay.png)',
        alignItems: 'baseline',
        [theme.breakpoints.down('sm')]: {
            height: '64px',
        },
    }),
    accountProfile: (theme: Theme) => ({
        zIndex: 100,
        width: '76px',
        height: '76px',
        marginTop: '70px',
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    }),
    loginButton: {
        color: Colors.white['50'],
        backgroundColor: 'transparent',
        fontSize: '14px',
    },
    pageBackground: (theme: Theme) => ({
        backgroundColor: theme.palette.background.paper,
        minHeight: '100vh',
        position: 'relative',
    }),
    title: {
        marginTop: '13px',
        marginBottom: '13px',
    },
    toolbar: (theme: Theme) => ({
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        height: '56px',
        alignItems: 'center',
    }),
    userMenu: {
        marginTop: '12px',
    },
    accountSpacer: (theme: Theme) => ({
        height: '145px',
        backgroundColor: 'white',
        zIndex: 50,
        position: 'fixed',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    }),
    appBar: (theme: Theme) => ({
        zIndex: () => theme.zIndex.drawer + 1,
    }),
};
