import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';
import * as Types from 'server/api/types';

type Subjects = 'Team' | 'Application';

type Abilities = 'View' | 'Edit';

export const getSecurityAbilities = (
    subject: Subjects,
    subjectId: string
): Promise<RequestHelperResult<Abilities[]>> => {
    const queryParameters: Types.GetSecurityAbilitiesQueryParams = {
        subject: subject,
        subjectId: subjectId,
    };

    return axiosRequestHelper<Types.GetSecurityAbilitiesResponseBody>('GET', '/api/security/abilities', {
        params: queryParameters,
    });
};
