import { PageWrapper } from 'src/client/components/PageWrapper/index';
import { Tutorial } from 'src/client/components/Tutorial';
import { Typography } from '@mui/material';
import { TutorialInfoText } from 'src/client/components/Tutorial/TutorialInfoText';
import { Styles } from './styles';

export const TutorialPage = (): JSX.Element => (
    <PageWrapper title="Tutorial">
        <Typography variant="h4" sx={Styles.tutorialPageTitle}>
            EM API Introduction
        </Typography>
        <Typography variant="body1" sx={Styles.tutorialPageContent}>
            This series of steps illustrates what is needed to make API calls and provides example requests and
            responses for a number of common interactions.
        </Typography>
        <Typography variant="body1" sx={Styles.tutorialPageContent}>
            <strong>Please note:</strong> This walkthrough is for informational purposes only. The requests and
            responses are simulated and are not actually communicating with the real API.
        </Typography>
        <TutorialInfoText />
        <Tutorial />
    </PageWrapper>
);
