import { Box, Button, CircularProgress, Divider } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { ApiClient } from 'src/client/api-client';
import { UserSecurityIcon } from 'src/client/icons/user-security-icon';
import { SchemaValidationResult, Validator } from 'src/client/util/validator';
import { AccountDisplay } from './AccountDisplay';
import { AccountEdit } from './AccountEdit';
import { Styles } from './styles';
import { AccountData } from './types';
import { useApp } from 'src/client/contexts/AppContextProvider';

type AccountInfoProps = {
    accountData: AccountData;
};

export const AccountInfo = (props: AccountInfoProps): JSX.Element => {
    const app = useApp();

    const [inEditMode, setInEditMode] = useState<boolean>(false);
    const [savedAccountInfo, setSavedAccountInfo] = useState<AccountData>(props.accountData);
    const [accountInfo, setAccountInfo] = useState<AccountData>(props.accountData);
    const [validationErrors, setValidationErrors] = useState<SchemaValidationResult<AccountData> | undefined>(
        undefined
    );
    const [saveInFlight, setSaveInFlight] = useState<boolean>(false);
    const [saveError, setSaveError] = useState<string | undefined>(undefined);

    const saveAccountInfo = async (): Promise<void> => {
        const validation = Validator.validateSchema(accountInfo, {
            firstName: {
                type: 'string',
                length: { maximum: 255 },
                presence: {
                    allowEmpty: false,
                },
            },
            lastName: {
                type: 'string',
                length: { maximum: 255 },
                presence: {
                    allowEmpty: false,
                },
            },
            phoneNumber: {
                type: 'string',
                length: { maximum: 30 },
            },
            companyName: {
                type: 'string',
                length: { maximum: 255 },
                presence: {
                    allowEmpty: false,
                },
            },
            jobTitle: {
                type: 'string',
                length: { maximum: 255 },
                presence: {
                    allowEmpty: false,
                },
            },
            department: {
                type: 'string',
                presence: {
                    allowEmpty: false,
                },
            },
        });

        setValidationErrors(validation);

        if (!validation) {
            setSaveInFlight(true);
            const result = await ApiClient.updateUserInformation(_.omit(accountInfo, 'email'));
            if (result.success) {
                setSavedAccountInfo(accountInfo);
                setInEditMode(false);
                setSaveError(undefined);
            } else {
                setSaveError(result.error || 'Unknown error occured, please try again.');
            }
            setSaveInFlight(false);
        }
    };

    const cancelEditAccountInfo = (): void => {
        setAccountInfo(savedAccountInfo);
        setInEditMode(false);
    };

    const canSave = !_.isEqual(savedAccountInfo, accountInfo) && !validationErrors;

    return (
        <>
            <Box sx={Styles.container}>
                {inEditMode ? (
                    <>
                        <AccountEdit
                            accountData={accountInfo}
                            setAccountData={setAccountInfo}
                            validationErrors={validationErrors}
                            clearValidationErrors={(): void => {
                                setValidationErrors(undefined);
                                setSaveError(undefined);
                            }}
                            disabled={saveInFlight}
                        />
                        <Box>
                            <Button
                                id="save-account-info-button"
                                variant="contained"
                                color="primary"
                                onClick={(): void => {
                                    void saveAccountInfo();
                                }}
                                sx={{ ...Styles.button, ...Styles.buttonSave }}
                                disableElevation
                                disabled={!canSave}
                            >
                                {saveInFlight ? (
                                    <CircularProgress
                                        sx={Styles.circularProgress}
                                        size={20}
                                        variant={'indeterminate'}
                                    />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={cancelEditAccountInfo}
                                sx={Styles.button}
                                disabled={saveInFlight}
                            >
                                Cancel
                            </Button>
                        </Box>
                        {saveError && <Box sx={Styles.errorTextField}>{saveError}</Box>}
                    </>
                ) : (
                    <>
                        <AccountDisplay accountData={savedAccountInfo} />
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={(): void => setInEditMode(true)}
                            sx={Styles.button}
                        >
                            Edit
                        </Button>
                    </>
                )}
            </Box>
            <Divider sx={Styles.divider} />
            <Box sx={Styles.container}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => app.setShowChangePasswordDialog(true)}
                    startIcon={<UserSecurityIcon />}
                    sx={Styles.button}
                    disabled={saveInFlight}
                >
                    Set New Password
                </Button>
            </Box>
        </>
    );
};
