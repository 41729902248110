import { useState } from 'react';
import { SimpleModal } from '../SimpleModal';
import { ApiClient } from 'src/client/api-client';
import { AddMemberEdit } from './AddMemberEdit';
import { AddMemberSuccess } from './AddMemberSuccess';

type AddMembersModalProps = {
    teamId: string;
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    onComplete: () => void;
};

type SubmissionResult = {
    successfulEmailInvites: string[];
    failedEmailInvites: Array<{ email: string; errorMessage: string }>;
};

export const AddMembersModal: React.FC<AddMembersModalProps> = (props) => {
    const [emails, setEmails] = useState<string[]>([]);
    const [error, setError] = useState<string | undefined>();
    const [submissionResult, setSubmissionResult] = useState<SubmissionResult | undefined>();

    const disableSubmit = emails.length === 0 && !submissionResult;

    const onSubmit = async (): Promise<void> => {
        const result = await ApiClient.inviteTeamMembers(props.teamId, emails);
        if (result.success) {
            setEmails([]);
            setError(undefined);
            setSubmissionResult(result.data);
        } else {
            setError(result.error);
        }
    };

    const cleanUpState = (): void => {
        setEmails([]);
        setError(undefined);
        setSubmissionResult(undefined);
    };

    const endWorkflow = (): Promise<void> => {
        props.setOpen(false);
        props.onComplete();
        cleanUpState();
        return Promise.resolve();
    };

    return (
        <SimpleModal
            isOpen={props.isOpen}
            modalTitle={'Add Team Members'}
            setOpen={props.setOpen}
            submitActionButtonText={submissionResult ? 'Done' : 'Submit'}
            submitActionButtonOnClick={submissionResult ? endWorkflow : onSubmit}
            submitActionButtonDisabled={disableSubmit}
            cancelActionButtonText={submissionResult ? undefined : 'Cancel'}
            onClose={cleanUpState}
        >
            {submissionResult ? (
                <AddMemberSuccess
                    successfulEmailInvites={submissionResult.successfulEmailInvites}
                    failedEmailInvites={submissionResult.failedEmailInvites}
                />
            ) : (
                <AddMemberEdit emails={emails} setEmails={setEmails} error={error} setError={setError} />
            )}
        </SimpleModal>
    );
};
