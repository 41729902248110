import * as Types from 'server/api/types';
import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';

export const requestUserRegistrationValidate = async (
    code: string,
    email: string,
    eulaNames?: string[]
): Promise<RequestHelperResult<boolean>> => {
    const requestBody: Types.UsersRegistrationsValidateRequestBody = {
        email: email,
        eulaNames: eulaNames,
    };

    const queryParameters: Types.UsersRegistrationsValidateQueryParams = {
        code: code,
    };

    const result = await axiosRequestHelper<Types.UsersRegistrationsValidateResponseBody>(
        'POST',
        '/api/users/registrations/validate',
        {
            data: requestBody,
            params: queryParameters,
        }
    );

    if (!result.success) {
        return result;
    }

    return {
        success: true,
        data: result.data.registrationComplete,
    };
};
