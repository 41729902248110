import { Theme } from '@mui/material/styles';
import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    body: (theme: Theme) => ({
        marginLeft: theme.spacing(2),
    }),
    modalTextContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    confirmationQuestionText: {
        fontWeight: 600,
        fontSize: '1.25rem',
        marginBottom: '24px',
    },
    confirmationInstructionalText: {
        fontSize: '1rem',
    },
    errorInformationText: {
        color: Colors.red[500],
        fontSize: '1rem',
    },
    textBoxHeader: { marginBottom: '10px' },
    readOnlyTextBox: { display: 'flex', flexFlow: 'column', width: '100%' },
    securityInfoBar: {
        marginBottom: '15px',
        backgroundColor: Colors.yellow[50],
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    secretsContainer: (theme: Theme) => ({
        backgroundColor: Colors.yellow[100],
        paddingTop: '25px',
        paddingBottom: '40px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginTop: '30px',
        maxwidth: '100%',
    }),
};
