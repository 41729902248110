import axios from 'axios';
import * as Types from 'server/api/types';

export const deleteAuthToken = async (userId: string): Promise<void> => {
    const requestBody: Types.SecurityTokensDeleteRequestBody = {
        userId: userId,
    };

    await axios.request<Types.SecurityTokensDeleteRequestBody>({
        method: 'DELETE',
        url: '/api/security/tokens',
        data: requestBody,
        validateStatus: (): boolean => true, // We don't care about the result
    });
};
