import { Box, FormHelperText, Typography } from '@mui/material';
import React, { useState } from 'react';
import { MakeTextField } from 'src/client/components/HelperComponents/TutorialHelpers/MakeTextField';
import { EM_API_SUBSCRIPTION_KEY, ORGANIZATION_AUTH_TOKEN, ORGANIZATION_ID } from 'src/client/constants/tutorial';
import { useAsyncFn } from 'src/client/hooks/async-fn-hook';
import { SimulatedEmApiClient } from 'src/client/simulated-em-api/simulated-em-api-client';
import { Validator } from 'src/client/util/validator';
import { withDelay } from 'src/shared/lib/with-delay';
import { BasicDropdown } from '../../BasicDropdown';
import { RequestDefinition, RequestResponse } from '../../RequestResponse';
import { SubmitButton } from '../../SubmitButton';
import { Styles } from '../styles';
import { TutorialExpandable, TutorialStepProps } from '../TutorialExpandable';

type CreateLocationTextFields = {
    emApiSubscriptionKey: string;
    organizationAuthToken: string;
    organizationId: string;
    name: string;
    description?: string;
    parentLocationId?: string;
    contact?: string;
    email?: string;
    phone?: string;
    locationType: string;
    city: string;
    state: string;
    postalCode: string;
    street1: string;
    street2?: string;
};

export const TutorialCreateAddressLocation: React.FC<TutorialStepProps> = React.memo((props) => {
    const [createLocationBodyValues, setCreateLocationBodyValues] = useState<CreateLocationTextFields>({
        emApiSubscriptionKey: EM_API_SUBSCRIPTION_KEY,
        organizationAuthToken: ORGANIZATION_AUTH_TOKEN,
        organizationId: ORGANIZATION_ID,
        name: `Office Building 6`,
        locationType: 'address',
        city: 'Detroit',
        state: 'Michigan',
        postalCode: '12345',
        street1: '99 Main Street',
    });

    const [inFlight, response, invokeAsyncFn] = useAsyncFn(withDelay(750, SimulatedEmApiClient.createLocationAddress));

    const handleChange = (value: string): void =>
        setCreateLocationBodyValues({ ...createLocationBodyValues, locationType: value });

    const requestBody = {
        organizationId: createLocationBodyValues.organizationId,
        name: createLocationBodyValues.name,
        description: createLocationBodyValues.description,
        parentLocationId: createLocationBodyValues.parentLocationId,
        contact: createLocationBodyValues.contact,
        email: createLocationBodyValues.email,
        phone: createLocationBodyValues.phone,
        locationType: createLocationBodyValues.locationType,
        address: {
            city: createLocationBodyValues.city,
            state: createLocationBodyValues.state,
            postalCode: createLocationBodyValues.postalCode,
            street1: createLocationBodyValues.street1,
            street2: createLocationBodyValues.street2,
        },
    };

    const submitRequest = (): void => invokeAsyncFn(requestBody);

    const request: RequestDefinition = {
        method: 'POST',
        endpointPath: '/locations',
        headers: [
            { name: 'Em-Api-Subscription-Key', value: createLocationBodyValues.emApiSubscriptionKey },
            { name: 'Authorization', value: `Bearer ${createLocationBodyValues.organizationAuthToken}` },
            { name: 'Accept', value: 'application/json' },
            { name: 'Content-Type', value: 'application/json; charset=utf-8' },
        ],
        body: requestBody,
    };

    const required = {
        type: 'string',
        presence: {
            allowEmpty: false,
        },
    } as const;

    //running validate on the values instead of the schema (since the schema is hardcoded)
    const validationErrors = Validator.validateSchema(createLocationBodyValues, {
        organizationId: {
            type: 'string',
            format: {
                pattern: `^${ORGANIZATION_ID}$`,
                message: `is from the organization that was created in step 3. For the tutorial, this is:${ORGANIZATION_ID}.`,
            },
            presence: {
                allowEmpty: false,
            },
        },
        organizationAuthToken: {
            type: 'string',
            format: {
                pattern: `^${ORGANIZATION_AUTH_TOKEN}$`,
                message: `is the token you got from step 4. This represents being logged in as the organization and grants the ability to add locations to the organization. For the tutorial, the token is: ${ORGANIZATION_AUTH_TOKEN}`,
            },

            presence: {
                allowEmpty: false,
            },
        },
        emApiSubscriptionKey: {
            type: 'string',
            format: {
                pattern: `^${EM_API_SUBSCRIPTION_KEY}$`,
                message: `needs to match the api key assigned to your application. The tutorial application's key is ${EM_API_SUBSCRIPTION_KEY}`,
            },
            presence: {
                allowEmpty: false,
            },
        },
        name: required,
        city: required,
        state: required,
        postalCode: required,
        street1: required,
        locationType: {
            type: 'string',
            format: {
                pattern: `^address$`,
                message: `needs to match the api key assigned to your application. The tutorial application's key is ${EM_API_SUBSCRIPTION_KEY}`,
            },
            presence: {
                allowEmpty: false,
            },
        },
    });

    return (
        <TutorialExpandable
            {...props}
            title="Create an Address Location"
            description={
                <>
                    <Typography variant="body1" sx={Styles.description}>
                        A Smart Breaker must be commissioned at a physical location, and the Smart Breaker Platform
                        requires at least two layers to represent the physical world - an{' '}
                        <strong>Address Location</strong> and an <strong>Equipment Location</strong>, the latter being
                        the child location of the former.
                    </Typography>
                    <br />
                    <Typography variant="body1" sx={Styles.description}>
                        Locations can be associated by using a <strong>Parent Location ID</strong>. A Parent Location is
                        an already existing Location that you would have created, such as an Address. An example of this
                        would be areas of a building that would each have their own Address Location (such as floors or
                        rooms). Their Parent would then be the building Address Location itself.
                    </Typography>
                    <br />
                    <Typography variant="body1" sx={Styles.description}>
                        Using an <strong>Organization Auth Token</strong>, create the Address Location by providing the
                        required address fields and record the returned <strong>Address Location ID</strong>.
                    </Typography>
                </>
            }
            valueField={
                <Box component="div" sx={Styles.values}>
                    <form noValidate autoComplete="off">
                        <Box component="div" sx={Styles.entryFields}>
                            {MakeTextField(
                                createLocationBodyValues,
                                validationErrors,
                                'emApiSubscriptionKey',
                                'EM API Subscription Key',
                                setCreateLocationBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                createLocationBodyValues,
                                validationErrors,
                                'organizationAuthToken',
                                'Organization Auth Token',
                                setCreateLocationBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                createLocationBodyValues,
                                validationErrors,
                                'organizationId',
                                'Organization ID',
                                setCreateLocationBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                createLocationBodyValues,
                                validationErrors,
                                'name',
                                'Name',
                                setCreateLocationBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                createLocationBodyValues,
                                validationErrors,
                                'description',
                                'Description',
                                setCreateLocationBodyValues
                            )}
                            {MakeTextField(
                                createLocationBodyValues,
                                validationErrors,
                                'parentLocationId',
                                'Parent Location ID',
                                setCreateLocationBodyValues,
                                {
                                    required: createLocationBodyValues.locationType === 'equipment',
                                }
                            )}
                            {MakeTextField(
                                createLocationBodyValues,
                                validationErrors,
                                'contact',
                                'Contact',
                                setCreateLocationBodyValues
                            )}
                            {MakeTextField(
                                createLocationBodyValues,
                                validationErrors,
                                'email',
                                'Email',
                                setCreateLocationBodyValues
                            )}
                            {MakeTextField(
                                createLocationBodyValues,
                                validationErrors,
                                'phone',
                                'Phone',
                                setCreateLocationBodyValues
                            )}
                            <BasicDropdown
                                sx={createLocationBodyValues.locationType !== 'address' ? undefined : Styles.formField}
                                onChange={handleChange}
                                value={createLocationBodyValues.locationType}
                                items={['address', 'equipment']}
                                label="Location Type"
                            />
                            {createLocationBodyValues.locationType !== 'address' ? (
                                <FormHelperText sx={Styles.formField}>
                                    Required to be &lsquo;address&lsquo; for this step. An equipment location will be
                                    created in the next step.
                                </FormHelperText>
                            ) : (
                                <></>
                            )}

                            {createLocationBodyValues.locationType === 'address' && (
                                <Box component="div" sx={Styles.entryFields}>
                                    {MakeTextField(
                                        createLocationBodyValues,
                                        validationErrors,
                                        'city',
                                        'City',
                                        setCreateLocationBodyValues,
                                        {
                                            required: true,
                                        }
                                    )}
                                    {MakeTextField(
                                        createLocationBodyValues,
                                        validationErrors,
                                        'state',
                                        'State',
                                        setCreateLocationBodyValues,
                                        {
                                            required: true,
                                        }
                                    )}
                                    {MakeTextField(
                                        createLocationBodyValues,
                                        validationErrors,
                                        'postalCode',
                                        'Postal Code',
                                        setCreateLocationBodyValues,
                                        {
                                            required: true,
                                        }
                                    )}
                                    {MakeTextField(
                                        createLocationBodyValues,
                                        validationErrors,
                                        'street1',
                                        'Street Address',
                                        setCreateLocationBodyValues,
                                        {
                                            required: true,
                                        }
                                    )}
                                    {MakeTextField(
                                        createLocationBodyValues,
                                        validationErrors,
                                        'street2',
                                        'Apt / Building Number',
                                        setCreateLocationBodyValues
                                    )}
                                </Box>
                            )}
                        </Box>
                    </form>
                    <SubmitButton
                        id="send-request-button"
                        onClick={submitRequest}
                        disabled={!!validationErrors}
                        spinning={inFlight}
                        buttonText="Send Request"
                        width={144}
                    />
                </Box>
            }
            requestResponse={<RequestResponse request={request} response={response} />}
        />
    );
});
