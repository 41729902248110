import { PageWrapper } from 'src/client/components/PageWrapper';
import { ScrollableNav } from 'src/client/components/ScrollableNav';
import source from 'src/client/markdown-content/markdown-pages/UnderstandingEvseStates.md';

const UNDERSTANDING_EVSE_STATES = [
    {
        id: 'physicalConnectionStates',
        title: 'Physical Connection States',
    },
    {
        id: 'currentlyAppliedControlSettings',
        title: 'Currently-Applied Control Settings',
    },
    {
        id: 'understandingEnabled',
        title: 'Understanding Enabled',
    },
    {
        id: 'understandingAuthorized',
        title: 'Understanding Authorized',
    },
];

export const UnderstandingEvseStatesPage: React.FC = () => (
    <PageWrapper title="Understanding EVSE States">
        <ScrollableNav sectionList={UNDERSTANDING_EVSE_STATES} source={source} pageRoute="/advancedTopics/evseStates" />
    </PageWrapper>
);
