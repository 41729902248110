import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    body: {
        marginBottom: '12px',
    },
    listItem: {
        display: 'flex',
        marginLeft: '0px',
        marginRight: '0px',
        paddingTop: '6px',
        paddingBottom: '6px',
    },
    selectedListItem: {
        backgroundColor: Colors.blue[50],
    },
    scrollableContainer: {
        maxHeight: '300px',
        overflow: 'auto',
    },
    bodyBottomContent: { display: 'flex', marginTop: '16px', flexDirection: 'row-reverse' },
    emptyState: {
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    emptyStateIcon: {
        alignItems: 'center',
        fontSize: '140px',
    },
};
