import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';
import * as Types from 'server/api/types';

export const updateApplicationTeam = (
    appId: string,
    teamId: string,
    role: 'Admin' | 'Developer'
): Promise<RequestHelperResult<undefined>> => {
    const requestBody: Types.ApplicationsTeamUpdateRequestBody = {
        role: role,
    };

    return axiosRequestHelper('PUT', `/api/applications/${appId}/teams/${teamId}`, {
        data: requestBody,
    });
};
