import * as Colors from '@brightlayer-ui/colors';
import { Theme } from '@mui/material/styles';

export const Styles = {
    body: (theme: Theme) => ({
        marginLeft: theme.spacing(2),
    }),
    modalTextContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    errorInformationText: {
        color: Colors.red[500],
        fontSize: '1rem',
    },
};
