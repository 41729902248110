import * as Colors from '@brightlayer-ui/colors';
import { Theme } from '@mui/material/styles';

export const Styles = {
    toolbar: (theme: Theme) => ({
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }),
    body: (theme: Theme) => ({
        minHeight: `calc(100vh - ${theme.spacing(8)})`,
        backgroundImage:
            'linear-gradient(90deg, rgba(229, 229, 229, 0.7), rgba(255, 255, 255, 1.0) 50%), url(/images/title-overlay.png)',
        [theme.breakpoints.down('xs')]: {
            minHeight: `calc(100vh - ${theme.spacing(7)})`,
        },
    }),
    header: {
        textAlign: 'center',
        marginBottom: '90px',
    },
    title1: {
        color: Colors.blue[500],
        marginBottom: '0px',
        paddingTop: '25px',
        paddingRight: '190px',
        paddingLeft: '190px',
    },
    subtitle: {
        marginTop: '26px',
    },
    buttons: {
        display: 'flex',
    },
    tutorialButton: {
        maxWidth: '100px',
        textTransform: 'uppercase',
        margin: '16px',
        marginTop: '26px',
    },
    divider: (theme: Theme) => ({
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    }),
    rotate: {
        animation: '2500ms $spin linear infinite',
    },
    '@keyframes spin': {
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
};
