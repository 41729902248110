import { Button, InputAdornment, TextField } from '@mui/material';
import { Styles } from './styles';
import _ from 'lodash';
import { SxProps } from '@mui/material/styles';
import { generateSxProps } from '../../helpers/generate-sx-props';

type ReadonlyTextWithActionProps = {
    title: string;
    content: string;
    buttonText: string;
    buttonDisabled?: boolean;
    sx?: SxProps;
    onClick?: () => void;
    displayError?: boolean;
    helperText?: string;
    'data-testid'?: string;
};
export const ReadOnlyTextWithAction: React.FC<ReadonlyTextWithActionProps> = (props) => (
    <TextField
        id={`${_.kebabCase(props.title)}-read-only-input`}
        data-testid={props['data-testid'] ? `${props['data-testid']}` : `${_.kebabCase(props.title)}-read-only-input`}
        label={props.title}
        variant="filled"
        defaultValue={props.content}
        error={props.displayError}
        helperText={props.helperText}
        sx={[Styles.readOnlyField, ...generateSxProps(props.sx)]}
        InputProps={{
            readOnly: true,
            endAdornment: (
                <InputAdornment position="end">
                    <Button
                        sx={Styles.button}
                        variant="contained"
                        color="primary"
                        disabled={props.buttonDisabled}
                        onClick={props.onClick}
                    >
                        {props.buttonText}{' '}
                    </Button>
                </InputAdornment>
            ),
        }}
    />
);
