import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    contentBlock: {
        display: 'flex',
        gap: '60px',
        alignItems: 'center',
    },
    textContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '60%',
    },
    imageContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '40%',
    },
    headline: {
        color: Colors.blue[500],
        marginBottom: '15px',
    },
};
