import { Box, Button, Typography } from '@mui/material';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrivacyTip } from 'src/client/icons/privacy-tip';
import { DateTimeHelpers } from 'src/shared/lib/date-helpers';
import { ReadOnlyTextAndCopy } from '../../ReadOnlyTextAndCopy';
import { Styles } from './styles';
import * as Colors from '@brightlayer-ui/colors';
import { useTheme } from '@mui/material/styles';

type ApplicationConfirmationProps = {
    application: {
        id: string;
        name: string;
        apiKey: string;
        clientId: string;
        secret1: string;
        secret1Expiry: string;
        secret2: string;
        secret2Expiry: string;
    };
};

export const ApplicationConfirmationDisplay: React.FC<ApplicationConfirmationProps> = (props) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const navigateAway = useCallback((): void => {
        navigate(`/applications/${props.application.id}`);
    }, [navigate, props.application.id]);

    return (
        <>
            <Box sx={Styles.body(theme)}>
                <Typography sx={Styles.title} variant="h4" data-testid="create-app-confirmation-title">
                    {props.application.name} has been created!
                </Typography>
                <Typography sx={Styles.informationWarning} variant="body1">
                    <b>Please keep the following information safe.</b>
                </Typography>
                <Typography sx={Styles.apiKeyInformation} variant="body1">
                    Your application API Key is not set to expire. However, do copy and securely store your secret
                    credentials. We will not keep these credentials for you and they will be lost as soon as you
                    navigate away from this page.
                </Typography>
                <Typography sx={Styles.textBoxHeader} variant="body1">
                    <b>{"Your application's API Key:"}</b>
                </Typography>
                <ReadOnlyTextAndCopy title="API Key" content={props.application.apiKey} sx={Styles.readOnlyTextBox} />
                <Typography sx={Styles.textBoxHeader} variant="body1">
                    <b>{"Your application's Client ID:"}</b>
                </Typography>
                <ReadOnlyTextAndCopy title="Client ID" content={props.application.clientId} />
            </Box>
            <Box sx={Styles.secretsContainer(theme)}>
                <Typography sx={Styles.textBoxHeader} variant="body1">
                    <b>{"Your application's secret credentials:"}</b>
                </Typography>
                <InfoListItem
                    data-testid="credentials-warning"
                    title={'Copy and securely save your secret credentials.'}
                    subtitle={
                        'We will not keep these credentials for you and you will need them to access your application.'
                    }
                    divider={'full'}
                    statusColor={Colors.yellow[500]}
                    subtitleSeparator={'/'}
                    icon={<PrivacyTip />}
                    sx={Styles.securityInfoBar}
                />
                <Box sx={Styles.readOnlyTextGroup}>
                    <ReadOnlyTextAndCopy title="Secret 1" content={props.application.secret1} />
                    <Typography sx={Styles.textBoxHeader} variant="body2">
                        <i>
                            This key expires on{' '}
                            {DateTimeHelpers.formatDateStr(props.application.secret1Expiry, 'MMMM d, yyyy')}
                        </i>
                    </Typography>
                    <ReadOnlyTextAndCopy title="Secret 2" content={props.application.secret2} />
                    <Typography sx={Styles.textBoxHeader} variant="body2">
                        <i>
                            This key expires on{' '}
                            {DateTimeHelpers.formatDateStr(props.application.secret2Expiry, 'MMMM d, yyyy')}
                        </i>
                    </Typography>
                </Box>
            </Box>
            <Box sx={Styles.body}>
                <Button sx={Styles.completeButton} variant="contained" color="primary" onClick={navigateAway}>
                    Done
                </Button>
            </Box>
        </>
    );
};
