import * as Colors from '@brightlayer-ui/colors';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { ApiClient } from 'src/client/api-client';
import { PrivacyTip } from 'src/client/icons/privacy-tip';
import { DateTimeHelpers } from 'src/shared/lib/date-helpers';
import { GenericConfirmationModal } from '../GenericConfirmationModal';
import { ReadOnlyTextAndCopy } from '../ReadOnlyTextAndCopy';
import { Styles } from './styles';
import { useTheme } from '@mui/material/styles';

type RotateAppSecretConfirmationModalProps = {
    app: { id: string; name: string };
    secretName: 'secret1' | 'secret2';
    onClose: () => void;
    onSuccess: () => void;
};

type RotateSecretResponseData = {
    secretName: string;
    expiry: string;
    value: string;
};

type ConfirmationModalState = 'confirmAction' | 'success' | 'failed' | 'confirmExit';

export const RotateAppSecretConfirmationModal: React.FC<RotateAppSecretConfirmationModalProps> = (props) => {
    const [errorText, setErrorText] = useState<string>('');
    const [newSecretInfo, setNewSecretInfo] = useState<RotateSecretResponseData>();
    const [modalState, setModalState] = useState<ConfirmationModalState>('confirmAction');
    const theme = useTheme();
    const onSubmitClick = async (): Promise<void> => {
        const result = await ApiClient.rotateApplicationSecret(props.app.id, props.secretName);

        if (!result.success) {
            setErrorText(`Failed to regenerate secret. ${result.error || 'no error found'} `);
            setModalState('failed');
            return;
        }

        setModalState('success');
        setNewSecretInfo(result.data);
    };

    const goToModalSuccessScreen = (): void => {
        setModalState('success');
    };

    const onDoneClick = async (): Promise<void> => {
        setModalState('confirmExit');
        await Promise.resolve();
    };

    const onExitClick = async (): Promise<void> => {
        props.onSuccess();
        props.onClose();
        await Promise.resolve();
    };

    const getReadableName = (secretName: 'secret1' | 'secret2'): string =>
        secretName === 'secret1' ? 'Secret 1' : 'Secret 2';

    const getModalContent = (): React.ReactElement => {
        switch (modalState) {
            case 'confirmAction':
                return (
                    <>
                        <Typography sx={Styles.confirmationQuestionText}>
                            Are you sure you want to regenerate secret
                            <b> {getReadableName(props.secretName)}</b> from the <b>{props.app.name}</b> application?
                        </Typography>
                        <Typography sx={Styles.confirmationInstructionalText}>
                            You will need to save the regenerated secret value for use in all relevant API calls
                        </Typography>
                    </>
                );
            case 'success':
                return (
                    (newSecretInfo && (
                        <>
                            <Box sx={Styles.secretsContainer(theme)}>
                                <Typography sx={Styles.textBoxHeader} variant="body1">
                                    <b>{"Your application's secret credentials:"}</b>
                                </Typography>
                                <InfoListItem
                                    data-testid="credentials-warning"
                                    title={'Copy and securely save your secret credentials.'}
                                    wrapSubtitle={true}
                                    subtitle={
                                        'We will not keep these credentials for you and you will need them to access your application.'
                                    }
                                    divider={'full'}
                                    wrapTitle={true}
                                    wrapInfo={true}
                                    statusColor={Colors.yellow[500]}
                                    subtitleSeparator={'/'}
                                    icon={<PrivacyTip />}
                                    sx={Styles.securityInfoBar}
                                />
                                <ReadOnlyTextAndCopy
                                    title={getReadableName(props.secretName)}
                                    content={newSecretInfo.value}
                                    sx={Styles.readOnlyTextBox}
                                />
                                <Typography sx={Styles.textBoxHeader} variant="body2">
                                    <i>
                                        This key expires on{' '}
                                        {DateTimeHelpers.formatDateStr(newSecretInfo.expiry, 'MMMM d, yyyy')}
                                    </i>
                                </Typography>
                            </Box>
                        </>
                    )) || <></>
                );
            case 'failed':
                // errorText is displayed instead of modal content in a failure scenario
                return <></>;
            case 'confirmExit':
                return (
                    <>
                        <InfoListItem
                            data-testid="credentials-warning"
                            title={`Are you sure you copied the new ${getReadableName(
                                props.secretName
                            )} value for the ${props.app.name} application?`}
                            wrapSubtitle={true}
                            divider={'full'}
                            wrapTitle={true}
                            wrapInfo={true}
                            statusColor={Colors.yellow[500]}
                            icon={<PrivacyTip />}
                            sx={Styles.securityInfoBar}
                        />
                        <Typography sx={Styles.confirmationInstructionalText}>
                            You will need to save the regenerated secret value for use in all relevant API calls
                        </Typography>
                    </>
                );
            default:
                return <></>;
        }
    };

    const getSubmitAction = (): (() => Promise<void>) => {
        switch (modalState) {
            case 'confirmAction':
                return onSubmitClick;
            case 'success':
                return onDoneClick;
            case 'failed':
                return onExitClick;
            default:
                return onExitClick;
        }
    };

    const getActionText = (): string => {
        switch (modalState) {
            case 'confirmAction':
                return 'Regenerate Secret';
            case 'success':
                return 'Done';
            case 'failed':
                return 'Regenerate Secret';
            default:
                return 'Finish';
        }
    };

    const getCancelText = (): string | undefined => {
        switch (modalState) {
            case 'confirmAction':
                return 'Cancel';
            case 'success':
                return undefined;
            case 'failed':
                return 'Exit';
            default:
                return 'Back';
        }
    };

    return (
        <GenericConfirmationModal
            errorText={errorText}
            submitActionButtonOnClick={getSubmitAction()}
            onClose={props.onClose}
            modalTitle="Regenerate Application Secret"
            submitActionButtonText={getActionText()}
            cancelActionButtonText={getCancelText()}
            cancelActionButtonOnClick={modalState === 'confirmExit' ? goToModalSuccessScreen : undefined}
        >
            {getModalContent()}
        </GenericConfirmationModal>
    );
};
