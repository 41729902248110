import _ from 'lodash';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectableDropdownList } from '../SelectableDropdownList';
import { Styles } from './styles';
import * as Colors from '@brightlayer-ui/colors';
import { Box, Typography } from '@mui/material';
import { Clock } from 'src/shared/lib/clock';
import { add } from 'date-fns';

type Applications = Array<{ id: string; name: string; status?: 'active' | 'expired'; expirationDate?: string }>;

export const AppsList: React.FC<{
    appsData: Applications;
}> = (props) => {
    const navigate = useNavigate();
    const sorted = _.sortBy(props.appsData, ['name']);

    const onClick = useCallback(
        (id: string) => (): void => {
            navigate(`/applications/${id}`);
        },
        [navigate]
    );

    const isExpirationDateWithinNextMonth = (expirationDate: string): boolean => {
        const futureDate = new Date(add(Clock.now(), { days: 30 }));
        const expiration = new Date(expirationDate);
        return futureDate >= expiration;
    };

    const getStatusWarning = (expirationDate?: string) => {
        if (expirationDate) {
            if (Clock.now() > new Date(expirationDate)) {
                return {
                    statusColor: Colors.red[500],
                    rightComponent: (
                        <Typography sx={Styles.list} data->
                            Subscription expired!
                        </Typography>
                    ),
                };
            }
            if (isExpirationDateWithinNextMonth(expirationDate)) {
                return {
                    statusColor: Colors.yellow[500],
                    rightComponent: (
                        <Typography sx={Styles.list} data->
                            Subscription expiring soon!
                        </Typography>
                    ),
                };
            }
        }
        return {};
    };

    return (
        <Box sx={Styles.list}>
            <SelectableDropdownList
                testId="apps-overview-list"
                listItems={sorted.map((app) => ({
                    id: app.id,
                    title: app.name,
                    ...getStatusWarning(app?.expirationDate),
                    onClick: onClick(app.id),
                }))}
            />
        </Box>
    );
};
