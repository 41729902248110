import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    link: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '8px',
        '&:hover': {
            color: Colors.blue[500],
        },
        '&:visited': {
            color: Colors.blue[500],
        },
    },
    linkIcon: {
        marginRight: '8px',
    },
};
