import {
    Box,
    Button,
    CardActions,
    CircularProgress,
    Divider,
    MobileStepper,
    Typography,
    useTheme,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Styles } from '../styles';

type ContainerProps = {
    step: {
        body: JSX.Element;
        canGoForward: boolean;
        canGoBack: boolean;
        onNext: () => void;
        onBack?: () => void;
    };
    numSteps: number;
    currStep: number;
    submitting?: boolean;
    errorMessage?: string;
};

export const CompleteAccountContainer: React.FC<ContainerProps> = (props) => {
    const theme = useTheme();
    return (
        <>
            {props.step.body}
            <Divider />
            {props.errorMessage && (
                <Box data-testid="error-message" sx={Styles.errorTextField}>
                    <Typography variant="body2">{props.errorMessage}</Typography>
                </Box>
            )}
            <CardActions sx={Styles.dialogActions}>
                <MobileStepper
                    variant="dots"
                    steps={props.numSteps}
                    position="static"
                    activeStep={props.currStep}
                    sx={Styles.containerModalStepper}
                    nextButton={
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={props.step.onNext}
                            disabled={!props.step.canGoForward || props.submitting}
                        >
                            <>
                                {props.submitting ? (
                                    <CircularProgress
                                        sx={Styles.circularProgress}
                                        size={20}
                                        variant={'indeterminate'}
                                    />
                                ) : (
                                    <>
                                        {props.currStep !== props.numSteps - 1 ? 'Next' : 'Submit'}
                                        {props.currStep !== props.numSteps - 1 &&
                                            (theme.direction === 'rtl' ? (
                                                <KeyboardArrowLeft />
                                            ) : (
                                                <KeyboardArrowRight />
                                            ))}
                                    </>
                                )}
                            </>
                        </Button>
                    }
                    backButton={
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={props.step.onBack}
                            disabled={props.submitting}
                        >
                            {props.step.canGoBack &&
                                (theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />)}
                            {!props.step.canGoBack ? 'Log Out' : 'Back'}
                        </Button>
                    }
                />
            </CardActions>
        </>
    );
};
