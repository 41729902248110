import { PageWrapper } from 'src/client/components/PageWrapper';
import { ScrollableNav } from 'src/client/components/ScrollableNav';
import source from 'src/client/markdown-content/markdown-pages/SharedDeviceAccess.md';

const SHARED_DEVICE_ACCESS_SECTIONS = [
    {
        id: 'sharedDeviceAccess',
        title: 'Shared Device Access'
    },
    {
        id: 'managingShareDeviceCodes',
        title: 'Managing Share Device Codes',
    },
    {
        id: 'accessTypes',
        title: 'Access Types'
    },
    {
        id: 'understandingShareCodeStatuses',
        title: 'Understanding Share Code Statuses'
    },
    {
        id: 'shareCodeStatusNotifications',
        title: 'Share Code Status Notifications'
    },
    {
        id: 'sharedDeviceStreams',
        title: 'Shared Device Streams'
    },
    {
        id: 'creatingStreams',
        title: 'Creating Streams'
    },
    {
        id: 'streamLimits',
        title: 'Stream Limits'
    },
    {
        id: 'sharedDeviceOverrideEvents',
        title: 'Shared Device Override Events'
    },
    {
        id: 'effectOnParticipatingDevices',
        title: 'Effect On Participating Devices'
    },
    {
        id: 'managingParticipatingDevices',
        title: 'Managing Participating Devices'
    },
    {
        id: 'overrideEventParticipationNotifications',
        title: 'Override Event Participation Notifications'
    }
];

export const SharedDeviceAccessPage: React.FC = () => (
    <PageWrapper title="Shared Device Access">
        <ScrollableNav sectionList={SHARED_DEVICE_ACCESS_SECTIONS} source={source} pageRoute="/advancedTopics/sharedDeviceAccess" />
    </PageWrapper>
);
