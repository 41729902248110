import { Box, Typography } from '@mui/material';
import { Styles } from './styles';

type HomeContentProps = {
    title: string;
    subtitle: string;
    content: string;
    orientation: 'left' | 'right';
    image?: string;
};

export const HomeContentBlock = (props: HomeContentProps): JSX.Element => {
    const content = (
        <Box sx={Styles.textContent}>
            <Box sx={{ alignSelf: props.orientation === 'right' ? 'start' : 'end', maxWidth: '90%' }}>
                <Typography variant="h4" sx={Styles.headline}>
                    {props.title}
                </Typography>
                <Typography variant="body1">
                    <strong>{props.subtitle}</strong>
                </Typography>
                <Typography variant="body1">{props.content}</Typography>
            </Box>
        </Box>
    );

    const image = props.image ? (
        <Box sx={Styles.imageContent}>
            <img src={props.image} alt="" />
        </Box>
    ) : undefined;

    const leftComponent = props.orientation === 'left' ? content : image;
    const rightComponent = props.orientation === 'right' ? content : image;

    return (
        <Box data-testid="content-block" sx={Styles.contentBlock}>
            {leftComponent}
            {rightComponent}
        </Box>
    );
};
