import * as Colors from '@brightlayer-ui/colors';
import { Theme } from '@mui/material/styles';
export const Styles = {
    dialogTitle: (theme: Theme) => ({
        padding: `${theme.spacing(4)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
        },
    }),
    dialogContent: (theme: Theme) => ({
        flex: '1 1 0px',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(2)}`,
        },
    }),
    dialogActions: (theme: Theme) => ({
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(3),
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
        },
    }),
    dialogButton: {
        width: 100,
    },
    errorTextField: {
        margin: '25px 25px 0px 25px',
        color: Colors.red[500],
    },
    circularProgress: {
        color: 'white',
    },
    eulaContent: { flex: '1 1 0px', overflow: 'auto' },
    checkbox: (theme: Theme) => ({ flex: '0 0 auto', marginRight: 0, marginTop: theme.spacing(2) }),
    heavyFont: { fontWeight: 600 },
    spinner: {
        display: 'flex',
        top: '40%',
        left: '40%',
        position: 'absolute',
    },
    containerModalStepper: {
        flexGrow: 1,
        backgroundColor: 'white',
    },
};
