import * as Types from 'server/api/types';
import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';

export const getEula = (language: string): Promise<RequestHelperResult<string>> => {
    const queryParameters: Types.GetEulaQueryParams = {
        language: language,
    };

    return axiosRequestHelper<Types.GetEulaResponseBody>('GET', '/api/eula', {
        params: queryParameters,
    });
};
