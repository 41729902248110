import React, { useCallback, useState } from 'react';
import { ApiClient } from 'src/client/api-client';
import { clearAndDeleteToken } from 'src/client/helpers/clear-and-delete-token';
import { SchemaValidationResult, Validator } from 'src/client/util/validator';
import { DisplayError } from '../DisplayError';
import { AccountContent } from './AccountContent';
import { CompleteAccountContainer } from './CompleteAccountContainer';
import { EulaContent } from './EulaContent';
import { useApp } from 'src/client/contexts/AppContextProvider';

type AccountInformation = {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    optedOutOfEmailNotifications: boolean;
    phoneNumber?: string;
    companyName?: string;
    jobTitle?: string;
    department?: string;
};

type CompleteAccountFlowProps = {
    accountInformation: AccountInformation;
    showAcceptEula?: boolean;
    showAccountEdit?: boolean;
};

const validate = (
    accountInfo: Omit<AccountInformation, 'id' | 'email'>
): SchemaValidationResult<Omit<AccountInformation, 'id' | 'email'>> =>
    Validator.validateSchema(accountInfo, {
        firstName: {
            type: 'string',
            length: { maximum: 255 },
            presence: {
                allowEmpty: false,
            },
        },
        lastName: {
            type: 'string',
            length: { maximum: 255 },
            presence: {
                allowEmpty: false,
            },
        },
        phoneNumber: {
            type: 'string',
            length: { maximum: 30 },
        },
        companyName: {
            type: 'string',
            length: { maximum: 255 },
            presence: {
                allowEmpty: false,
            },
        },
        jobTitle: {
            type: 'string',
            length: { maximum: 255 },
            presence: {
                allowEmpty: false,
            },
        },
        department: {
            type: 'string',
            presence: {
                allowEmpty: false,
            },
        },
    });

export const CompleteAccountFlow: React.FC<CompleteAccountFlowProps> = (props) => {
    const app = useApp();

    const [submissionError, setSubmissionError] = useState<string | undefined>(undefined);
    const [activeStep, setActiveStep] = useState(0);
    const [eulaAccepted, setEulaAccepted] = useState<boolean>(false);
    const [accountInfo, setAccountInfo] = useState<Omit<AccountInformation, 'id'>>(props.accountInformation);
    const [validationErrors, setValidationErrors] = useState<
        SchemaValidationResult<Omit<AccountInformation, 'id' | 'email'>> | undefined
    >(validate(props.accountInformation));
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const logOut = useCallback((): void => {
        void clearAndDeleteToken(app.loginData.userId || '').then(() => {
            app.onUserNotAuthenticated();
        });
    }, [app]);

    const handleNext = useCallback((): void => {
        if (submissionError) {
            setSubmissionError(undefined);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, [submissionError]);

    const handleBack = useCallback((): void => {
        if (submissionError) {
            setSubmissionError(undefined);
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, [submissionError]);

    const submit = async (): Promise<void> => {
        if (submissionError) {
            setSubmissionError(undefined);
        }

        const validation = validate(accountInfo);
        if (validation) {
            setValidationErrors(validation);
            return;
        }

        setIsSubmitting(true);
        const [eulaAccept, profileAccept] = await Promise.all([
            props.showAcceptEula ? ApiClient.acceptEula() : undefined,
            props.showAccountEdit
                ? ApiClient.updateUserInformation({
                      companyName: accountInfo.companyName,
                      department: accountInfo.department,
                      firstName: accountInfo.firstName,
                      jobTitle: accountInfo.jobTitle,
                      lastName: accountInfo.lastName,
                      phoneNumber: accountInfo.phoneNumber,
                      optedOutOfEmailNotifications: accountInfo.optedOutOfEmailNotifications,
                  })
                : undefined,
        ]);

        if ((eulaAccept && !eulaAccept.success) || (profileAccept && !profileAccept.success)) {
            setSubmissionError(
                `Error: ${
                    eulaAccept && !eulaAccept.success
                        ? eulaAccept.error || 'Unknown error occured while accepting EULA.'
                        : ''
                } ${
                    profileAccept && !profileAccept.success
                        ? profileAccept.error || 'Unknown error occured while updating acccount information.'
                        : ''
                }`
            );
            setIsSubmitting(false);
        } else {
            app.onUserAuthenticated({
                email: accountInfo.email,
                userId: props.accountInformation.id,
                rememberMe: app.loginData.rememberMe || false,
                firstName: accountInfo.firstName,
                lastName: accountInfo.lastName,
                isEulaAccepted: true,
                isProfileComplete: true,
            });
        }
    };

    const steps: Array<{
        body: JSX.Element;
        canGoBack: boolean;
        canGoForward: boolean;
        onNext: () => void;
        onBack?: () => void;
    }> = [
        ...(props.showAcceptEula
            ? [
                  {
                      body: <EulaContent eulaAccepted={eulaAccepted} setEulaAccepted={setEulaAccepted} />,
                      canGoBack: activeStep !== 0,
                      canGoForward: eulaAccepted,
                      onNext: props.showAccountEdit ? handleNext : submit,
                      onBack: activeStep === 0 ? logOut : handleBack,
                  },
              ]
            : []),
        ...(props.showAccountEdit
            ? [
                  {
                      body: (
                          <AccountContent
                              key="account"
                              accountInfo={accountInfo}
                              setAccountInfo={setAccountInfo}
                              validationErrors={validationErrors}
                              clearValidationErrors={(): void => {
                                  setValidationErrors(undefined);
                              }}
                          />
                      ),
                      canGoBack: !!props.showAcceptEula,
                      canGoForward: !validationErrors,
                      onNext: submit,
                      onBack: activeStep === 0 ? logOut : handleBack,
                  },
              ]
            : []),
    ];

    if (steps.length === 0) {
        // If we got here, it was a big mistake.
        // Log the user out in case the security state is in a weird place?
        logOut();
        return <DisplayError />;
    }

    return (
        <CompleteAccountContainer
            step={steps[activeStep]}
            currStep={activeStep}
            numSteps={steps.length}
            submitting={isSubmitting}
            errorMessage={submissionError}
        />
    );
};
