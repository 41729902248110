import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    emailTextField: {
        marginBottom: '16px',
    },
    emailList: {
        maxHeight: '200px',
        overflow: 'auto',
    },
    errorTextField: {
        marginTop: '16px',
        color: Colors.red[500],
    },
};
