import { Typography } from '@mui/material';
import { useState } from 'react';
import { ApiClient } from 'src/client/api-client';
import { GenericConfirmationModal } from '../GenericConfirmationModal';
import { ReadOnlyTextWithAction } from '../ReadOnlyTextWithAction';
import { Styles } from './styles';

type Props = {
    applicationId: string;
    canEdit: boolean;
    onSuccess: () => void;
};

export const GeneratePreviewApiKeyConfirmation: React.FC<Props> = (props): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(false);

    const generatePreviewApiKey = async (): Promise<void> => {
        setError(false);
        const result = await ApiClient.applicationGeneratePreviewSubscription(props.applicationId);

        if (!result.success) {
            setError(true);
        } else {
            props.onSuccess();
            setIsOpen(false);
        }
    };

    const onClose = (): void => {
        setError(false);
        setIsOpen(false);
    };

    return (
        <>
            <ReadOnlyTextWithAction
                title="Preview API Key"
                content={''}
                helperText={'Generate a Preview API Key to access endpoints that are in preview.'}
                buttonDisabled={!props.canEdit}
                buttonText={'Generate'}
                onClick={(): void => setIsOpen(true)}
                sx={Styles.readOnlyText}
                data-testid="generate-preview-api-key"
            />

            {isOpen && (
                <GenericConfirmationModal
                    onClose={onClose}
                    modalTitle="Generate Preview API Key"
                    submitActionButtonOnClick={generatePreviewApiKey}
                    submitActionButtonText={'Confirm'}
                    errorText={
                        error ? 'An error occurred while generating the Preview API Key. Please try again later.' : ''
                    }
                    cancelActionButtonOnClick={onClose}
                    cancelActionButtonText="Cancel"
                    allowRefreshOnError={true}
                >
                    <Typography>Both Production and Preview APIs can be accessed using a Preview API key.</Typography>
                    <Typography sx={Styles.confirmationParagraph}>
                        APIs that are marked as &quot;Preview&quot; are subject to undergo breaking changes before they
                        are released into Production. Avoid using Preview APIs in user-facing production applications
                        (unless those applications are also in preview).
                    </Typography>
                </GenericConfirmationModal>
            )}
        </>
    );
};
