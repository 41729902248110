import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    error: {
        color: Colors.red[500],
    },
    readOnlyText: {
        marginBottom: '16px',
        marginRight: '50px',
        height: '80px',
    },
    confirmationParagraph: {
        marginTop: '16px',
    },
};
