import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { MakeTextField } from 'src/client/components/HelperComponents/TutorialHelpers/MakeTextField';
import { CLIENT_ID, CLIENT_SECRET, EM_API_SUBSCRIPTION_KEY } from 'src/client/constants/tutorial';
import { useAsyncFn } from 'src/client/hooks/async-fn-hook';
import { SimulatedEmApiClient } from 'src/client/simulated-em-api/simulated-em-api-client';
import { Validator } from 'src/client/util/validator';
import { withDelay } from 'src/shared/lib/with-delay';
import { RequestDefinition, RequestResponse } from '../../RequestResponse';
import { SubmitButton } from '../../SubmitButton';
import { Styles } from '../styles';
import { TutorialExpandable, TutorialStepProps } from '../TutorialExpandable';

type OrganizationAuthenticateTextFields = {
    emApiSubscriptionKey: string;
    clientId: string;
    clientSecret: string;
};

export const TutorialOrganizationAuthenticate: React.FC<TutorialStepProps> = React.memo((props) => {
    const [organizationAuthenticateTextFieldValues, setOrganizationAuthenticateBodyValues] =
        useState<OrganizationAuthenticateTextFields>({
            emApiSubscriptionKey: EM_API_SUBSCRIPTION_KEY,
            clientId: CLIENT_ID,
            clientSecret: CLIENT_SECRET,
        });

    const [inFlight, response, invokeAsyncFn] = useAsyncFn(
        withDelay(750, SimulatedEmApiClient.loginAsOrganizationAccount)
    );

    const requestBody = {
        clientId: organizationAuthenticateTextFieldValues.clientId,
        clientSecret: organizationAuthenticateTextFieldValues.clientSecret,
    };

    const submitRequest = (): void => invokeAsyncFn(requestBody);

    const request: RequestDefinition = {
        method: 'POST',
        endpointPath: '/serviceAccount/authToken',
        headers: [
            { name: 'Em-Api-Subscription-Key', value: organizationAuthenticateTextFieldValues.emApiSubscriptionKey },
            { name: 'Accept', value: 'application/json' },
            { name: 'Content-Type', value: 'application/json; charset=utf-8' },
        ],
        body: requestBody,
    };

    const validationErrors = Validator.validateSchema(organizationAuthenticateTextFieldValues, {
        emApiSubscriptionKey: {
            type: 'string',
            format: {
                pattern: `^${EM_API_SUBSCRIPTION_KEY}$`,
                message: `needs to match the api key assigned to your application. The tutorial application's key is ${EM_API_SUBSCRIPTION_KEY}`,
            },
            presence: {
                allowEmpty: false,
            },
        },
        clientId: {
            type: 'string',
            format: {
                pattern: `^${CLIENT_ID}$`,
                message: `comes from your Application. For the tutorial, this is ${CLIENT_ID}`,
            },
            presence: {
                allowEmpty: false,
            },
        },
        clientSecret: {
            type: 'string',
            format: {
                pattern: `^${CLIENT_SECRET}$`,
                message: `can match one of 2 secrets issued to you at the creation of your application. For the tutorial, this is ${CLIENT_SECRET}`,
            },

            presence: {
                allowEmpty: false,
            },
        },
    });

    return (
        <TutorialExpandable
            {...props}
            title="Retrieve Organization Auth Token"
            description={
                <Typography variant="body1" sx={Styles.description}>
                    Once the <strong>Organization</strong> is created and you have securely stored your organization
                    credentials, you can use those credentials to obtain an <strong>Organization Auth Token</strong>.
                    Most device management, device command, and system management endpoints require an Organization Auth
                    Token.
                </Typography>
            }
            valueField={
                <Box component="div" sx={Styles.values}>
                    <form noValidate autoComplete="off">
                        <Box component="div" sx={Styles.entryFields}>
                            {MakeTextField(
                                organizationAuthenticateTextFieldValues,
                                validationErrors,
                                'emApiSubscriptionKey',
                                'EM API Subscription Key',
                                setOrganizationAuthenticateBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                organizationAuthenticateTextFieldValues,
                                validationErrors,
                                'clientId',
                                'Client ID',
                                setOrganizationAuthenticateBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                organizationAuthenticateTextFieldValues,
                                validationErrors,
                                'clientSecret',
                                'Client Secret',
                                setOrganizationAuthenticateBodyValues,
                                {
                                    required: true,
                                }
                            )}
                        </Box>
                    </form>
                    <SubmitButton
                        id="send-request-button"
                        onClick={submitRequest}
                        disabled={!!validationErrors}
                        spinning={inFlight}
                        buttonText="Send Request"
                        width={144}
                    />
                </Box>
            }
            requestResponse={<RequestResponse request={request} response={response} />}
        />
    );
});
