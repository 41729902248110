import { TextField } from '@mui/material';
import React from 'react';
import { Styles } from './styles';

type TutorialTextFieldProps = {
    id: string;
    label: string;
    value: string;
    errorMessage?: string;
    type?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const TutorialTextField: React.FC<TutorialTextFieldProps> = (props) => (
    <TextField
        InputLabelProps={{ color: 'primary' }}
        id={props.id}
        sx={Styles.root}
        variant="filled"
        color="primary"
        type={props.type}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        helperText={props.errorMessage}
    />
);
