import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { EmptyState } from '@brightlayer-ui/react-components';
import _ from 'lodash';
import { Team } from '..';
import Group from '@mui/icons-material/Group';
import { Styles } from './styles';

type AddTeamsFormProps = {
    teams: Array<{ id: string; name: string; userCount: number }>;
    onTeamClick: (team: Team) => void;
    numTeams: number;
    teamsSelected: Array<{ id: string; name: string; userCount: number }>;
};

export const AddTeamsForm: React.FC<AddTeamsFormProps> = (props) => {
    if (props.teams.length === 0) {
        return (
            <EmptyState
                sx={Styles.emptyState}
                data-testid="empty-teams-modal-message"
                icon={<Group sx={Styles.emptyStateIcon} />}
                title="Oops, we couldn't find any teams to add!"
                description="You can only add teams to an application that you are a member of."
            />
        );
    }

    return (
        <>
            <Typography sx={Styles.body} variant="body1" data-testid="teams-content">
                Select the teams you would like to give access to the application.
            </Typography>
            <Box sx={Styles.scrollableContainer}>
                <Divider />
                <FormGroup data-testid="form-group">
                    {_.sortBy(props.teams, 'name').map((team, i) => {
                        const isSelected = !!props.teamsSelected.find((t) => t.id === team.id);
                        return (
                            <div key={i} data-testid="form-item">
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={isSelected} onClick={(): void => props.onTeamClick(team)} />
                                    }
                                    label={
                                        <>
                                            <Typography variant="subtitle1">{team.name}</Typography>
                                            <Typography variant="body2">
                                                {team.userCount} Team Member{team.userCount !== 1 && 's'}
                                            </Typography>
                                        </>
                                    }
                                    sx={{ ...Styles.listItem, ...(isSelected ? Styles.selectedListItem : {}) }}
                                />
                                <Divider />
                            </div>
                        );
                    })}
                </FormGroup>
            </Box>
            <Typography data-testid="selected-text" sx={Styles.bodyBottomContent}>
                {props.numTeams} Team{props.numTeams !== 1 && 's'} selected.
            </Typography>
        </>
    );
};
