import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    header: {
        marginBottom: '14px',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '93px',
    },
    icon: {
        margin: '8px',
        marginTop: '0px',
        width: '60px',
        height: '60px',
    },
    subheader: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '16px',
    },
    errorTextField: {
        marginLeft: '14px',
        color: Colors.red[500],
    },
    editableState: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '25px',
        width: '100%',
    },
    circularProgress: {
        color: 'white',
    },
    button: {
        marginLeft: '12px',
        textTransform: 'uppercase',
    },
    root: {
        '& input': {
            padding: '14px',
            fontSize: '2.0rem',
        },
        width: '100%',
        maxWidth: '600px',
    },
};
