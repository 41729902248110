import { PageWrapper } from 'src/client/components/PageWrapper';
import { ScrollableNav } from 'src/client/components/ScrollableNav';
import source from 'src/client/markdown-content/markdown-pages/UnderstandingStreams.md';

const UNDERSTANDING_STREAMS_SECTIONS = [
    {
        id: 'supportedStreamEndpointTypes',
        title: 'Supported Stream Endpoint Types',
    },
    {
        id: 'ioTHubStream',
        title: 'IoTHub Stream',
    },
    {
        id: 'webhookStream',
        title: 'Webhook Stream',
    },
    {
        id: 'authentication',
        title: 'Authentication',
    },
    {
        id: 'authenticatingTheValidationRequest',
        title: 'Authenticating the Validation Request',
    },
    {
        id: 'authenticatingThePublishRequest',
        title: 'Authenticating the Publish Request',
    },
    {
        id: 'usingAnSdkForAwsRequestV4SignatureValidation',
        title: 'Using an SDK for AWS Request V4 Signature Validation',
    },
    {
        id: 'dataSentViaStreams',
        title: 'Data Sent via Streams',
    },
];

export const UnderstandingStreamsPage: React.FC = () => (
    <PageWrapper title="Understanding Streams">
        <ScrollableNav
            sectionList={UNDERSTANDING_STREAMS_SECTIONS}
            source={source}
            pageRoute="/advancedTopics/streams"
        />
    </PageWrapper>
);
