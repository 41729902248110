import _ from 'lodash';
import { useCallback, useState } from 'react';
import { ApiClient } from 'src/client/api-client';
import { useAsyncFn } from 'src/client/hooks/async-fn-hook';
import { useRequest } from 'src/client/hooks/request-hook';
import { ApplicationRole } from 'src/shared/types/developer-portal-roles';
import { DisplayError } from '../DisplayError';
import { SimpleModal } from '../SimpleModal';
import { Spinner } from '../Spinner';
import { StepperModal } from '../StepperModal';
import { AddTeamsForm } from './AddTeamsForm';
import { AddTeamsSuccess } from './AddTeamsSuccess';
import { SetRoles } from './SetRolesForm';
import { Styles } from './styles';
import { Box } from '@mui/material';

export type Team = { id: string; name: string; userCount: number; role?: ApplicationRole };

type AddTeamsProps = {
    applicationId: string;
    onClose: () => void;
    onComplete: () => void;
    appExistingTeamIds: string[];
};

export const AddTeamsModal: React.FC<AddTeamsProps> = (props) => {
    const [isLoading, teams] = useRequest(() => ApiClient.getTeams('exclude'));
    const [selectedTeams, setSelectedTeams] = useState<Team[]>([]);
    const [inFlight, response, invokeAsyncFn] = useAsyncFn(ApiClient.applicationsAddTeams);

    const onTeamClick = useCallback((team: Team): void => {
        setSelectedTeams((existingTeams: Team[]): Team[] => {
            const index = existingTeams.indexOf(team);
            if (index >= 0) {
                return _.without(existingTeams, team);
            }
            return existingTeams.concat(team);
        });
    }, []);

    const submitTeams = (): void => {
        invokeAsyncFn(props.applicationId, {
            teams: selectedTeams.map((team) => ({ teamId: team.id, role: team.role ?? 'Developer' })),
        });
    };

    const onComplete = (): Promise<void> => {
        props.onClose();
        props.onComplete();
        return Promise.resolve();
    };

    const steps = [
        <Box key="1">
            {isLoading ? (
                <Spinner sx={Styles.modalSpinner} />
            ) : !teams ? (
                <DisplayError />
            ) : (
                <AddTeamsForm
                    // Currently the GET /teams endpoint does not have a filter for applications which would
                    // allow us to filter out existing teams on an application. This filter does not exist
                    // because of the already slow response time from the PXW CDS. To prevent extra queries
                    // and the fact the Application Detail page already has its existing teams, we are propping
                    // the list down and making the UI more "smart" by filtering out the existing values.
                    // In the future, if Postgres DB is implemented on the server, it would be a nice change to
                    // update the GET /teams endpoint with a new filter and get rid of this "smart UI" implementation.
                    teams={teams.filter((t) => !props.appExistingTeamIds.includes(t.id))}
                    teamsSelected={selectedTeams}
                    onTeamClick={onTeamClick}
                    numTeams={selectedTeams.length}
                />
            )}
        </Box>,
        <SetRoles
            key="2"
            teams={selectedTeams}
            setTeams={setSelectedTeams}
            submitting={inFlight}
            submitError={response && !response.success ? response.error || 'Unknown error occurred.' : undefined}
        />,
    ];

    return !response || !response.success ? (
        <StepperModal
            modalTitle="Add Teams"
            steps={steps}
            disableNext={isLoading || !teams || selectedTeams.length === 0}
            onSubmitAction={submitTeams}
            actionInFlight={inFlight}
            onClose={props.onClose}
        />
    ) : (
        <SimpleModal
            modalTitle="Add Teams"
            isOpen={true}
            submitActionButtonText={'Done'}
            submitActionButtonOnClick={onComplete}
            onClose={(): void => {
                void onComplete();
            }}
        >
            <AddTeamsSuccess teamsList={selectedTeams} teamsResult={response.data} />
        </SimpleModal>
    );
};
