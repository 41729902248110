import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';
import * as Types from 'server/api/types';

export const updateApplicationInfo = (appId: string, name: string): Promise<RequestHelperResult<undefined>> => {
    const requestBody: Types.ApplicationUpdateRequestBody = {
        name,
    };

    return axiosRequestHelper('PATCH', `/api/applications/${appId}`, {
        data: requestBody,
    });
};
