import { OpenInNew, PhoneAndroid } from '@mui/icons-material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Description from '@mui/icons-material/Description';
import Group from '@mui/icons-material/Group';
import HelpIcon from '@mui/icons-material/HelpRounded';
import Home from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Navigate, Route } from 'react-router-dom';
import { getRuntimeConfig } from 'src/shared/lib/runtime-config';
import { AdvancedTopicsPage } from '../pages/AdvancedTopicsPage';
import { ApplicationCreatePage } from '../pages/ApplicationsCreatePage';
import { ApplicationsDetailsPage } from '../pages/ApplicationsDetailsPage';
import { ApplicationsListPage } from '../pages/ApplicationsListPage';
import { CompleteAccountPage } from '../pages/CompleteAccountPage';
import { ControlDevicesPage } from '../pages/ControlDevicesPage';
import { HelpPage } from '../pages/HelpPage';
import { HomePage } from '../pages/HomePage';
import { PermissionsPage } from '../pages/PermissionsPage';
import { SharedDeviceAccessPage } from '../pages/SharedDeviceAccessPage';
import { SystemHierarchyPage } from '../pages/SystemHierarchyPage';
import { TeamsCreatePage } from '../pages/TeamsCreatePage';
import { TeamsDetailsPage } from '../pages/TeamsDetailsPage';
import { TeamsListPage } from '../pages/TeamsListPage';
import { TutorialPage } from '../pages/TutorialPage';
import { UnderstandingEvseConfigurationPage } from '../pages/UnderstandingEvseConfigurationPage';
import { UnderstandingEvseStatesPage } from '../pages/UnderstandingEvseStatesPage';
import { UnderstandingLocalCommunicationPage } from '../pages/UnderstandingLocalCommunicationPage';
import { UnderstandingOcppPage } from '../pages/UnderstandingOcppPage';
import { UnderstandingStreamsPage } from '../pages/UnderstandingStreamsPage';
import { UserInfo } from '../pages/UserInfoPage';

type DrawerPage = {
    title: string;
    routeRegex?: RegExp;
    route: string;
    itemID: string;
    icon?: OverridableComponent<SvgIconTypeMap>;
};

type DrawerPageWithItems = DrawerPage & {
    items?: DrawerPage[];
};

type ExternalDrawerPage =
    | Omit<DrawerPage, 'route' | 'component'> & {
          externalRoute: string;
          rightComponent?: JSX.Element;
      };

const advancedTopicsRoutePrefix = '/advancedTopics';
const advancedTopicsRouteRegex = /^\/advancedTopics[/a-zA-Z0-9-]*$/;

export const AdvancedTopicsPages: DrawerPage[] = [
    {
        title: 'System Hierarchy',
        route: `${advancedTopicsRoutePrefix}/systemHierarchy`,
        routeRegex: advancedTopicsRouteRegex,
        itemID: 'systemHierarchy',
    },
    {
        title: 'Control Devices',
        route: `${advancedTopicsRoutePrefix}/controlDevices`,
        routeRegex: advancedTopicsRouteRegex,
        itemID: 'controlDevices',
    },
    {
        title: 'Understanding Streams',
        route: `${advancedTopicsRoutePrefix}/streams`,
        routeRegex: advancedTopicsRouteRegex,
        itemID: 'streams',
    },
    {
        title: 'Understanding Local Communications',
        route: `${advancedTopicsRoutePrefix}/localCommunication`,
        routeRegex: advancedTopicsRouteRegex,
        itemID: 'localCommunication',
    },
    {
        title: 'Permissions',
        route: `${advancedTopicsRoutePrefix}/permissions`,
        routeRegex: advancedTopicsRouteRegex,
        itemID: 'permissions',
    },
    {
        title: 'Understanding OCPP',
        route: `${advancedTopicsRoutePrefix}/ocpp`,
        routeRegex: advancedTopicsRouteRegex,
        itemID: 'ocpp',
    },
    {
        title: 'Understanding EVSE Configuration',
        route: `${advancedTopicsRoutePrefix}/evseConfiguration`,
        routeRegex: advancedTopicsRouteRegex,
        itemID: 'evseConfiguration',
    },
    {
        title: 'Understanding EVSE States',
        route: `${advancedTopicsRoutePrefix}/evseStates`,
        routeRegex: advancedTopicsRouteRegex,
        itemID: 'evseStates',
    },
    {
        title: 'Shared Device Access',
        route: `${advancedTopicsRoutePrefix}/sharedDeviceAccess`,
        routeRegex: advancedTopicsRouteRegex,
        itemID: 'sharedDeviceAccess',
    },
];

export const DrawerPagesAuthorized: DrawerPage[] = [
    {
        title: 'My Apps',
        routeRegex: /^\/applications[/a-zA-Z0-9-]*$/,
        route: '/applications',
        itemID: 'my-apps',
        icon: PhoneAndroid,
    },
    {
        title: 'My Teams',
        routeRegex: /^\/teams[/a-zA-Z0-9-]*$/,
        route: '/teams',
        itemID: 'my-teams',
        icon: Group,
    },
];

export const DrawerPages: DrawerPageWithItems[] = [
    {
        title: 'Home',
        route: '/',
        itemID: 'home-page',
        icon: Home,
    },
    {
        title: 'Tutorial',
        route: '/tutorial',
        itemID: 'tutorial',
        icon: MenuBookIcon,
    },
    {
        title: 'Help',
        route: '/help',
        itemID: 'help',
        icon: HelpIcon,
    },
    {
        title: 'Advanced Topics',
        route: '/advancedTopics',
        itemID: 'advanced-topics',
        routeRegex: /^\/advancedTopics[/a-zA-Z0-9-]*$/,
        icon: AccountTreeIcon,
        items: AdvancedTopicsPages,
    },
];

export const getExternalDrawerPages = (): ExternalDrawerPage[] => [
    {
        title: 'API Documentation',
        externalRoute: getRuntimeConfig(window).apiDocumentationUrl,
        itemID: 'documentation',
        icon: Description,
        rightComponent: <OpenInNew fontSize="small" />,
    },
    {
        title: 'Preview API Documentation',
        externalRoute: getRuntimeConfig(window).previewApiDocumentationUrl,
        itemID: 'preview-documentation',
        icon: Description,
        rightComponent: <OpenInNew fontSize="small" />,
    },
];

export const CompleteAccountRoutes = (
    <>
        <Route path="completeAccount" element={<CompleteAccountPage />} />
        <Route path="" element={<Navigate to="/completeAccount" />} />
        <Route path="*" element={<Navigate to="/completeAccount" />} />
    </>
);

export const AuthorizedRoutes = (
    <>
        <Route path="" element={<HomePage />} />,
        <Route path="account" element={<UserInfo />} />
        <Route path="teams" element={<TeamsListPage />} />
        <Route path="teams/create" element={<TeamsCreatePage />} />
        <Route path="teams/:id" element={<TeamsDetailsPage />} />
        <Route path="teams/:id/applications" element={<TeamsDetailsPage />} />
        <Route path="applications" element={<ApplicationsListPage />} />
        <Route path="applications/:id" element={<ApplicationsDetailsPage />} />
        <Route path="applications/create" element={<ApplicationCreatePage />} />
        <Route path="*" element={<Navigate to="/" />} />
    </>
);

export const PublicRoutes = [
    <Route path="" key="route-home" element={<HomePage />} />,
    <Route path="tutorial" key="route-tutorial" element={<TutorialPage />} />,
    <Route path="help" key="route-help" element={<HelpPage />} />,
    <Route path="advancedTopics" key="route-advancedTopics" element={<AdvancedTopicsPage />} />,
    <Route path="advancedTopics/systemHierarchy" key="route-systemHierarchy" element={<SystemHierarchyPage />} />,
    <Route path="advancedTopics/controlDevices" key="route-controlDevices" element={<ControlDevicesPage />} />,
    <Route path="advancedTopics/streams" key="route-streams" element={<UnderstandingStreamsPage />} />,
    <Route
        path="advancedTopics/localCommunication"
        key="route-communications"
        element={<UnderstandingLocalCommunicationPage />}
    />,
    <Route path="advancedTopics/permissions" key="route-permissions" element={<PermissionsPage />} />,
    <Route path="advancedTopics/ocpp" key="route-occp" element={<UnderstandingOcppPage />} />,
    <Route
        path="advancedTopics/evseConfiguration"
        key="route-evseConfiguration"
        element={<UnderstandingEvseConfigurationPage />}
    />,
    <Route path="advancedTopics/evseStates" key="route-evseStates" element={<UnderstandingEvseStatesPage />} />,
    <Route
        path="advancedTopics/sharedDeviceAccess"
        key="route-sharedDeviceAccess"
        element={<SharedDeviceAccessPage />}
    />,
];
