import { Box, SxProps, Theme } from '@mui/material';

const Styles = {
    width: 24,
    height: 24,
};

export const PrivacyTip = (props: { sx?: SxProps<Theme> }): JSX.Element => (
    <Box
        component="svg"
        sx={{ ...props.sx, ...Styles }}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
            fill="#F2B741"
        />
    </Box>
);
