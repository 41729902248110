import validate from 'validate.js';

export type SchemaValidationResult<T> = { [key in keyof T]?: string[] } | undefined;

export type PropertySchema = {
    type: 'string';
    equality?: string;
    format?: { pattern: string | RegExp; flags?: string; message: string };
    length?: { maximum?: number };
    presence?: { allowEmpty?: boolean };
    inclusion?: string[];
};

const validateSchema = <T>(value: T, schema: { [key in keyof T]?: PropertySchema }): SchemaValidationResult<T> =>
    validate(value, schema);

export const Validator = {
    validateSchema,
};
