import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    rightComponent: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
    groupIcon: { marginLeft: '10px', color: Colors.gray[300] },
    leaveButton: { marginRight: '24px', textTransform: 'uppercase' },

    inviteButton: { textTransform: 'uppercase', marginRight: '24px' },
    list: { marginLeft: '25px', marginRight: '25px' },
};
