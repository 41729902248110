import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { MakeTextField } from 'src/client/components/HelperComponents/TutorialHelpers/MakeTextField';
import { EM_API_SUBSCRIPTION_KEY, ORGANIZATION_AUTH_TOKEN } from 'src/client/constants/tutorial';
import { useAsyncFn } from 'src/client/hooks/async-fn-hook';
import { SimulatedEmApiClient } from 'src/client/simulated-em-api/simulated-em-api-client';
import { Validator } from 'src/client/util/validator';
import { withDelay } from 'src/shared/lib/with-delay';
import { RequestDefinition, RequestResponse } from '../../RequestResponse';
import { SubmitButton } from '../../SubmitButton';
import { Styles } from '../styles';
import { TutorialExpandable, TutorialStepProps } from '../TutorialExpandable';

type TextFields = {
    emApiSubscriptionKey: string;
    organizationAuthToken: string;
};

export const TutorialGetAssignableRoles: React.FC<TutorialStepProps> = React.memo((props) => {
    const [getRolesFieldValues, setGetRolesFieldValues] = useState<TextFields>({
        emApiSubscriptionKey: EM_API_SUBSCRIPTION_KEY,
        organizationAuthToken: ORGANIZATION_AUTH_TOKEN,
    });

    const [inFlight, response, invokeAsyncFn] = useAsyncFn(withDelay(750, SimulatedEmApiClient.getAssignableRoles));

    const submitRequest = (): void => invokeAsyncFn();

    const request: RequestDefinition = {
        method: 'GET',
        endpointPath: '/roles',
        headers: [
            { name: 'Em-Api-Subscription-Key', value: getRolesFieldValues.emApiSubscriptionKey },
            { name: 'Authorization', value: `Bearer ${getRolesFieldValues.organizationAuthToken}` },
            { name: 'Accept', value: 'application/json' },
            { name: 'Content-Type', value: 'application/json; charset=utf-8' },
        ],
    };

    //running validate on the values instead of the schema (since the schema is hardcoded)
    const validationErrors = Validator.validateSchema(getRolesFieldValues, {
        emApiSubscriptionKey: {
            type: 'string',
            format: {
                pattern: `^${EM_API_SUBSCRIPTION_KEY}$`,
                message: `key should be the same as the subscription key from the rest of the tutorial: ${EM_API_SUBSCRIPTION_KEY}.`,
            },
            presence: {
                allowEmpty: false,
            },
        },
        organizationAuthToken: {
            type: 'string',
            format: {
                pattern: `^${ORGANIZATION_AUTH_TOKEN}$`,
                message: `should be the token that was given at step 4: ${ORGANIZATION_AUTH_TOKEN}.`,
            },
            presence: {
                allowEmpty: false,
            },
        },
    });

    return (
        <TutorialExpandable
            {...props}
            title="Get Valid User Roles"
            description={
                <>
                    <Typography variant="body1" sx={Styles.description}>
                        A user will need to be invited to the <strong>Organization</strong> in order to perform tasks
                        such as commission a device via the EM Install mobile application. Each user needs a role
                        assigned to control what type of access they have. This endpoint will list those user roles. One
                        of which is the <strong>installer role</strong>
                        which will be assigned to a user in the next step.
                    </Typography>
                    <br />
                    <Typography variant="body1" sx={Styles.description}>
                        Use the organization token and api key from before to list the valid user roles.
                    </Typography>
                </>
            }
            valueField={
                <Box component="div" sx={Styles.values}>
                    <form noValidate autoComplete="off">
                        <Box component="div" sx={Styles.entryFields}>
                            {MakeTextField(
                                getRolesFieldValues,
                                validationErrors,
                                'emApiSubscriptionKey',
                                'EM API Subscription Key',
                                setGetRolesFieldValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                getRolesFieldValues,
                                validationErrors,
                                'organizationAuthToken',
                                'Organization Auth Token',
                                setGetRolesFieldValues,
                                {
                                    required: true,
                                }
                            )}
                        </Box>
                    </form>
                    <SubmitButton
                        id="send-request-button"
                        onClick={submitRequest}
                        disabled={!!validationErrors}
                        spinning={inFlight}
                        buttonText="Send Request"
                        width={144}
                    />
                </Box>
            }
            requestResponse={<RequestResponse request={request} response={response} />}
        />
    );
});
