/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegistrationUIActions } from '@brightlayer-ui/react-auth-workflow';
import { ApiClient } from '../api-client';
import { DEVELOPER_PORTAL_EULA_NAME } from '../constants';

// This type definition is just a convenience to make testing easier since
// all the functions are optional now on the RegistrationUIActions type.
type Actions = Required<
    Pick<
        RegistrationUIActions,
        'loadEula' | 'requestRegistrationCode' | 'validateUserRegistrationRequest' | 'completeRegistration'
    >
>;

/**
 * Example implementation of [[RegistrationUIActions]] to start with during development.
 *
 * Registration Actions to be performed based on the user's actions. The application will create appropriate actions
 * (often API calls, local network storage, credential updates, etc.) based on the actionable needs of the user.
 */
export const ProjectRegistrationUIActions = (): Actions => ({
    /**
     * The user wants to complete an action but must first accept the EULA.
     * The application should retrieve an application-specific EULA for the user.
     *
     * @param language  The i18n language the user is requesting for the EULA text.
     *
     * @returns Resolve with EULA, otherwise reject with an error message.
     */
    loadEula: async (language: string): Promise<string> => {
        const result = await ApiClient.getEula(language);

        if (result.success) {
            return `<div>${result.data}</div>`;
        }

        throw new Error('Failed to load EULA.');
    },

    /**
     * The user entered their email address and accepted the EULA.
     * The API should now send them an email with the validation code.
     *
     * @param email  Email for the registering user.
     *
     * @returns Resolve when the server accepted the request.
     */
    requestRegistrationCode: async (email: string): Promise<string> => {
        const result = await ApiClient.requestUserRegistration(email);

        if (result.success) {
            return '';
        }

        throw new Error(result.error || 'Registration failed due to unknown error.');
    },

    /**
     * The user has tapped on an email link inviting them to register with the application.
     * The application should validate the code provided by the link.
     *
     * @param validationCode  Registration code provided from the link.
     * @param validationEmail  Email provided from the invitation email link (optional) `?email=addr%40domain.com`.
     *
     * @returns Resolves when the code is valid. True if registration is complete, False if account information is needed.
     *          If the code is not valid a rejection will occur with an error message.
     */
    validateUserRegistrationRequest: async (
        validationCode: string,
        validationEmail?: string
    ): Promise<{ codeValid: boolean | string; accountExists?: boolean }> => {
        if (!validationEmail) {
            throw new Error('No validation email. Unable to proceed.');
        }

        // We need to decode the email because it is encoded in the url
        const decodedEmail = decodeURIComponent(validationEmail);
        const result = await ApiClient.requestUserRegistrationValidate(validationCode, decodedEmail, [
            DEVELOPER_PORTAL_EULA_NAME,
        ]);

        if (result.success) {
            return { codeValid: true };
        }

        throw new Error('Failed to validate verification code. Unable to proceed.');
    },
    /**
     * The user has been invited to register and has entered the necessary account and
     * password information.
     * The application should now complete the registration process given the user's data.
     *
     * Note: Upon resolution, the user will be brought back to the Login screen.
     *
     * @param userData  Account details and password entered by the user.
     * @param validationCode  Registration code provided from the invitation email link.
     * @param validationEmail  Email provided from the invitation email link (optional) `?email=addr%40domain.com`.
     *
     * @returns Resolve when account creation succeeds, otherwise reject with an error message.
     */
    completeRegistration: async (userData: any): Promise<{ email: string; organizationName: string }> => {
        const decodedEmail = decodeURIComponent(userData.emailAddress);

        const payload = await ApiClient.createUser(userData.code, {
            email: decodedEmail,
            password: userData.password,
            firstName: userData.firstName,
            lastName: userData.lastName,
            phoneNumber: userData.extra.phoneNumber,
            companyName: userData.extra.companyName,
            jobTitle: userData.extra.jobTitle,
            department: userData.extra.department,
            optedOutOfEmailNotifications: userData.extra.optedOutOfEmailNotifications,
        });

        if (payload.success) {
            return {
                email: payload.data.email,
                organizationName: `${payload.data.firstName} ${payload.data.lastName}`,
            };
        }

        throw new Error(payload.error || 'Registration failed to complete.');
    },
});
