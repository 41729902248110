import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    successContainer: {
        minWidth: '400px',
    },
    successHeader: {
        marginBottom: '12px',
    },
    divider: {
        marginTop: '18px',
        marginBottom: '24px',
    },
    emailList: {
        maxHeight: '200px',
        overflow: 'auto',
    },
    errorTextField: {
        color: Colors.red[500],
    },
    errorContainer: {
        maxHeight: '200px',
        overflow: 'auto',
    },
    errorListItem: { marginBottom: '6px' },
};
