export const Styles = {
    topButton: {
        marginTop: '10px',
        marginBottom: '20px',
        textTransform: 'uppercase',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
};
