import { AdvancedGuideTopics } from 'src/client/components/AdvancedTopicsContent';
import { PageWrapper } from 'src/client/components/PageWrapper';
import { Styles } from './styles';
import { Box } from '@mui/material';

export const AdvancedTopicsPage: React.FC = () => (
    <PageWrapper title="Advanced Topics">
        <Box sx={Styles.contentBlock}>
            <AdvancedGuideTopics />
        </Box>
    </PageWrapper>
);
