import { Box, Button, Typography } from '@mui/material';
import { Styles } from './styles';

type CodeBlockProps = {
    id: string;
    title: string;
    line: string;
    headers?: Array<{ name: string; value: string }>;
    body?: Record<string, any>;
    buttonArgs?: { text: string; onClick: () => void };
};

export const CodeBlock: React.FC<CodeBlockProps> = (props) => (
    <Box data-testid={props.id} sx={Styles.codeBlock}>
        <Typography sx={Styles.codeTitle} variant="body2">
            {props.title}
        </Typography>
        <Box sx={Styles.codeContainer}>
            <span>{props.line}</span>
            {props.headers?.map((header, i) => (
                <span key={i}>{`${header.name}: ${header.value}`}</span>
            ))}
            {props.body && (
                <Box sx={Styles.bodyText}>
                    <br />
                    {JSON.stringify(props.body, null, 2)}
                </Box>
            )}
            {props.buttonArgs ? (
                <Box>
                    <br />
                    <Button sx={Styles.button} onClick={props.buttonArgs.onClick} variant="contained" color="primary">
                        {props.buttonArgs.text}
                    </Button>
                </Box>
            ) : null}
        </Box>
    </Box>
);
