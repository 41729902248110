export const Styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: '1.563rem',
        marginRight: '1.563rem',
    },
    createButton: {
        textTransform: 'uppercase',
    },
};
