import { acceptEula } from './endpoints/accept-eula';
import { applicationGeneratePreviewSubscription } from './endpoints/application-generate-preview-api-key';
import { applicationsAddTeams } from './endpoints/applications-add-teams';
import { createApplication } from './endpoints/create-application';
import { createAuthToken } from './endpoints/create-auth-token';
import { createTeam } from './endpoints/create-team';
import { createUser } from './endpoints/create-user';
import { deleteAuthToken } from './endpoints/delete-auth-token';
import { deleteTeamInvite } from './endpoints/delete-team-invite';
import { getApplicationById } from './endpoints/get-application-by-id';
import { getApplications } from './endpoints/get-applications';
import { getApplicationsByTeam } from './endpoints/get-applications-by-team';
import { getCurrentNotification } from './endpoints/get-current-notification';
import { getEula } from './endpoints/get-eula';
import { getSecurityAbilities } from './endpoints/get-security-abilities';
import { getTeamById } from './endpoints/get-team-by-id';
import { getTeamInvite } from './endpoints/get-team-invite';
import { getTeams } from './endpoints/get-teams';
import { getUserInformation } from './endpoints/get-user-information';
import { inviteTeamMembers } from './endpoints/invite-team-members';
import { leaveTeam } from './endpoints/leave-team';
import { removeTeamFromApplication } from './endpoints/remove-team-from-application';
import { requestUserRegistration } from './endpoints/request-user-registration';
import { requestUserRegistrationValidate } from './endpoints/request-user-registration-validate';
import { rotateApplicationSecret } from './endpoints/rotate-application-secret';
import { updateApplicationInfo } from './endpoints/update-application-info';
import { updateApplicationTeam } from './endpoints/update-application-team';
import { updateTeamInfo } from './endpoints/update-team-info';
import { updateTeamUser } from './endpoints/update-team-user';
import { updateUserInformation } from './endpoints/update-user-information';
import { userPasswordChange } from './endpoints/user-password-change';
import { userPasswordReset } from './endpoints/user-password-reset';
import { getEntitlementsDetails } from './endpoints/get-entitlements';
import { refreshEntitlementStatus } from './endpoints/refresh-entitlement-status';
import { getEntitlementByApplicationId } from './endpoints/get-entitlement-by-application-id';

export const ApiClient = {
    acceptEula,
    applicationGeneratePreviewSubscription,
    applicationsAddTeams,
    createApplication,
    createAuthToken,
    createTeam,
    createUser,
    deleteAuthToken,
    deleteTeamInvite,
    getApplicationById,
    getApplications,
    getApplicationsByTeam,
    getCurrentNotification,
    getEula,
    getSecurityAbilities,
    getTeamById,
    getTeamInvite,
    getTeams,
    getUserInformation,
    getEntitlementByApplicationId,
    inviteTeamMembers,
    leaveTeam,
    removeTeamFromApplication,
    requestUserRegistration,
    requestUserRegistrationValidate,
    rotateApplicationSecret,
    updateApplicationInfo,
    updateApplicationTeam,
    updateTeamInfo,
    updateTeamUser,
    updateUserInformation,
    userPasswordChange,
    userPasswordReset,
    getEntitlementsDetails,
    refreshEntitlementStatus,
};
