import { PageWrapper } from 'src/client/components/PageWrapper';
import { ScrollableNav } from 'src/client/components/ScrollableNav';
import source from 'src/client/markdown-content/markdown-pages/HelpContent.md';

const HELP = [
    {
        id: 'entitlement',
        title: 'Entitlement',
    },
    {
        id: 'purchasingAnEntitlement',
        title: 'Purchasing an Entitlement',
    },
    {
        id: 'applications',
        title: 'Applications',
    },
    {
        id: 'creatingAnApplication',
        title: 'Creating an Application',
    },
    {
        id: 'subscriptionStatus',
        title: 'Subscription status',
    },
    {
        id: 'updatingSubscriptionStatus',
        title: 'Updating subscription status',
    },
    {
        id: 'teams',
        title: 'Teams',
    },
    {
        id: 'creatingATeam',
        title: 'Creating a Team',
    },
    {
        id: 'addingDevelopersToATeam',
        title: 'Adding Developers to a Team',
    },
    {
        id: 'ownerVsMemberRoleOfATeamMember',
        title: 'Owner vs Member: Role of a Team Member',
    },
    {
        id: 'changingATeamMembersRole',
        title: "Changing a Team Member's Role",
    },
    {
        id: 'removingATeamMember',
        title: 'Removing a Team Member',
    },
    {
        id: 'leavingATeam',
        title: 'Leaving a Team',
    },
    {
        id: 'associatingTeamsWithAnApplication',
        title: 'Associating Teams with an Application',
    },
    {
        id: 'addingATeamToAnApplication',
        title: 'Adding a Team to an Application',
    },
    {
        id: 'adminVsDeveloperRoleOfATeamOnAnApplication',
        title: 'Admin vs Developer: Role of a Team on an Application',
    },
    {
        id: 'changingTheRoleOfATeamForAnApplication',
        title: 'Changing the Role of a Team for an Application',
    },
    {
        id: 'removingATeamFromAnApplication',
        title: 'Removing a Team from an Application',
    },
    {
        id: 'contactUs',
        title: 'Contact Us',
    },
];

export const HelpPage: React.FC = () => (
    <PageWrapper title="Help">
        <ScrollableNav sectionList={HELP} source={source} pageRoute="/help" />
    </PageWrapper>
);
