import { Typography } from '@mui/material';
import { useState } from 'react';
import { ApiClient } from 'src/client/api-client';
import { GenericConfirmationModal } from '../GenericConfirmationModal';
import { Styles } from './styles';

type RemoveFromTeamTeamConfirmationModalProps = {
    team: { id: string; name: string };
    targetUserId: string;
    targetDisplayName: string;
    onClose: () => void;
    onSuccess: () => void;
};

export const RemoveFromTeamConfirmationModal: React.FC<RemoveFromTeamTeamConfirmationModalProps> = (props) => {
    const [errorText, setErrorText] = useState<string>('');

    const onSubmitClick = async (): Promise<void> => {
        const result = await ApiClient.leaveTeam(props.team.id, props.targetUserId);

        if (!result.success) {
            setErrorText(`Failed to remove user from team. ${result.error || 'no error found'} `);
            return;
        }
        props.onSuccess();
        props.onClose();
    };

    return (
        <GenericConfirmationModal
            errorText={errorText}
            submitActionButtonOnClick={onSubmitClick}
            onClose={props.onClose}
            modalTitle="Remove from Team"
            submitActionButtonText="Remove User"
            cancelActionButtonText="Cancel"
        >
            <Typography sx={Styles.confirmationQuestionText} data-testid="modal-text-container">
                Are you sure you want to remove <b>{props.targetDisplayName} </b>
                from the
                <b> {props.team.name} </b>
                team?
            </Typography>
            <Typography sx={Styles.confirmationInstructionalText}>
                They will have to be reinvited to rejoin the team.
            </Typography>
        </GenericConfirmationModal>
    );
};
