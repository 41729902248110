import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    boxLayout: {
        width: '100%',
        bgcolor: Colors.white[200],
        maxHeight: 150,
        overflow: 'auto',
    },
    listView: {
        paddingLeft: 1,
    },
    infoData: {
        marginLeft: 1,
    },
};
