import { EmptyState } from '@brightlayer-ui/react-components';
import { ApiClient } from 'src/client/api-client';
import { DisplayError } from 'src/client/components/DisplayError';
import { PageWrapper } from 'src/client/components/PageWrapper';
import { Spinner } from 'src/client/components/Spinner';
import { useRequest } from 'src/client/hooks/request-hook';
import { AddCircle, PhoneAndroid } from '@mui/icons-material';
import { ListPageNavBar } from 'src/client/components/ListPageNavBar';
import { AppsList } from 'src/client/components/AppsList';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Styles } from './styles';

export const ApplicationsListPage: React.FC = () => {
    const navigate = useNavigate();
    const [isLoading, appsData] = useRequest(ApiClient.getApplications);

    const navigateTo = (route: string): void => {
        navigate(route);
    };

    return (
        <PageWrapper title="My Apps" sx={Styles.contentBody}>
            <ListPageNavBar
                startIcon={<AddCircle />}
                buttonId={'create-application'}
                buttonText={'Create New Application'}
                onClick={(): void => {
                    navigateTo(`/applications/create`);
                }}
            />
            {isLoading ? (
                <Spinner />
            ) : !appsData ? (
                <DisplayError />
            ) : appsData.length > 0 ? (
                <AppsList appsData={appsData} />
            ) : (
                <EmptyState
                    data-testid="empty-apps-page-message"
                    sx={Styles.emptyState}
                    icon={<PhoneAndroid sx={Styles.emptyStateIcon} />}
                    title="No Applications Found"
                    description="You have not created any applications yet."
                />
            )}
        </PageWrapper>
    );
};
