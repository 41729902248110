export const Styles = {
    body: {
        display: 'flex',
        flexDirection: 'column',
        margin: '25px',
        marginTop: '25px',
    },
    viewAppButton: {
        textTransform: 'uppercase',
    },
    createAppButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        margin: '12px',
    },
};
