import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';
import * as Types from 'server/api/types';

export const updateTeamInfo = (teamId: string, name: string): Promise<RequestHelperResult<undefined>> => {
    const requestBody: Types.TeamUpdateRequestBody = {
        name,
    };

    return axiosRequestHelper('PATCH', `/api/teams/${teamId}`, {
        data: requestBody,
    });
};
