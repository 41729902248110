import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    title: {
        color: Colors.blue['500'],
    },
    content: {
        color: Colors.black['500'],
    },
    textAndTitle: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
    },
};
