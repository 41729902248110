import * as Types from 'server/api/types';
import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';

export const requestUserRegistration = (email: string, eulaNames?: string[]): Promise<RequestHelperResult<unknown>> => {
    const requestBody: Types.BeginUserRegistrationRequestBody = {
        email: email,
        eulaNames: eulaNames,
    };

    return axiosRequestHelper('POST', '/api/users/registrations', {
        data: requestBody,
    });
};
