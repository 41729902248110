import { SxProps } from '@mui/material/styles';
export const generateSxProps = (sx?: SxProps) => {
    const sxArray = [];
    if (sx) {
        if (Array.isArray(sx)) {
            sxArray.push(...sx);
        } else {
            sxArray.push(sx);
        }
    }
    return sxArray;
};
