import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';
import * as Types from 'server/api/types';

export const applicationsAddTeams = (
    applicationId: string,
    args: Types.ApplicationsAddTeamsRequestBody
): Promise<RequestHelperResult<Types.ApplicationsAddTeamsResponseBody['data']>> =>
    axiosRequestHelper<Types.ApplicationsAddTeamsResponseBody>('POST', `/api/applications/${applicationId}/teams`, {
        data: args,
    });
