import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';
import * as Types from 'server/api/types';

export const getUserInformation = (args?: {
    includeUnacceptedEulas?: boolean;
}): Promise<RequestHelperResult<Types.GetUsersAccountInfoResponseBody['data']>> =>
    axiosRequestHelper<Types.GetUsersAccountInfoResponseBody>('GET', '/api/users/account', {
        params: {
            checkEula: args?.includeUnacceptedEulas ? 'true' : undefined,
        },
    });
