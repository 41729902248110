import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { MakeTextField } from 'src/client/components/HelperComponents/TutorialHelpers/MakeTextField';
import { DEVICE_ID, EM_API_SUBSCRIPTION_KEY, ORGANIZATION_AUTH_TOKEN } from 'src/client/constants/tutorial';
import { useAsyncFn } from 'src/client/hooks/async-fn-hook';
import { SimulatedEmApiClient } from 'src/client/simulated-em-api/simulated-em-api-client';
import { Validator } from 'src/client/util/validator';
import { getRuntimeConfig } from 'src/shared/lib/runtime-config';
import { withDelay } from 'src/shared/lib/with-delay';
import { BasicDropdown } from '../../BasicDropdown';
import { RequestDefinition, RequestResponse } from '../../RequestResponse';
import { SubmitButton } from '../../SubmitButton';
import { Styles } from '../styles';
import { TutorialExpandable, TutorialStepProps } from '../TutorialExpandable';

type CommandSelections = 'open' | 'close';
type CreateLocationTextFields = {
    emApiSubscriptionKey: string;
    organizationAuthToken: string;
    deviceId: string;
    command: CommandSelections;
    reason: string;
    secondsUntilReset?: string;
};

export const TutorialControlBreaker: React.FC<TutorialStepProps> = React.memo((props) => {
    const [controlBreakerTextFieldValues, setControlBreakerBodyValues] = useState<CreateLocationTextFields>({
        emApiSubscriptionKey: EM_API_SUBSCRIPTION_KEY,
        organizationAuthToken: ORGANIZATION_AUTH_TOKEN,
        deviceId: DEVICE_ID,
        command: 'open',
        reason: '',
    });

    const [inFlight, response, invokeAsyncFn] = useAsyncFn(withDelay(750, SimulatedEmApiClient.controlDevice));

    // map the pretty looking text to the actual values
    const handleChange = (value: string): void =>
        value === 'open'
            ? setControlBreakerBodyValues({ ...controlBreakerTextFieldValues, command: 'open' })
            : setControlBreakerBodyValues({ ...controlBreakerTextFieldValues, command: 'close' });

    const requestBody = {
        command: controlBreakerTextFieldValues.command,
        reason: controlBreakerTextFieldValues.reason,
        secondsUntilReset: controlBreakerTextFieldValues.secondsUntilReset
            ? parseInt(controlBreakerTextFieldValues.secondsUntilReset)
            : undefined,
    };

    const submitRequest = (): void => invokeAsyncFn(requestBody);

    const request: RequestDefinition = {
        method: 'POST',
        endpointPath: `/devices/${controlBreakerTextFieldValues.deviceId}/breaker/remoteHandle/position`,
        headers: [
            { name: 'Em-Api-Subscription-Key', value: controlBreakerTextFieldValues.emApiSubscriptionKey },
            { name: 'Authorization', value: `Bearer ${controlBreakerTextFieldValues.organizationAuthToken}` },
            { name: 'Content-Type', value: 'application/json; charset=utf-8' },
        ],
        body: requestBody,
    };

    // running validate on the values instead of the schema (since the schema is hardcoded)
    const validationErrors = Validator.validateSchema(controlBreakerTextFieldValues, {
        emApiSubscriptionKey: {
            type: 'string',
            format: {
                pattern: `^${EM_API_SUBSCRIPTION_KEY}$`,
                message: `key should be the same as the subscription key from the rest of the tutorial: ${EM_API_SUBSCRIPTION_KEY}.`,
            },
            presence: {
                allowEmpty: false,
            },
        },
        organizationAuthToken: {
            type: 'string',
            format: {
                pattern: `^${ORGANIZATION_AUTH_TOKEN}$`,
                message: `should be the token that was given at step 4: ${ORGANIZATION_AUTH_TOKEN}.`,
            },
            presence: {
                allowEmpty: false,
            },
        },
        deviceId: {
            type: 'string',
            format: {
                pattern: `^${DEVICE_ID}$`,
                message: `should be the id of a device listed in step 9: ${DEVICE_ID}.`,
            },
            presence: {
                allowEmpty: false,
            },
        },
        secondsUntilReset: {
            type: 'string',
            format: {
                pattern: '^[0-9]+$|^$', //must be all numerc or empty string
                message: `must be a number of seconds greater than 0`,
            },
        },
        reason: {
            type: 'string',
            length: { maximum: 255 },
            presence: {
                allowEmpty: false,
            },
        },
    });

    return (
        <TutorialExpandable
            {...props}
            title="Control A Device"
            description={
                <>
                    <Typography variant="body1" sx={Styles.description}>
                        After the <strong>device ID</strong> has been retrieved, it can be used to issue any number of{' '}
                        <a
                            href={`${getRuntimeConfig(window).apiDocumentationUrl}#tag/Device-Commands`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Device Commands
                        </a>
                        .
                    </Typography>
                    <br />
                    <Typography variant="body1" sx={Styles.description}>
                        As an example, let&apos;s control the device! Using the{' '}
                        <a
                            href={`${getRuntimeConfig(window).apiDocumentationUrl}#operation/postRemoteHandlePosition`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            control endpoint
                        </a>{' '}
                        and the <strong>Organization Auth Token</strong>, the Smart Breaker can be remotely opened or
                        closed. The breaker should come back with a 204 no-content when the command is performed
                        successfully.
                    </Typography>
                </>
            }
            valueField={
                <Box component="div" sx={Styles.values}>
                    <form noValidate autoComplete="off">
                        <Box component="div" sx={Styles.entryFields}>
                            {MakeTextField(
                                controlBreakerTextFieldValues,
                                validationErrors,
                                'emApiSubscriptionKey',
                                'EM API Subscription Key',
                                setControlBreakerBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                controlBreakerTextFieldValues,
                                validationErrors,
                                'organizationAuthToken',
                                'Organization Auth Token',
                                setControlBreakerBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                controlBreakerTextFieldValues,
                                validationErrors,
                                'deviceId',
                                'Device ID',
                                setControlBreakerBodyValues,
                                { required: true }
                            )}
                            <BasicDropdown
                                label="Device Command *"
                                data-testid="device-command-dropdown"
                                sx={Styles.formField}
                                value={controlBreakerTextFieldValues.command}
                                onChange={handleChange}
                                items={['open', 'close']}
                            />
                            {MakeTextField(
                                controlBreakerTextFieldValues,
                                validationErrors,
                                'reason',
                                'Reason',
                                setControlBreakerBodyValues,
                                {
                                    required: true,
                                }
                            )}
                            {MakeTextField(
                                controlBreakerTextFieldValues,
                                validationErrors,
                                'secondsUntilReset',
                                'Seconds Until Reset',
                                setControlBreakerBodyValues,
                                {
                                    type: 'number',
                                }
                            )}
                        </Box>
                    </form>
                    <SubmitButton
                        id="send-request-button"
                        onClick={submitRequest}
                        disabled={!!validationErrors}
                        spinning={inFlight}
                        buttonText="Send Request"
                        width={144}
                    />
                </Box>
            }
            requestResponse={<RequestResponse request={request} response={response} />}
        />
    );
});
