import { Box } from '@mui/material';
import _ from 'lodash';
import { GenericTextAndTitle } from 'src/client/components/GenericTextAndTitle';
import { AccountData } from '../types';
import { Styles } from './styles';

type AccountInfoProps = {
    accountData: AccountData;
};

export const AccountDisplay = (props: AccountInfoProps): JSX.Element => {
    const fieldNames: Array<keyof Omit<AccountData, 'department' | 'optedOutOfEmailNotifications'>> = [
        'firstName',
        'lastName',
        'email',
        'phoneNumber',
        'companyName',
        'jobTitle',
    ];

    return (
        <Box sx={Styles.infoContainer}>
            {fieldNames.map((value) => (
                <GenericTextAndTitle
                    id={`${_.kebabCase(value)}-info`}
                    key={value}
                    title={_.startCase(value)}
                    content={props.accountData[value] || ''}
                />
            ))}
            <GenericTextAndTitle
                id="department-info"
                title="Department / Division"
                content={props.accountData.department || ''}
            />

            <GenericTextAndTitle
                id="email-preference"
                title="Email Preference"
                content={props.accountData.optedOutOfEmailNotifications ? 'Opted Out' : 'Opted In'}
            />
        </Box>
    );
};
