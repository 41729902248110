import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';
import * as Types from 'server/api/types';

export const getTeams = (
    pendingInvites?: 'exclude' | 'include'
): Promise<RequestHelperResult<Types.GetTeamsResponseBody['data']>> => {
    const queryParameters: Types.GetTeamsQueryParams = {
        pendingInvites: pendingInvites,
    };

    return axiosRequestHelper<Types.GetTeamsResponseBody>('GET', '/api/teams', {
        params: queryParameters,
    });
};
