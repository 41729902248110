import * as Types from 'server/api/types';
import { axiosRequestHelper, RequestHelperResult } from './endpoint-helpers';

export const inviteTeamMembers = (
    id: string,
    emails: string[]
): Promise<RequestHelperResult<Types.TeamsInvitesCreateResponseBody['data']>> => {
    const requestBody: Types.TeamsInvitesCreateRequestBody = {
        emails: emails,
    };

    return axiosRequestHelper<Types.TeamsInvitesCreateResponseBody>('POST', `/api/teams/${id}/invites`, {
        data: requestBody,
    });
};
