import { ApiClient } from 'src/client/api-client';
import { AccountInfo } from 'src/client/components/AccountInfo';
import { DisplayError } from 'src/client/components/DisplayError';
import { PageWrapper } from 'src/client/components/PageWrapper';
import { Spinner } from 'src/client/components/Spinner';
import { useRequest } from 'src/client/hooks/request-hook';

export const UserInfo = (): JSX.Element => {
    const [isLoading, accountData] = useRequest(ApiClient.getUserInformation);

    return (
        <PageWrapper title="My Account" userProfile={true}>
            {isLoading ? (
                <Spinner />
            ) : !accountData ? (
                <DisplayError />
            ) : (
                <AccountInfo
                    accountData={{
                        firstName: accountData.firstName,
                        lastName: accountData.lastName,
                        email: accountData.email,
                        phoneNumber: accountData.phoneNumber,
                        companyName: accountData.companyName,
                        jobTitle: accountData.jobTitle,
                        department: accountData.department,
                        optedOutOfEmailNotifications: accountData.optedOutOfEmailNotifications,
                    }}
                />
            )}
        </PageWrapper>
    );
};
